import { CARD_SECRET_REQUEST, CARD_SECRET_SUCCESS, CARD_SECRET_FAIL} from '../actions/types';

export default function cardSecretReducer(state = {}, action) {
    switch(action.type) {
        case CARD_SECRET_REQUEST:
            return { loading: true }
        case CARD_SECRET_SUCCESS:
            return { loading: false, data: action.payload}
        case CARD_SECRET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}