import React, {useState, useEffect} from 'react'
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Label,
    Badge,
    UncontrolledTooltip,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CardSummary from '../dashboard/CardSummary'
import CustomCardSummary from '../dashboard/CustomCardSummary'
import Loader from '../common/Loader';
import { createBulkSendMessage } from '../../actions/bulkSendActions'

const BulkTextSending = ({recipients, contacts, groups, fields, bulkMessage} ) => {
   
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)


    const bulkSendCreate = useSelector((state) => state.bulkSendCreate)
    const {success: successBulkSendCreate, info: infoBulkSendCreate } = bulkSendCreate



    useEffect(() => {
        const numberId = JSON.parse(bulkMessage?.bulkNumber)._id
        dispatch(createBulkSendMessage({...bulkMessage, bulkNumber: numberId}))
    }, [])


    useEffect(() => {
        if(auth?.credits < 100000) {//(Math.ceil(bulkMessage?.messageContents?.plainText?.length / 153) * recipients)) {
        console.log("need to add credits")
        } else {
            console.log('enough credits')
        }

    }, [])

    const [currentMessage, setCurrentMessage] = useState({index: 0})
    const [fieldWithDefaultValues, setFieldWithDefaultValues] = useState()
    const plainText = bulkMessage.messageContents.plainText



useEffect(() => {
    const OurFields = fields.map((el) => { return { name: el.title , id: el._id}})
    setFieldWithDefaultValues([{name: "First name", id: "700"}, {name: "Last name", id: "701"}, {name: "Company name", id: "702"}, {name: "Phone", id: "703"}, {name: "Email", id: "704"}, ...OurFields])
  }, [fields])


const fiftyContacts = []
const removeKey = bulkMessage.groups.map((el) => { return el.group} ) // changing from [ { group: "seihfliasj" } ] to [ "seihfliasj"]
const groupContacts = groups.filter((el) => { return removeKey.includes(el._id)}) // get actual group object by filter from an array of string Ids
groupContacts.map((group) => { return group.contacts.forEach((el) => fiftyContacts.length < 50 ? fiftyContacts.push(el) : "" )  }) // try to get 50 contacts from groups
if(fiftyContacts.length < 50) { // if not enough push in from individual contacts
    const individualContacts = contacts.filter((el) => { return removeKey.includes(el._id)}) // get actual contact object by filter from array of string Ids
    individualContacts.map((contact) => { return (fiftyContacts.length < 50 ) ? fiftyContacts.push(contact) : ""})
}
//----------------------------------------------------------get mentions from state with default values--------------------------------------------------------------------------------------------
const getMentionFromId = fieldWithDefaultValues?.filter(el => bulkMessage?.messageContents?.mentions?.some(o2 => el.id === o2.mentionId));
//----------------------------------------------------------Reformat mentions to match firstName instead of First Name--------------------------------------------------------------------------------------------
const mentionReformat = getMentionFromId?.map((el) => { 
    if(el.name === "First name") {
        return { name: "firstName", id: el.id}
    } else if(el.name === "Last name") {
        return { name: "lastName", id: el.id}
    } else if(el.name === "Company name") {
        return { name: "company", id: el.id} 
    } else if(el.name === "Phone") {
        return { name: "phone_number", id: el.id}  
    } else if(el.name === "Email") {
        return { name: "email", id: el.id}
    }
        return { name: el.name, id: el.id}
})

const mentionForCurlys = getMentionFromId?.map((el) => { 
    if(el.name === "First name") {
        return { [el.name]: "{firstName}"}
    } else if(el.name === "Last name") {
        return { [el.name]: "{lastName}"}
    } else if(el.name === "Company name") {
        return { [el.name]: "{company}"} 
    } else if(el.name === "Phone") {
        return { [el.name]: "{phone_number}"}  
    } else if(el.name === "Email") {
        return { [el.name]: "{email}"}
    }
        return { [el.name]: `{${el.name}}`}
})

let mentionArr = []
if(mentionForCurlys) {
mentionArr.push(Object.assign({}, ...mentionForCurlys))
}


//----------------------------------------------------------Create mentions with contacts value--------------------------------------------------------------------------------------------
function valueByKey(obj, key) {  
    let result = obj[key];
    
    if (result) {

      return result;
    }
    Object.keys(obj).forEach(function(k) {

      if (typeof obj[k] == 'object') {
        result = valueByKey(obj[k], key);
      } 
    });
    return result;
  }


  const customContactValues = fiftyContacts?.map((contact) => { 
    const contactNoPsids = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        blocked: contact?.blocked,
        created: contact?.created,
        fields: contact?.fields,
        phone_number: contact?.phone_number,
        _id: contact?._id
      }

    let result = {};
      const thru = mentionReformat?.forEach((mention) => { result[mention.name] = valueByKey(contactNoPsids, mention.name) } ); 

    return result;
  });
 

    function renderPlainText(plainText, obj) {
        let result = plainText;

        for(let [key, value] of Object.entries(obj)) {
            if(value === undefined) {
            result = result.replace(new RegExp(`{${key}}`, "g"), "");
        } else {
            result = result.replace(new RegExp(`{${key}}`, "g"), value);
        }
        }

        return result;
    }


    let resultsObj
    if(mentionArr) {
    resultsObj = mentionArr?.map((mentionValues) => 
        renderPlainText(plainText, mentionValues));
    }

    const formattedText = resultsObj[0]?.toString()


    let mergeStringWithContactValues
    if(formattedText) {
        mergeStringWithContactValues = customContactValues?.map((contactValues) => 
        renderPlainText(formattedText, contactValues))
}
    const finalMessages = mergeStringWithContactValues?.map((el) => {
        return {message: el}
    })
    
  
  
   const nextMessage = () => {
       setCurrentMessage({index: (currentMessage.index + 1) % finalMessages?.length})
   }

   const previousMessage = () => {
       setCurrentMessage({index: currentMessage.index === 0 ? currentMessage.index = finalMessages?.length - 1 : (currentMessage.index - 1) % finalMessages?.length})
   }

  
  
  return (

    <div>
        <Card>
            <CardBody>
                { !successBulkSendCreate ? (
                  <div>
                    <Row>
                        <Col className="col-4">
                        <Label>Sending Messages...</Label> 
                        </Col>
                        <Col className="col-8">
                            <Loader />
                        </Col>
                    </Row>
                    </div>
                ) : (<div classname="card-deck">
                      <CustomCardSummary to="#!" rate="BulkText complete" color="info" className="mr-5" >
                      <Badge color="soft-success" pill className="fs--1">
                            Success
                        <FontAwesomeIcon icon="check" transform="shrink-1" className=" ml-1" />
                            </Badge>
                        </CustomCardSummary>
                    </div>
                    )}
                <Row>
                    <Col className="col-4">
                        <Label>Message Preview</Label>
                    </Col>

                    <Col className="col-md-12 col-lg-5">
                            <div
                                className={classNames('p-2 rounded-soft compose-preview-message', {
                                'bg-200': false,
                                'bg-primary text-white': true
                                })}
                            >
                                {//(message || message?.text) && (
                                (
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${finalMessages ? finalMessages[`${parseInt(currentMessage?.index)}`]?.message : ""}` }} />
                                )}
                            </div>
                                <Row className="mt-1">
                                    <Button
                                        onClick={() => {return previousMessage()}}
                                        color="link"
                                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-5 ml-5"
                                        id="previous"
                                    >
                                        <FontAwesomeIcon icon="arrow-left" />
                                    </Button>
                                    <Button
                                        onClick={() => {return nextMessage()}}
                                        color="link"
                                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
                                        id="next"
                                    >
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="previous">
                                        Previous
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip placement="bottom" target="next">
                                        Next
                                    </UncontrolledTooltip>
                                </Row>
                        </Col>
                       
                </Row>
                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>To:</Label>
                    </Col>
                    <Col className="col-10">
                        {bulkMessage?.contactLength?.length ? (
                            <Badge color="soft-primary" pill className="fs--1">
                            {`${bulkMessage?.contactLength?.length} individual contacts`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                        ) : ( "")}
                        {bulkMessage.groupNames.map((el) => (
                            <div>
                            <Badge color="soft-success" pill className="fs--1" overflow="hidden">
                            {`Group: ${el?.title } with ${el?.contacts?.length} contacts`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                            </div>
                        ))}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>From:</Label>
                    </Col>
                    <Col className="col-10">
                    <Badge color="soft-info" pill className="fs--1">
                            {JSON.parse(bulkMessage?.bulkNumber).phoneNumber + " " + JSON.parse(bulkMessage?.bulkNumber).nickName}
                            </Badge>
                       
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-4">
                        <Label>Message Length:</Label>
                    </Col>
                    <Col className="col-8">
                    <Badge color="soft-info" pill className="fs--1">
                            {`${finalMessages ? finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length : ""} characters`}
                            </Badge>
                       
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-4">
                        <Label>Segments:</Label>
                    </Col>
                    <Col className="col-8">
                    <Badge color="soft-info" pill className="fs--1">
                            {`Messages sent in ${finalMessages ? Math.ceil(finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length / 153) : ""} ${finalMessages && Math.ceil(finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length / 153) < 2 ? "part" : "parts"}`}
                            </Badge>
                       
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>Total cost:</Label>
                    </Col>
                    <Col className="col-10">
                    <Badge color="soft-info" pill className="fs--1">
                    {`Cost to send ${finalMessages ? ((Math.ceil(finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length / 153)) * recipients + " Credits") : ""}`}
                            </Badge>
                       
                    </Col>
                </Row>
                < hr/>
               
                <div className="card-deck">
        <CardSummary title="" rate="Go to campaigns" to="/" color="warning" className="mr-5" >
         <h4>Campaigns</h4>
          </CardSummary>
      <CardSummary title="" rate="Go to chat" tag={Link} to="/chat" color="info">
         <h4>Chat</h4>
        </CardSummary>
        <CardSummary title="" rate="Sent Recieved" tag={Link} to="/pages/history" color="success">
        <h4>History</h4>
        </CardSummary>
      </div>
            </CardBody>
        </Card>
    </div>

  )


}



export default BulkTextSending