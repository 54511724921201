import { ADD_CONTENT } from '../actions/types';


export default function (state = "", action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case ADD_CONTENT:
            return action.payload  
            default:
            return state;
    }
}