import { ACCOUNT_DELETE_REQUEST, ACCOUNT_DELETE_SUCCESS, ACCOUNT_DELETE_FAIL, ACCOUNT_DELETE_RESET } from '../actions/types';

export default function bulkSendDeleteReducer(state = {}, action) {
    switch(action.type) {
        case ACCOUNT_DELETE_REQUEST:
            return { loading: true }
        case ACCOUNT_DELETE_SUCCESS:
            return { loading: false, success: true}
        case ACCOUNT_DELETE_FAIL:
            return { loading: false, error: action.payload }
            case ACCOUNT_DELETE_RESET:
                return { }
        default:
            return state
    }
}