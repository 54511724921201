import { GROUP_DETAILS_REQUEST, GROUP_DETAILS_FAIL, GROUP_DETAILS_SUCCESS } from "../actions/types"

export default function groupDetailsReducer ( state = [], action ) {
    switch(action.type) {
        case GROUP_DETAILS_REQUEST:
            return { loading: true }
        case GROUP_DETAILS_SUCCESS:
            const groupWName = action.payload.map((el) => { return {...el, name: el.firstName + " " + el.lastName}})
            if(groupWName.title !== "unsubscribers") {
                return groupWName.filter((c) => c.blocked === false && !c.UnsubscribeDate)
            } else {
                return groupWName.filter((c) => c.blocked === false)
            }
        case GROUP_DETAILS_FAIL:
            return { loading: false, error: action.payload}
            default:
                return state
    }
}