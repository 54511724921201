import { CALENDAR_UPDATE_REQUEST, CALENDAR_UPDATE_SUCCESS, CALENDAR_UPDATE_FAIL, CALENDAR_UPDATE_RESET } from '../actions/types';

export default function calendarUpdateReducer(state = {}, action) {
    switch(action.type) {
        case CALENDAR_UPDATE_REQUEST:
            return { loading: true }
        case CALENDAR_UPDATE_SUCCESS:
            return { loading: false, success: true, calendarInfo: action.payload}
        case CALENDAR_UPDATE_FAIL:
            return { loading: false, error: action.payload }
            case CALENDAR_UPDATE_RESET:
            return { }
        default:
            return state
    }
}