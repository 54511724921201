import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CampaignDetails from './CampaignDetails';
import Divider from '../common/Divider';
import Flex from '../common/Flex';
import moment from 'moment';
import momentt from 'moment-timezone';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ml-n1 mr-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon icon={icon} transform={transform ?? ''} className="text-primary fa-stack-1x" inverse />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Media className="mt-3">
    {getCircleStackIcon(icon)}
    <Media body>
      <>
        <h6>{heading}</h6>
        {children || <p className="mb-0 text-justify">{content}</p>}
      </>
    </Media>
  </Media>
);

const CompletedEventModal = ({ showDetailModal, setShowDetailModal, modalEventContent, fields, groups }) => {
  

  const toggle = () => setShowDetailModal(!showDetailModal);


  const {title, totalMessageSids, description, end, start, endDate, startDate, timezone, repeatCampaign, repeatCampaignHourCycles, repeatCampaignEnd, repeatCampaignEndSessions, repeatCampaignDayTimes, repeatCampaignDayCount, repeatCampaignWeekCount, repeatCampaignWeekdays, repeatCampaignWeekdayTimes, repeatCampaignType, repeatCampaignMonthOnThe, repeatCampaignMonthOnDay, repeatCampaignMonthTimes, location, organizer, messageDetails, messageContents, cost, contactLength, _id } = showDetailModal && modalEventContent;


  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={showDetailModal} toggle={toggle} modalClassName="theme-modal" contentClassName="border" centered>
      <ModalHeader toggle={toggle} tag="div" className="px-card bg-light border-0 flex-between-center" close={closeBtn}>
        <h5 className="mb-0">{title}</h5>
        {organizer && (
          <p className="mb-0 fs--1 mt-1">
            by <a href="#!">{organizer}</a>
          </p>
        )}
      </ModalHeader>
      <ModalBody className="px-card pb-card pt-1 fs--1">
      {totalMessageSids && (() => {

          return ( 
          
          <EventModalMediaContent icon="check" heading="Total messages sent">
            <span>{`${totalMessageSids.length} messages`}</span>
          </EventModalMediaContent>

            )})
            () // without these () it does not render....
            }
      {startDate && (() => {
          const ttDate = new Date(startDate) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
          const toTz = momentt.tz(ttDate.toISOString().replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
          const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
          const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000

          return ( 
          
          <EventModalMediaContent icon="calendar-check" heading="First Sent Date">
            <span>{moment(ttISO).format('LLLL')}</span>
          </EventModalMediaContent>

            )})
            () // without these () it does not render....
            }
             {endDate && repeatCampaignEnd !== "Never" && (() => {
          const ttDate = new Date(endDate) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
          const toTz = momentt.tz(ttDate.toISOString().replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
          const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
          const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000

          return ( 
          
          <EventModalMediaContent icon="calendar-check" heading="End date">
            <span>{moment(ttISO).format('LLLL')}</span>
          </EventModalMediaContent>

            )})
            () // without these () it does not render....
            }
        
        {repeatCampaign !== "noRepeat" && !endDate && repeatCampaignEnd === "Never" && (
          <EventModalMediaContent icon="calendar-check" heading="End Date">
            <span>Never end</span>
          </EventModalMediaContent>
        )}
        {repeatCampaignEnd === "After" && (
          <EventModalMediaContent icon="calendar-check" heading="End Date">
            <span>Campaign will stop after {repeatCampaignEndSessions} sessions</span>
          </EventModalMediaContent>
        )}
        {timezone && (
          <EventModalMediaContent icon="calendar-check" heading="Timezone">
            <span>{timezone}</span>
          </EventModalMediaContent>
        )}
       
        {description && <EventModalMediaContent icon="align-left" heading="Description" content={description} />}
            
        


        {repeatCampaign && (
        <EventModalMediaContent icon="redo" heading="Repeat Campaign">
          <span>{repeatCampaign}</span>

          

           { // HOUR VIEW
           repeatCampaignHourCycles && repeatCampaign === "Hourly" && (
          <div>
          {' '}
           {
            repeatCampaignHourCycles.map((el) => {

              const ttDate = new Date(el.trigger_time) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
               const toTz = momentt.tz(el.trigger_time.replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
                  const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000
              return(
              <div>
               <span>{moment(ttISO).format('h: mm a')}</span>
              </div>
            )})
          }
          </div>
         )}




          { // DAY VIEW
          repeatCampaignDayTimes && repeatCampaign === "Daily" && (
                    <div>
                      <span>Repeat every {repeatCampaignDayCount > 1 ? repeatCampaignDayCount + " days" : "day"}</span>
                    {' '}
                     {
                      repeatCampaignDayTimes.map((el) => {

                        const ttDate = new Date(el.trigger_time) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                         const toTz = momentt.tz(el.trigger_time.replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                          const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
                            const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000
                        return(
                        <div>
                         <span>{moment(ttISO).format('h: mm a')}</span>
                        </div>
                      )})
                    }
                    </div>
                   )}



          { // WEEK VIEW
                    repeatCampaignWeekdays && repeatCampaign === "Weekly" && (
                              <div>
                                <span>Repeat every {repeatCampaignWeekCount > 1 ? repeatCampaignWeekCount + " weeks" : "week"}</span>
                              {' '}
                              {
                                Object.entries(repeatCampaignWeekdays).map(([key, value]) => {
                                  if(value === true) {

                                    return (
                                      <div>
                                        <Divider>
                                        <h6>{key.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</h6>
                                        </Divider>
                                        {
                                          repeatCampaignWeekdayTimes[key]?.map((el) => { // el.trigger_time 2022-11-02T20:00:00Z --- from server in UTC
                                            const ttDate = new Date(el.trigger_time) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                                            const toTz = momentt.tz(el.trigger_time.replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                                            const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
                                            const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000
                                            return (
                                              
                                              <div><span className="ml-3">{moment(ttISO).format('h: mm a')}</span></div>
                                            )
                                          })
                                        }
                                      </div>
                                     
                                    )
                                  }
                                 
                                  // Pretty straightforward - use key for the key and value for the value.
                                  // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                              })
                              }
                               
                              </div>
                             )}









                  { // MONTH VIEW
                                      repeatCampaign === "Monthly" && (
                                                <div>
                                                  {repeatCampaignType === "onDay" ? (
                                                    <div>
                                                      <span>Repeat on the {repeatCampaignMonthOnDay} of the month</span>
                                                      </div>
                                                  ) : repeatCampaignType === "onThe" ?  (
                                                    <div>
                                                        <span>Repeat on the {repeatCampaignMonthOnThe?.first_part + " " + repeatCampaignMonthOnThe?.day} of the month</span>

                                                      </div>
                                                  ) : (
                                                    <div>
                                                      </div>
                                                  )}
                                                 
                                                {' '}
                                                {
                                                  repeatCampaignMonthTimes.map((el) => {

                                                    const ttDate = new Date(el.trigger_time) //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                                                     const toTz = momentt.tz(el.trigger_time.replace("Z", ""), timezone).format() //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                                                      const applyOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000); //1667397600000
                                                        const ttISO = new Date(applyOffset).toISOString().replace("Z", "") //2022-11-02T14:00:00.000
                                                    return(
                                                    <div>
                                                     <span>{moment(ttISO).format('h: mm a')}</span>
                                                    </div>
                                                  )})
                                                }

                                                </div>
                                               )}





        </EventModalMediaContent>)}
        {
       // location && (
       //   <EventModalMediaContent icon="map-marker-alt" heading="Location">
       //     <div className="mb-1" dangerouslySetInnerHTML={{ __html: location }} />
       //   </EventModalMediaContent>
       // )
        }
        <CampaignDetails fields={fields} groups={groups} messageDetails={messageDetails} messageContents={messageContents} contactLength={contactLength} cost={cost} totalMessageSids={totalMessageSids} />
      </ModalBody>
      <ModalFooter tag={Flex} justify="end" className="bg-light px-card border-top-0">
    
      </ModalFooter>
    </Modal>
  );
};

export default CompletedEventModal;
