import { CREDIT_CREATE_REQUEST, CREDIT_CREATE_SUCCESS, CREDIT_CREATE_FAIL, CREDIT_CREATE_RESET } from '../actions/types';

export default function creditCreateReducer(state = {}, action) {
    switch(action.type) {
        case CREDIT_CREATE_REQUEST:
            return { loading: true }
        case CREDIT_CREATE_SUCCESS:
            return { loading: false, data: action.payload}
        case CREDIT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case CREDIT_CREATE_RESET:
            return { loading: false, state: {}}
        default:
            return state
    }
}