import { GROUP_DETAILS_TITLE_REQUEST, GROUP_DETAILS_TITLE_FAIL, GROUP_DETAILS_TITLE_SUCCESS } from "../actions/types"

export default function groupDetailsTitleReducer ( state = { groupTitle: { } }, action ) {
    switch(action.type) {
        case GROUP_DETAILS_TITLE_REQUEST:
            return { loading: true }
        case GROUP_DETAILS_TITLE_SUCCESS:
            return { loading: false, groupTitle: action.payload}
        case GROUP_DETAILS_TITLE_FAIL:
            return { loading: false, error: action.payload}
            default:
                return state
    }
}