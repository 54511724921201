import { CSVUPLOAD_DELETE_REQUEST, CSVUPLOAD_DELETE_SUCCESS, CSVUPLOAD_DELETE_FAIL } from '../actions/types';

export default function csvUploadDeleteReducer(state = [], action) {
    switch(action.type) {
        case CSVUPLOAD_DELETE_REQUEST:
            return { loading: true }
        case CSVUPLOAD_DELETE_SUCCESS:
            return { loading: false, success: true}
        case CSVUPLOAD_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}