import { NUMBER_CREATE_REQUEST, NUMBER_CREATE_SUCCESS, NUMBER_CREATE_FAIL, NUMBER_CREATE_RESET } from '../actions/types';

export default function numberCreateReducer(state = {}, action) {
    switch(action.type) {
        case NUMBER_CREATE_REQUEST:
            return { loading: true }
        case NUMBER_CREATE_SUCCESS:
            return { loading: false, success: true}
        case NUMBER_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case NUMBER_CREATE_RESET:
            return { loading: false, state: {} }
        default:
            return state
    }
}