import { UPDATE_SELECTED_SENT_ROWS } from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {
       case UPDATE_SELECTED_SENT_ROWS: // could have issue with empty string may need to do action.payload || false
       return state

            default:
            return state;
    }
}