import { LIST_UNSUBS } from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {

        case LIST_UNSUBS:
            const listUnSubs = action.payload.filter((group) => group.title === "unsubscribers")[0]
            const addNameField = listUnSubs.contacts.map((c) => { return {...c, name: c.firstName + " " + c.lastName} })
            return addNameField // doing this because reactTable gives error when using group.contacts as data, so we need to return only contacts

            default:
            return state;
    }
}