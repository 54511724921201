
const LIST_TEMPLATES = 'list_templates';


export default function (state = [], action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case LIST_TEMPLATES:
            return action.payload  
            default:
            return state;
    }
}