import { SUBSCRIPTION_DETAILS_REQUEST, SUBSCRIPTION_DETAILS_FAIL, SUBSCRIPTION_DETAILS_SUCCESS } from "../actions/types"

export default function subscriptionDetailsReducer ( state = { field: {} }, action ) {
    switch(action.type) {
        case SUBSCRIPTION_DETAILS_REQUEST:
            return { loading: true }
        case SUBSCRIPTION_DETAILS_SUCCESS:
            return { loading: false, subscription: action.payload.response.subscription}
        case SUBSCRIPTION_DETAILS_FAIL:
            return { loading: false, error: action.payload}
            default:
                return state
    }
}