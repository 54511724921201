import { LIST_RESPONDER_MESSAGES } from "../actions/types";


export default function (state = [], action) {
    const { payload } = action
    switch (action.type) {
        case LIST_RESPONDER_MESSAGES:
         
            return payload.data
            default:
            return state;

    }

}