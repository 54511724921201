import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPageName } from '../../helpers/utils';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import { createContact, createGroup, listGroups, listFields, createField } from '../../actions/index';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '../common/Divider';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { GROUP_CREATE_RESET } from '../../actions/types';


const ContactForm = ({ hasLabel, showContactModal, setShowContactModal, showSingleOrListModal, setShowSingleOrListModal, newContactInfo, fromCompose  }) => {

  const history = useHistory();

  const isChat = getPageName('chat')
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()
  // State
  const [contact, setContact] = useState({ 
    phone_number: newContactInfo ? newContactInfo.replace(/\D/g, '') : "",
    phone_type: 'mobile',
    group: '',
    fields: "",
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    error: '',
    open: false})

    const [field, setField] = useState({
      title: ''
    })

  const [isDisabled, setIsDisabled] = useState(true);
  const [createGroupModal, setCreateGroupModal] = useState(false)
  const [showFieldModal, setShowFieldModal] = useState(false)
  const [newGroup, setNewGroup] = useState({ title: ''})
  const [existErr, setExistErr] = useState(false)

  
  const handleChange = e => {
    setContact({...contact, [e.target.name]: e.target.value})
  };

  const handleFieldChange = e => {
    setField({...field, [e.target.name]: e.target.value})
  }

  const toggle = () => { setCreateGroupModal(!createGroupModal)}

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  const closeFieldBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowFieldModal(!showFieldModal)}}>
      &times;
    </button>
  );
  
  const groups = useSelector((state) => state.groups)
  const fields = useSelector((state) => state.fields)
  const numbers = useSelector((state) => state.numbers)

  
  const groupCreate = useSelector((state) => state.groupCreate)
  const {success: successCreateGroup} = groupCreate

  const fieldCreate = useSelector((state) => state.fieldCreate)
  const { success: successFieldCreate } = fieldCreate

  useEffect(() => {
    if(successCreateGroup) {
   toast(
     <Fragment>
       <strong>Group Created</strong>
     </Fragment>
   );
   dispatch({type: GROUP_CREATE_RESET})
  }
 }, [successCreateGroup]);

  useEffect(() => {
    dispatch(listGroups())
    setIsDisabled( !contact.phoneNumber || !contact.phoneType);
  }, [contact.phoneNumber, contact.phoneType, successCreateGroup ]);

  useEffect(() => {
    dispatch(listFields())
  }, [successFieldCreate])

  return ( 
  <>
    <Form >
      
      <FormGroup>
        <Label>Enter a Number</Label><strong style={{color: "red"}}> *</strong>
        <PhoneInput 
            country="us"
            isValid={(value, country) => {
              if(value.match(/12345/)) {
                return 'Invalid value: '+value+', '+country.name;
              } else if (value.match(/1234/)) {
                return false
              } else {
                return true;
              }
            }}
            inputStyle={{border: 'none', font: 'caption' }}
            inputClass="w-100 font-weight-bold bg-light"
            containerClass="rounded text-sm shadow focus:outline-none focus:shadow-outline dark"
            countryCodeEditable={false}
            dropdownClass="rounded"
            preferredCountries={['us','ca','mx','fr','it','br','co','it','gr']}
            limitMaxLength={true}
            enableSearch={true}
            name="phone_number"
            value={newContactInfo ? newContactInfo : contact.phone_number.value}
            onChange={phone_number => setContact({...contact, phone_number: phone_number})}
           
        />
       
      </FormGroup>
      <div>
        <FormGroup>
          <Label>Phone type</Label>
          <Input
            placeholder={!hasLabel ? 'landline or mobile' : ''}
            name="phone_type"
            required={true}
            value={contact.phone_type.value}
            onChange={handleChange}
            type='select'>

          <option name="mobile" value="mobile">Mobile</option>
          <option name="landline" value="landline">Landline</option>
          </Input>
        </FormGroup>
      </div>
      <div>
        <FormGroup>
        <Label>
          Add to a Group</Label>
            <Select
              name="group"
              className="mb-3"
              components={animatedComponents}
              closeMenuOnSelect={true}
              options={groups}
              getOptionLabel={({title}) => title}
              getOptionValue={({_id}) => _id}
              onChange={(_id) => setContact({...contact, group: _id})}
              isMulti
              placeholder="Select Group..."
              isSearchable={true}
            />
        </FormGroup>
      </div>
      
      <div>
      <Button color="light" onClick={(() => setCreateGroupModal(true))} className="rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon="user-plus" />
                {` or create a new group`}
              </Button>
                <Modal isOpen={createGroupModal} centered toggle={() => setCreateGroupModal(!createGroupModal)}>
                <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
                Let's give the group a name
                  </ModalHeader>
                    <ModalBody className="p-0">
                      <Card>
                        <CardBody className="fs--1 font-weight-normal p-4"> 
                            <Label for="title">Group Name:</Label>
                              <Input value={newGroup.title.value} onChange={(e) => {setNewGroup({...newGroup, [e.target.name]: e.target.value})}} className="mb-3" name="title" id="title"/>
                              <Button block onClick={(e) => { return e.preventDefault(), dispatch(createGroup(newGroup)), setCreateGroupModal(false)}} color="primary" disabled={!newGroup.title}className="mb-3">Save</Button>
                        </CardBody>
                      </Card>
                    </ModalBody>
                </Modal>
      </div>
      <div>
        <FormGroup>
          <Label className="mt-2">First Name</Label>
          <Input
            placeholder={!hasLabel ? 'First Name' : ''}
            name="firstName"
            value={contact.firstName.value}
            onChange={handleChange}
            type='input'
            autoComplete='off'
          />
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <Label>Last Name</Label>
          <Input
            placeholder={!hasLabel ? 'Last Name' : ''}
            name="lastName"
            value={contact.lastName.value}
            onChange={handleChange}
            type='input'
            autoComplete='off'
          />
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <Label>Email</Label>
          <Input
            placeholder={!hasLabel ? 'Email' : ''}
            name="email"
            value={contact.email.value}
            onChange={handleChange}
            type='email'
            autoComplete='off'
          />
        </FormGroup>
      </div>
      <Divider className="mt-4">custom field values unique to this contact</Divider>  
      <div>
      <Button color="light" onClick={(() => setShowFieldModal(!showFieldModal))} className="rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon="plus" />
                {` New Field`}
              </Button>
                <Modal isOpen={showFieldModal} centered toggle={() => setShowFieldModal(!showFieldModal)}>
                <ModalHeader close={closeFieldBtn}  className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Create a new Field
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                              <Label for="title">Field Name:</Label>
                                <Input value={field.title.value} onChange={handleFieldChange} className="mb-3" name="title" id="title"/>
                              <Button block disabled={field?.title?.length < 1} onClick={() => { return dispatch(createField(field)), setShowFieldModal(!showFieldModal)}} color="primary" className="mb-3" >Save</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
      </div>
      {fields.map((field) => { return (
        
      <div>
        <FormGroup>
          <Label className="mt-2">{field?.title}</Label>
          <Input
            name="fieldTitles"
            onChange={(e) => setContact({...contact, fields: {[field.title]: e.target.value, defaultField: "default"}})}
            type='input'
            autoComplete='off'
          />
        </FormGroup>
      </div>
  )})}
      <Divider className="mt-4"></Divider>   
      <FormGroup>
      <Button 
    block 
    color="primary"
    disabled={!contact.phone_number}
    onClick={(e) => {
      const checkExists = async () => {
      const response = await axios.get(`https://my.bulktext.com/contact-form?phoneNumber=${contact.phone_number}`, {withCredentials: true});
      if (response.data.length > 0) {
        setExistErr(true);
      } else{
            if(isChat && !numbers.length) {
                e.preventDefault();
                toast(
                  <div className="p-3">
                      <div className="text-center">You need to purchase a number to create a chat.</div>
                      <Row className="mt-2 justify-content-center">
                          <Col md="6" sm="12" className="mb-2 mb-md-0">
                              <Button block color="btn btn-falcon-primary" onClick={() => { return history.push('/services/numbers'), toast.dismiss()}}>Purchase Number</Button>
                          </Col>
                          <Col md="6" sm="12">
                              <Button block color="btn btn-falcon-danger" onClick={() => toast.dismiss()}>Cancel</Button>
                          </Col>
                      </Row>
                  </div>,
                  {
                      position: "top-center",
                      autoClose: false,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  }
              );
            } else {
                return e.preventDefault(),
                setExistErr(false),
                dispatch(createContact(contact)),
                setShowContactModal(!showContactModal)
            }
        }
    }
    checkExists()
    
    }}>Save</Button>
      </FormGroup>
    </Form>
    {existErr && (
      <div>
      <span className='text-danger d-inline-block'>
      Contact with same number already exists
     </span>
     </div>
    )}
  
  </>
 
    
  );
};

ContactForm.propTypes = {
  
  hasLabel: PropTypes.bool
};

ContactForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default ContactForm;