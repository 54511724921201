import React, { useEffect, useState } from 'react';
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import FalconCardHeader from '../common/FalconCardHeader';
import Calendar from './Calendar';
import CompletedTable from './CompletedTable';
import PausedTable from './PausedTable';
import { listCalendar } from '../../actions/index';


const CalendarTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [tabName, setTabName] = useState('Click to text')
  const [isOpenScheduleModal, setIsOpenScheduleModal ] = useState(false)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [eventId, setEventId] = useState() // this is for passing the id from pause event modal, and completed event modal to scheduleBulkText
  const calendar = useSelector((state) => state.calendar)
  const pausedCalendar = useSelector((state) => state.pausedCalendar)
  const completedCalendar = useSelector((state) => state.completedCalendar)
  
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  

  const dispatch = useDispatch()

    const updateCalendar = useSelector((state) => state.calendarUpdate)
    const { success: successUpdate } = updateCalendar
  
    const deleteCalendar = useSelector((state) => state.calendarDelete)
    const { success: successDelete } = deleteCalendar


    useEffect(() => {
      dispatch(listCalendar())
    }, [successUpdate, successDelete])
  


  return (
    <div>
      <Col>
       <Row>
         <Card className="w-100">
           <CardBody>
            <FalconCardHeader title="" light={false}></FalconCardHeader>
                <Nav className="justify-content-start flex-column flex-md-row" tabs>
                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); setTabName('Calendar') }}
                      >
                        <span className={`badge badge-soft-primary fs--1 mt-1`}>Active  <span className={`badge badge-primary fs--2 rounded-capsule`}>{calendar?.length}</span></span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); setTabName('Completed') }}
                      >
                        <span className={`badge badge-soft-success fs--1 mt-1`}>Completed  <span className={`badge badge-success fs--2 rounded-capsule`}>{completedCalendar?.length}</span></span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); setTabName('Paused') }}
                      >
                        <span className={`badge badge-soft-warning fs--1 mt-1`}>Paused  <span className={`badge badge-warning fs--2 rounded-capsule`}>{pausedCalendar?.length}</span></span>
                       
                      </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Calendar showDetailModal={showDetailModal} setShowDetailModal={setShowDetailModal} eventId={eventId} setEventId={setEventId} isOpenScheduleModal={isOpenScheduleModal} setIsOpenScheduleModal={setIsOpenScheduleModal} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col>
                        <CompletedTable eventId={eventId} setEventId={setEventId}  />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col>
                        <PausedTable showDetailModal={showDetailModal} setShowDetailModal={setShowDetailModal} eventId={eventId} setEventId={setEventId} isOpenScheduleModal={isOpenScheduleModal} setIsOpenScheduleModal={setIsOpenScheduleModal} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>      
            </CardBody>
        </Card>
       </Row>
      </Col>
    </div>
  );
}



export default CalendarTabs;