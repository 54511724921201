/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Flex from '../Flex';
import { UPDATE_PAGE_INDEX, UPDATE_PAGE_SIZE } from '../../../actions/types';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const AdvanceContactsTableFooter = ({
  
  rowCount,
  navButtons,
  page,
  pageIndex,
  pageCount,
  pageSize,
  rowInfo,
  rowsPerPageSelection,
  rowsPerPageOptions = [10, 25, 50, 100],
  className
}) => {

  const dispatch = useDispatch()

  const windowSize = useWindowDimensions()

  return (
    <div>
    <Flex
    className={classNames(
      className,
      'align-items-center justify-content-between'
    )}
  >
    <Flex alignItems="center" className="fs--1">
      {rowInfo && (
        <p className="mb-0">
          <span className="d-none d-sm-inline-block me-2">
            {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length}{' '}
            of {rowCount}
          </span>
        </p>
      )}
      {rowsPerPageSelection && (
        <>
          <p className="mb-0 mx-2">Rows per page:</p>
          <Form.Select
            size="sm"
            className="w-auto"
            value={pageSize}
            onChange={e => {
              return dispatch({type: UPDATE_PAGE_SIZE, payload:(Number(e.target.value))})
            }}
            >
            {rowsPerPageOptions.map(value => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </>
      )}
    </Flex>
    {navButtons && (
      <Flex>
        <Button
          size="sm"
          disabled={(pageIndex + 1 === 1 || pageIndex + 1 > pageCount)}
          variant={(pageIndex + 1 === 1 || pageIndex + 1 > pageCount) ? 'light' : 'primary'}
          onClick={() => { return dispatch({type: UPDATE_PAGE_INDEX, payload: pageIndex - 1})}}
          className={classNames({ disabled: (pageIndex + 1 === 1 || pageIndex + 1 > pageCount) })}
        >
          {windowSize?.width < 500 ? "<" : "Previous"}
        </Button>
        <Button
          size="sm"
          disabled={(pageIndex + 1 >= pageCount)}
          variant={(pageIndex + 1 >= pageCount) ? 'light' : 'primary'}
          className={classNames('ml-2', {
            disabled: (pageIndex + 1 >= pageCount)
          })}
          onClick={() => { return dispatch({type: UPDATE_PAGE_INDEX, payload: pageIndex + 1})}}
        >
          {windowSize?.width < 500 ? ">" : "Next"}
        </Button>
      </Flex>
    )}
  </Flex>
    
    </div>
  );
};

export default AdvanceContactsTableFooter;