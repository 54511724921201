import { ATTACH_MMS_CREATE_REQUEST, ATTACH_MMS_CREATE_SUCCESS, ATTACH_MMS_CREATE_FAIL, ATTACH_MMS_RESET } from '../actions/types';

export default function attachMMSCreateReducer(state = {}, action) {
    switch(action.type) {
        case ATTACH_MMS_CREATE_REQUEST:
            return { loading: true }
        case ATTACH_MMS_CREATE_SUCCESS:
            return { loading: false, success: true, payload: action.payload.data}
        case ATTACH_MMS_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case ATTACH_MMS_RESET:
                return {};
        default:
            return state
    }
}