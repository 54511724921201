import {GROUP_DELETE_REQUEST, GROUP_DELETE_SUCCESS, GROUP_DELETE_FAIL } from '../actions/types';

export default function groupDeleteReducer(state = {}, action) {
    switch(action.type) {
        case GROUP_DELETE_REQUEST:
            return {loading: true}
        case GROUP_DELETE_SUCCESS:
            return {loading: false, success: true}
        case GROUP_DELETE_FAIL:
            return {loading: false, error: action.payload }
        default:
            return state
    }
}