import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


const PrivateRoute = ({ component: Component, ...rest }) => {
    
    const auth = useSelector((state => state.auth));



return (

    
    <Route {...rest} render={props => (
        
       auth !== false ? ( 
            <Component {...props} />
       ) : (
            <Redirect to={{ pathname: '/authentication/basic/login', 
            state: { from: props.location }}} >    
            </Redirect>
        )
)} />
    )}

export default PrivateRoute
