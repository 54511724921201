import { CALENDAR_DELETE_REQUEST, CALENDAR_DELETE_SUCCESS, CALENDAR_DELETE_FAIL } from '../actions/types';
    
    export default function calendarDeleteReducer(state = {}, action) {
        switch(action.type) {
            case CALENDAR_DELETE_REQUEST:
                return { loading: true }
            case CALENDAR_DELETE_SUCCESS:
                return { loading: false, success: true}
            case CALENDAR_DELETE_FAIL:
                return { loading: false, error: action.payload }
            default:
                return state
        }
    }