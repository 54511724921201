import { UPDATE_PAGE_SIZE, UPDATE_PAGE_INDEX } from '../actions/types';

const initialState = {
    pageSize: 10,
    pageIndex: 0,
  };
  
  const paginationReducer = (state = initialState, action) => {
    switch(action.type) {
      case UPDATE_PAGE_SIZE:
        console.log("update_page_size ran", action.payload)
        return {
          ...state,
          pageSize: action.payload,
        };
      case UPDATE_PAGE_INDEX:
        console.log("update_page_index", action.payload)
        return {
          ...state,
          pageIndex: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default paginationReducer;