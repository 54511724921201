import { 
    PRIMARY_NUMBER_UPDATE_REQUEST,
    PRIMARY_NUMBER_UPDATE_SUCCESS,
    PRIMARY_NUMBER_UPDATE_FAIL,
    PRIMARY_NUMBER_UPDATE_RESET
    } from '../actions/types';

export default function primaryNumberUpdateReducer(state = {}, action) {
    
    switch(action.type) {
        case PRIMARY_NUMBER_UPDATE_REQUEST:
            return { loading: true}
        case PRIMARY_NUMBER_UPDATE_SUCCESS:
            return { loading: false, success: true}
        case PRIMARY_NUMBER_UPDATE_FAIL:
            return { loading: false, error: action.payload}
        case PRIMARY_NUMBER_UPDATE_RESET:
            return { loading: false, state: {}}
        default:
            return state
    }



}