import React, {useState, useEffect} from 'react'
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Label,
    Badge,
    UncontrolledTooltip
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Loader from '../common/Loader';
import { useDispatch } from 'react-redux';
import { getContactsByIds } from '../../actions';


const CampaignDetails = ({fields, groups, messageContents, messageDetails, contactLength }) => {
   
   

    const [currentMessage, setCurrentMessage] = useState({index: 0})
    const [fiftyContacts, setFiftyContacts] = useState([]);
    const [fieldWithDefaultValues, setFieldWithDefaultValues] = useState()
    const [allGroupContacts, setAllGroupContacts] = useState()
    const dispatch = useDispatch()
    
    const plainText = messageContents?.plainText
    let bulkNumber
    if(messageDetails) {

        bulkNumber = JSON.parse(messageDetails?.bulkNumber)
    }


useEffect(() => {
    const OurFields = fields.map((el) => { return { name: el.title , id: el._id}})
    setFieldWithDefaultValues([{name: "First name", id: "700"}, {name: "Last name", id: "701"}, {name: "Company name", id: "702"}, {name: "Phone", id: "703"}, {name: "Email", id: "704"}, ...OurFields])
  }, [fields])

 


  useEffect(() => {
    const newFiftyContacts = [];
    const removeKey = messageDetails?.groups?.map((el) => { return el.group });
    const groupContacts = groups?.filter((el) => { return removeKey?.includes(el._id)});

    groupContacts.forEach((group) => { 
        group.contacts.forEach((el) => {
            if(newFiftyContacts.length < 50) {
                newFiftyContacts.push(el);
            }
        })
    });

    if(newFiftyContacts.length < 50) {
        const fetchContacts = async () => {
            try {
                if(removeKey) {
                const res = await dispatch(getContactsByIds(removeKey));
                const individualContacts = res?.filter((el) => { return removeKey?.includes(el._id)});
                individualContacts.forEach((contact) => {
                    if(newFiftyContacts.length < 50) {
                        newFiftyContacts.push(contact);
                    }
                });
                setFiftyContacts(newFiftyContacts)
            }
            } catch (error) {
                console.error(error);
            }
        };

        fetchContacts();
    } else {
        
        setFiftyContacts(newFiftyContacts);
    }
}, []);
//----------------------------------------------------------get mentions from state with default values--------------------------------------------------------------------------------------------
const getMentionFromId = fieldWithDefaultValues?.filter(el => messageContents?.mentions?.some(o2 => el.id === o2.mentionId));
//----------------------------------------------------------Reformat mentions to match firstName instead of First Name--------------------------------------------------------------------------------------------
const mentionReformat = getMentionFromId?.map((el) => { 
    if(el.name === "First name") {
        return { name: "firstName", id: el.id}
    } else if(el.name === "Last name") {
        return { name: "lastName", id: el.id}
    } else if(el.name === "Company name") {
        return { name: "company", id: el.id} 
    } else if(el.name === "Phone") {
        return { name: "phone_number", id: el.id}  
    } else if(el.name === "Email") {
        return { name: "email", id: el.id}
    }
        return { name: el.name, id: el.id}
})

const mentionForCurlys = getMentionFromId?.map((el) => { 
    if(el.name === "First name") {
        return { [el.name]: "{firstName}"}
    } else if(el.name === "Last name") {
        return { [el.name]: "{lastName}"}
    } else if(el.name === "Company name") {
        return { [el.name]: "{company}"} 
    } else if(el.name === "Phone") {
        return { [el.name]: "{phone_number}"}  
    } else if(el.name === "Email") {
        return { [el.name]: "{email}"}
    }
        return { [el.name]: `{${el.name}}`}
})

let mentionArr = []
if(mentionForCurlys) {
mentionArr.push(Object.assign({}, ...mentionForCurlys))
}


//----------------------------------------------------------Create mentions with contacts value--------------------------------------------------------------------------------------------
function valueByKey(obj, key) {  
    let result = obj[key];
    if (result) {
      return result;
    }
    Object.keys(obj).forEach(function(k) {
      if (typeof obj[k] == 'object') {
        result = valueByKey(obj[k], key);
      } 
    });
    return result;
  }

  
  const customContactValues = fiftyContacts?.map((contact) => { 
    const contactNoPsids = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        blocked: contact?.blocked,
        created: contact?.created,
        fields: contact?.fields,
        phone_number: contact?.phone_number,
        _id: contact?._id
      }
    let result = {};
      const thru = mentionReformat?.forEach((mention) => { result[mention.name] = valueByKey(contactNoPsids, mention.name) } ); 
      
    return result;
  });

  
 

    function renderPlainText(plainText, obj) {
        let result = plainText;

        for(let [key, value] of Object.entries(obj)) {
            if(value === undefined) {
            result = result.replace(new RegExp(`{${key}}`, "g"), "");
        } else {
            result = result.replace(new RegExp(`{${key}}`, "g"), value);
        }
        }

        return result;
    }


    let resultsObj
    if(mentionArr) {
    resultsObj = mentionArr?.map((mentionValues) => 
        renderPlainText(plainText, mentionValues));
    }

    const formattedText = resultsObj[0]?.toString()


    let mergeStringWithContactValues
    if(formattedText) {
        mergeStringWithContactValues = customContactValues?.map((contactValues) => 
        renderPlainText(formattedText, contactValues))
}

    const finalMessages = mergeStringWithContactValues?.map((el) => {
        return {message: el}
    })
   

  
  
   const nextMessage = () => {
       setCurrentMessage({index: (currentMessage.index + 1) % finalMessages?.length})
   }

   const previousMessage = () => {
       setCurrentMessage({index: currentMessage.index === 0 ? currentMessage.index = finalMessages?.length - 1 : (currentMessage.index - 1) % finalMessages?.length})
   }

   useEffect(() => {
    if(messageDetails) {
        let totalContacts = 0
        return messageDetails?.groupNames.map((el) => { return totalContacts = totalContacts + el?.contacts?.length} ),
    
         setAllGroupContacts(totalContacts)

    }

   }, [messageDetails])

  
  return (

    <div>
        <Card>
            <CardBody>
                <Row>
                    <Col className="col-4">
                        <Label>Message Preview</Label>
                    </Col>
                        <Col className="col-8">
                            {!finalMessages?.length ? (
                                <Loader />

                            ) : (


                            <div
                                className={classNames('p-2 rounded-soft compose-preview-message', {
                                'bg-200': false,
                                'bg-primary text-white': true
                                })}
                            >
                                {//(message || message?.text) && (
                                (
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${finalMessages ? finalMessages[`${parseInt(currentMessage?.index)}`]?.message : ""}` }} />
                                )}
                            </div>


                            )}
                                <Row className="mt-1">
                                    <Button
                                        onClick={() => {return previousMessage()}}
                                        color="link"
                                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-5 ml-5"
                                        id="previous"
                                    >
                                        <FontAwesomeIcon icon="arrow-left" />
                                    </Button>
                                    <Button
                                        onClick={() => {return nextMessage()}}
                                        color="link"
                                        className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
                                        id="next"
                                    >
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="previous">
                                        Previous
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip placement="bottom" target="next">
                                        Next
                                    </UncontrolledTooltip>
                                </Row>
                        </Col>
                </Row>
                
                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>To:</Label>
                    </Col>
                    <Col className="col-10">
                        {contactLength?.length ? (
                            <Badge color="soft-primary" pill className="fs--1 overflow-hidden">
                            {`${contactLength?.length} contacts`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                        ) : ( "")}
                        {messageDetails?.groupNames.map((el, i) => (
                            <div key={i}>
                            <Badge color="soft-success" pill className="fs--1">
                            {`Group: ${el?.title } with ${el?.contacts?.length} contacts`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                            </div>
                        ))}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>From:</Label>
                    </Col>
                    <Col className="col-8">
                    <Badge color="soft-info" pill className="fs--1">
                            {bulkNumber?.nickName ? bulkNumber?.nickName + " " + bulkNumber?.phoneNumber : bulkNumber?.phoneNumber}
                            </Badge>
                       
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-4">
                        <Label>Message Length:</Label>
                    </Col>
                    <Col className="col-8">
                    <Badge color="soft-info" pill className="fs--1">
                            {`${finalMessages ? finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length : ""} characters`}
                            </Badge>
                       
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-4">
                        <Label>Segments:</Label>
                    </Col>
                    <Col className="col-8">
                    <Badge color="soft-info" pill className="fs--1">
                            1
                            </Badge>
                       
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-2">
                        <Label>Total cost:</Label>
                    </Col>
                    <Col className="col-10">
                    <Badge color="soft-info" pill className="fs--1">
                    `${finalMessages ? Math.ceil(finalMessages[`${parseInt(currentMessage?.index)}`]?.message?.length / 156) * allGroupContacts + contactLength?.length : ""} credits`
                            </Badge>
                       
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </div>

  )


}



export default CampaignDetails