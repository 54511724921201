import { KEYWORD_DELETE_REQUEST, KEYWORD_DELETE_SUCCESS, KEYWORD_DELETE_FAIL } from '../actions/types';

export default function keywordDeleteReducer(state = {}, action) {
    switch(action.type) {
        case KEYWORD_DELETE_REQUEST:
            return { loading: true }
        case KEYWORD_DELETE_SUCCESS:
            return { loading: false, success: true}
        case KEYWORD_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}