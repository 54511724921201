import React, {Fragment} from 'react';
import Section from '../../common/Section';
import { Col, Row, Card, CardBody } from 'reactstrap';
import Logo from '../../navbar/Logo';
import LogoutContent from '../LogoutContent';

const Logout = () => (
  <Fragment>
  <Section className="py-0 text-center">
<Row className="flex-center min-vh-100 py-6">
  <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
    <Logo />
    <Card>
      <CardBody className="fs--1 font-weight-normal p-5">
      <LogoutContent />
      </CardBody>
    </Card>
  </Col>
</Row>
</Section>
</Fragment>
);

export default Logout;
