import { UPDATE_SELECTED_GROUP_ROWS } from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {
       case UPDATE_SELECTED_GROUP_ROWS: // could have issue with empty string may need to do action.payload || false
   
       if(!state.length) {
        return action.payload.selectedFlatRows.map((el) => { return {id: el.id, original: el.original}})
       } else {
       const matched = Object.keys(action.payload.selectedRowIds).map((selRow) => { 
            const foundRow = state.filter((el) => { return el.id === selRow})[0]
                if(foundRow) {
                 
                return foundRow
                } else {
    
                    const newRow = action.payload.selectedFlatRows.filter((el) => { return el.id === selRow})[0]
                   
                return {id: newRow.id, original: newRow.original}
                }
        })
        return matched
        }

            default:
            return state;
    }
}