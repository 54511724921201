import React, { useEffect, useRef, useState, Fragment, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalHeader, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import rrulePlugin from '@fullcalendar/rrule';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarEventModal from './CalendarEventModal';
import ScheduleBulkText from './ScheduleBulkText';
import Flex from '../common/Flex';
import { toast } from 'react-toastify';
import moment from 'moment';
import AppContext from '../../context/Context'
import { getPageName } from '../../helpers/utils';
import ScheduleTime from '../compose/ScheduleTime';
import Loader from '../common/Loader';
import { listCalendar, listFields, listContacts } from '../../actions/index';
import { UPDATE_MONTH_VIEW, CALENDAR_UPDATE_RESET } from '../../actions/types';


//                                                  showTimeModal and set are for compose
const Calendar = ({ showCalendar, setShowCalendar, recipients, bulkMessage, setBulkMessage, showDetailModal, setShowDetailModal, eventId, setEventId, calendarView, setCalendarView, showTimeModal, setShowTimeModal, isOpenScheduleModal, setIsOpenScheduleModal}) => {
  const calendarRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth)
  const [calendarApi, setCalendarApi] = useState({});
  const [title, setTitle] = useState('');
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState(false);
  const [addScheduleStartDate, setAddScheduleStartDate] = useState();
  const [addScheduleEndDate, setAddScheduleEndDate] = useState();
  const [fromCalendar, setFromCalendar] = useState(true)
  const [showViewModal, setShowViewModal] = useState(false)
  const [view, setView] = useState("Month View")
  const isCompose = getPageName('sms-compose')
  const calendar = useSelector((state) => state.calendar)
  


  const {
   
    isDark,

  } = useContext(AppContext);


  
  //----------------------------Redux state
  const calendarCreate = useSelector((state) => state.calendarCreate)
  const { success: successCreate } = calendarCreate

  const calendarDelete = useSelector((state) => state.calendarDelete)
  const { success: successDelete } = calendarDelete

  const calendarUpdate = useSelector((state) => state.calendarUpdate)
  const { success: successUpdate } = calendarUpdate

  const fields = useSelector((state) => state.fields)
  //const contacts = useSelector((state) => state.contacts)
  const groups = useSelector((state) => state.groups)

  const buttonText = {
    today: 'Today',
    month: 'Month view',
    week: 'week',
    day: 'day',
    listWeek: 'list view',
    listYear: 'year'
  };

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const viewName = ['Month View', 'Week View', 'Day View', 'List View', 'Year View'];
  const composeViewName = ['Month View', 'Week View', 'Day View'];

  const views = {
    week: {
      eventLimit: 3
    }
  };


  useEffect(() => {
    if(successUpdate) {
      toast(
        <Fragment>
          <strong>Campaign Updated</strong>
        </Fragment>
      );
      dispatch({type: CALENDAR_UPDATE_RESET})
     }
  }, [successUpdate])


  useEffect(() => {
    if(currentFilter === "Month View") {
      dispatch({type: UPDATE_MONTH_VIEW})
    } 
  }, [currentFilter])


  useEffect(() => {
    if(auth.timezone) {
      setCalendarApi(calendarRef.current.getApi());

    }
  }, [auth]);


  useEffect(() => {
   // dispatch(listContacts())
    dispatch(listCalendar())
    dispatch(listFields())
  }, [successCreate, successDelete, successUpdate ])




  const handleFilter = filter => {
    !isCompose ? setCurrentFilter(filter) : setCalendarView(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleEventClick = info => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      //console.log("info", info)
      setModalEventContent(info);
      setIsOpenModal(true);
    }
  };

  const closeTimeBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowTimeModal(!showTimeModal)}}>
      &times;
    </button>
  );

  return (
    <>
      <Card className="mb-3">
        <CardHeader>
          <Row noGutters className="align-items-center">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <UncontrolledTooltip placement="bottom" target="previous">
                Previous
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="bottom" target="next">
                Next
              </UncontrolledTooltip>
              <Button
                onClick={() => {
                  calendarApi.prev();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1 ml-md-2"
                id="previous"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
              <Button
                onClick={() => {
                  calendarApi.next();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
                id="next"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </Button>
            </Col>
            {!auth?.timezone ? (
               <Col xs="auto" md="auto" className="order-md-2">
               <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">
                 Loading...
               </h4>
             </Col>
                ) : (
                  <Col xs="auto" md="auto" className="order-md-2">
                  <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">
                    {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
                  </h4>
                </Col>
                )
                }
          
            <Col xs md={!isCompose ? "auto" : ""} tag={Flex} justify="end" className="order-md-3">
              <Button
                size="sm"
                color="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            {!isCompose && (
            <Col xs="auto" className="d-flex order-md-0">
              <Button color="primary" size="sm" onClick={() => { return setIsOpenScheduleModal(true), setFromCalendar(true)}}>
                <FontAwesomeIcon icon="plus" className="mr-1" /> BulkText
              </Button>
            </Col>
            )}
            {!isCompose && (
             <Col className="d-flex justify-content-end order-md-2">
             <Button
               className="mr-2"
               onClick={() => { setShowViewModal(!showViewModal)}}
               size="sm"
               color="falcon-primary"
               >{view}
                 <FontAwesomeIcon className='ml-2' icon="sort"/>
             </Button>
             </Col>
            )}
          
			     
          </Row>
        </CardHeader>
  
          <div>
        <CardBody className="p-0">
     {!auth?.timezone ? (
      <Loader />
     ) : (
       <FullCalendar
         ref={calendarRef}
         headerToolbar={false}
         plugins={[
           rrulePlugin,
           interactionPlugin,
           dayGridPlugin,
           timeGridPlugin,
           bootstrapPlugin,
           listPlugin,
           momentTimezonePlugin
               ]}
         timeZone={auth.timezone}
         initialView="dayGridMonth"
         themeSystem="bootstrap"
         dayMaxEvents={2}
         height={800}
         stickyHeaderDates={false}
         selectable
         selectMirror
         select={info => {
           const newD = new Date(info.start)
           const getOffset = (timeZone = 'UTC', date = newD) => {
             const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
             const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
             return (tzDate.getTime() - utcDate.getTime()) / 6e4;
           }
           // remove the accounts chosen timezone offset
           const rmvCusOffset = newD.setTime(newD.getTime() + getOffset(auth.timezone) * 60_000);
           const cusDD = new Date(rmvCusOffset)
           // add the local offset whole point of this is to create a new Date object and pass it to the react-datepicker. it needs a date object to work
           const addLocalOffset = cusDD.setTime(cusDD.getTime() + new Date().getTimezoneOffset() * 60_000)
          
           if(new Date(addLocalOffset) < moment().subtract(1, 'day')) {
             // if one day in the past, select future date
             toast(
               <Fragment>
                 <strong>Select Future date</strong>
               </Fragment>
             );
           } else if(isCompose) {
             return (calendarView === "Month View" ? setShowTimeModal(!showTimeModal) : ""),
             setAddScheduleStartDate(new Date(addLocalOffset))
           } else {
           setAddScheduleStartDate(new Date(addLocalOffset));
           setIsOpenScheduleModal(true);
         }
       
         }}
         views={views}
         eventTimeFormat={eventTimeFormat}
         eventClick={handleEventClick}
         events={calendar}
         eventStartEditable={false}
         buttonText={buttonText}
         
       />

     )}
       
        </CardBody>
        </div>
        
      </Card>
      {
                // addStart date modal---------------------------------------
              }
      <Modal size={"lg"} isOpen={showTimeModal && calendarView === "Month View"} centered toggle={() => { setShowTimeModal(!showTimeModal)}}>
              <ModalHeader close={closeTimeBtn} >What time should it run?</ModalHeader>
                  <ModalBody className="p-0">
                    <ScheduleTime showTimeModal={showTimeModal} setShowTimeModal={setShowTimeModal} recipients={recipients} showCalendar={showCalendar} setShowCalendar={setShowCalendar} bulkMessage={bulkMessage} setBulkMessage={setBulkMessage} composeViewName={composeViewName} addScheduleStartDate={addScheduleStartDate} />
                  </ModalBody>
            </Modal> 

            {!isCompose && (
              <Modal isOpen={showViewModal} centered toggle={() => {setShowViewModal(!showViewModal)}}>
              <ModalHeader toggle={() => {setShowViewModal(!showViewModal)}} className="d-flex justify-content-center" >Calendar view</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                      {viewName.map((el, i) => (
                        <Button
                          key={i}
                          onClick={() => {
                            return handleFilter(el),
                            setView(el),
                          setShowViewModal(false)
                          }} 
                          block 
                          className="text-info" 
                          color="light">{el}</Button>
                      ))}
                        <Button onClick={() => { setShowViewModal(!showViewModal) }} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
            )}
            




      <ScheduleBulkText
        eventId={eventId}
        setEventId={setEventId}
        fromCalendar={fromCalendar}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        addScheduleStartDate={addScheduleStartDate}
        setAddScheduleStartDate={setAddScheduleStartDate}
        addScheduleEndDate={addScheduleEndDate}
        setAddScheduleEndDate={setAddScheduleEndDate}
        modalEventContent={modalEventContent}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        showDetailModal={showDetailModal}
        setShowDetailModal={setShowDetailModal}


      />
     

      <CalendarEventModal
       // contacts={contacts}
        fields={fields}
        groups={groups}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modalEventContent={modalEventContent}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
      />
    </>
  );
};

export default Calendar;
