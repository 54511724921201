import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Media,
  Row,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Flex from '../common/Flex';
import Moment from 'react-moment';
import Loader from '../common/Loader';
import PauseEventModal from './PauseEventModal';
import AdvanceTable from '../common/AdvanceTable';
import AdvanceTableFooter from '../common/AdvanceTableFooter';
import AdvanceTableSearchBox from '../common/AdvanceTableSearchBox';
import AdvanceTableWrapper from '../common/AdvanceTableWrapper';
import { listPausedCalendar, listCalendar, calendarResume, resumeMultipleCalendars, deleteMultipleCalendars } from '../../actions/index';

const PausedTable = ({ showDetailModal, setShowDetailModal, eventId, setEventId, isOpenScheduleModal, setIsOpenScheduleModal}) => {



//--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();

  const pausedCalendar = useSelector((state) => state.pausedCalendar)
  const fields = useSelector((state) => state.fields)
  const groups = useSelector((state) => state.groups)
  const contacts = useSelector((state) => state.contacts)


  const updateCalendar = useSelector((state) => state.calendarUpdate)
  const { success: successUpdate } = updateCalendar



  const calendarDelete = useSelector((state) => state.calendarDelete)
  const { success: successDelete } = calendarDelete


  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showResumeModal, setShowResumeModal] = useState(false)
  const [modalEventContent, setModalEventContent] = useState()
  const [rowDelete, setRowDelete] = useState()

    const selectionCallback = useCallback((selectedFlatRows) => { return  setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id})), setRowDelete([])}, 
    [setIsSelected]);
  
//--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------
 
const titleFormatter = (rowData ) => {
  const {title} = rowData.row.original
  
  return (
      <Media tag={Flex} align="center" className="cursor-pointer" onClick={() => { return setModalEventContent(rowData.row.original), setShowDetailModal(!showDetailModal)}}>
        <Media body className="ml-2 mt-2">
          <h5 className="mb-0 fs--1">{title}</h5>
        </Media>
      </Media>
  );
};


const toFormatter = (rowData) => {
    const { messageDetails, contactLength } = rowData.row.original
    
    return (
      <Media body className="mt-1">
        {contactLength?.length ? (
                            <Badge color="soft-primary" pill className="fs--1">
                            {`${contactLength?.length} individual contacts`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                        ) : ( "")}
                        {messageDetails?.groupNames.map((el) => (
                            <div>
                            <Badge color="soft-success" pill className="fs--1">
                            {`Group: ${el?.title }`}
                        <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
                            </Badge>
                            </div>
                        ))}
    </Media>
    );
  };

  const repeatCampaignFormatter = (rowData) => {
    const {repeatCampaign} = rowData.row.original
  return (
   
        <Media tag={Flex} align="center">
          <Media body className="ml-2">
            <h5 className="mb-0 fs--1 mt-2">{repeatCampaign}</h5>
          </Media>
        </Media>
  );
};

const startDateFormatter = (rowData) => {
  const {start} = rowData.row.original
  
return (
    <Media tag={Flex} align="center">
      <Media body className="ml-2">
        <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={start}/></h5>
      </Media>
    </Media>
)};

  const actionFormatter = (rowData) => {
    return (
    // Control your row with this id // there was a : after {id} now its a ,
   
    <div className="d-flex justify-content-end">
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal bg-soft-primary">
       <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
     </DropdownToggle>
     <DropdownMenu className="border py-2 mr-7 mr-xxl-8">
       <DropdownItem onClick={() => { return dispatch(calendarResume({campaignId: rowData?.row?.original?._id}))}}><FontAwesomeIcon icon="play" className="fs--1" />{` Resume Campaign`}</DropdownItem>
       <DropdownItem onClick={() => { return setModalEventContent(rowData?.row?.original), setShowDetailModal(!showDetailModal)}} ><FontAwesomeIcon icon="edit" className="fs--1" />{` View/Edit Campaign`}</DropdownItem>
       <DropdownItem onClick={() => { return setRowDelete([rowData?.row?.original?._id]), setShowDeleteModal(!showDeleteModal)}} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete Campaign`}</DropdownItem>
     </DropdownMenu>
  
   </UncontrolledDropdown>
   </div>
   
    )
};

//--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      Cell: titleFormatter
    },

    {
      accessor: 'messageDetails',
      Header: 'To',
      Cell: toFormatter,
    },
    {
      accessor: 'repeatCampaign',
      Header: 'Type',
      Cell: repeatCampaignFormatter
    },
    {
      accessor: 'start',
      Header: 'Start date',
      Cell: startDateFormatter
    },
    {
      accessor: 'actions',
      headerProps: {
          className: 'd-flex justify-content-end',
          
      },
      Cell: actionFormatter,
      sticky: 'right',
      
  }
  ];
  



//--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------
  
useEffect(() => {
  dispatch(listPausedCalendar())
}, [successUpdate, successDelete])


useEffect(() => {
  setRowDelete([])
}, [])

//--------------------------------------------------------------------------Render------------------------------------------------------------------------------------
  
  return (
    <Card className="mb-3">
      <FalconCardHeader title="" light={false}>
          <Fragment>
          <ButtonGroup className="mt-2">
          <ButtonIcon icon="play" onClick={() => { return setShowResumeModal(!showResumeModal)}} disabled={!isSelected.length} transform="shrink-3" color="falcon-success" size="sm" >
              Resume
            </ButtonIcon>
            <Modal isOpen={showResumeModal} centered toggle={() => setShowResumeModal(!showResumeModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                      <Label className="text-center">Selected Campaigns will be set to active and placed back in Calendar</Label>
                        <Button onClick={() => { if(rowDelete?.length) { dispatch(resumeMultipleCalendars(rowDelete))} else { dispatch(resumeMultipleCalendars(isSelected))} setShowResumeModal(!showResumeModal)}} block className="text-success" color="light">Resume Selected Campaigns</Button>
                            <Button onClick={() => { return setShowResumeModal(!showResumeModal), setRowDelete([])}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
            <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal)}} disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <Button onClick={() => { if(rowDelete?.length) { dispatch(deleteMultipleCalendars(rowDelete))} else { dispatch(deleteMultipleCalendars(isSelected))} setShowDeleteModal(!showDeleteModal)}} block className="text-danger" color="light">Delete Selected Campaigns</Button>
                            <Button onClick={() => { return setShowDeleteModal(!showDeleteModal), setRowDelete([])}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>

               <PauseEventModal
                   eventId={eventId}
                   setEventId={setEventId}
                   contacts={contacts}
                   fields={fields}
                   groups={groups}
                   showDetailModal={showDetailModal}
                   setShowDetailModal={setShowDetailModal}
                   modalEventContent={modalEventContent}
                   isOpenScheduleModal={isOpenScheduleModal}
                   setIsOpenScheduleModal={setIsOpenScheduleModal}
                 />

            </ButtonGroup>
          </Fragment>
      </FalconCardHeader>
      <CardBody className="p-0">

      {!pausedCalendar ? (
          <div>
            <Loader />
            </div>
        ) : (
            
            <Fragment>
                <Card>
                
                <AdvanceTableWrapper
                  columns={columns}
                  data={pausedCalendar}
                  sortable
                  pagination
                  perPage={12}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                  
                >
                  <Row className="flex-start-center mb-3 ml-1">
                    <Col xs="auto" sm={6} lg={4}>
                      <AdvanceTableSearchBox table/>
                    </Col>
                  </Row>
                 
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceTableFooter
                      rowCount={pausedCalendar?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
                
                </Card>
                </Fragment>
              )
            }
      </CardBody>
    </Card>
  );
};


export default PausedTable;
