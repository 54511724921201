import { BLOCKED_CONTACT_CREATE_REQUEST, BLOCKED_CONTACT_CREATE_SUCCESS, BLOCKED_CONTACT_CREATE_FAIL, BLOCKED_CONTACT_CREATE_RESET } from '../actions/types';

export default function blockedContactCreateReducer(state = {}, action) {
    switch(action.type) {
        case BLOCKED_CONTACT_CREATE_REQUEST:
            return { loading: true }
        case BLOCKED_CONTACT_CREATE_SUCCESS:
            return { loading: false, success: true}
        case BLOCKED_CONTACT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case BLOCKED_CONTACT_CREATE_RESET:
            return { }
        default:
            return state
    }
}