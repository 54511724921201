import { FETCH_USER, UPDATE_USER_CREDIT } from '../actions/types';

const initialState = {
    users:[],
    loading:true
}

export default function (state = initialState, action) {
    
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false // when we logout, this action.payload is an empty string so lets do || false

        case UPDATE_USER_CREDIT: 
        console.log("action.payload", action.payload)
            const newAuth = {...state, credits: action.payload.user.credits}
            return newAuth
       
            default:
            return state;
    }
}