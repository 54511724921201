/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const AdvanceTableSearchBox = ({ globalFilter, setGlobalFilter, placeholder = 'Search...' }) => {
  
  const [value, setValue] = useState(globalFilter);

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success:successDelete } = contactDelete

  useEffect(() => {
    setValue()
  }, [successDelete])

  const onChange = useAsyncDebounce(value => {
   
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => { 
          setValue(value); // set state variable
          onChange(value); // then call onChange to with value
        }}
        size="md"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
        autoComplete="off"
      />
      <InputGroup.Text className="bg-transparent">
        <FontAwesomeIcon icon="search" className="fs--1 text-600" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;