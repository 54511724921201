import { DELETE_SENT_MESSAGES_CREATE_REQUEST, DELETE_SENT_MESSAGES_CREATE_SUCCESS, DELETE_SENT_MESSAGES_CREATE_FAIL } from '../actions/types';
    
    export default function sentHistoryDeleteReducer(state = {}, action) {
        switch(action.type) {
            case DELETE_SENT_MESSAGES_CREATE_REQUEST:
                return { loading: true }
            case DELETE_SENT_MESSAGES_CREATE_SUCCESS:
                return { loading: false, success: true}
            case DELETE_SENT_MESSAGES_CREATE_FAIL:
                return { loading: false, error: action.payload }
            default:
                return state
        }
    }