import { TEMPLATE_DELETE_REQUEST, 
TEMPLATE_DELETE_SUCCESS,
TEMPLATE_DELETE_FAIL } from '../actions/types';

export default function templateDeleteReducer(state = {}, action) {
    switch(action.type) {
        case TEMPLATE_DELETE_REQUEST:
            return { loading: true }
        case TEMPLATE_DELETE_SUCCESS:
            return { loading: false, success: true}
        case TEMPLATE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}