import { NUMBER_DETAILS_REQUEST, NUMBER_DETAILS_FAIL, NUMBER_DETAILS_SUCCESS } from "../actions/types"

export default function numberDetailsReducer ( state = [], action ) {
    switch(action.type) {
        case NUMBER_DETAILS_REQUEST:
            return { loading: true }
        case NUMBER_DETAILS_SUCCESS:
            return action.payload
        case NUMBER_DETAILS_FAIL:
            return { loading: false, error: action.payload}
            default:
                return state
    }
}