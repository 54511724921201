import React, { useRef, Fragment, useState } from 'react'
import { Button, Card, CardBody, Col, Media } from 'reactstrap';
import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import { useDispatch, useSelector } from 'react-redux';
import { attachFile } from '../../actions/index';
import axios from 'axios';







const AttachFile = ({ highlighted, setHighlighted, showFileModal, setShowFileModal }) => {

    const InputFile = useRef(null)

    const [userFile, setUserFile ] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [url, setUrl] = useState('')


    const dispatch = useDispatch()

    const attachFileCreate = useSelector((state) => state.attachFileCreate);

    const onChange = (e) => {
        const files = e.target.files;
        files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    };

    const onButtonClick = (e) => {
        e.preventDefault();
       InputFile.current.click();
      };
      
      


    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
      }

      


   const manualFileUpload = async (e) => {
       e.preventDefault();
       if(e.target.files[0].name) {
           setUserFile(e.target.files[0].name)
           const file = e.target.files[0];
           //const blob = new Blob(file, {'type': "application/pdf"})
           const convertedFile = await convertToBase64(file)
           setUploadFile({image: convertedFile, imageName: file.name, type: file.type})
       }
     }


//     const manualUploadFile = (e) => {
//        e.preventDefault();
//
//        // Create an object of formData
//        let formData = new FormData();
//
//        // Update the formData object
//
//        formData.append('file', e.target.files[0]);
//
//        axios.post("http://localhost:5000/attach-file", formData, { headers: {'Content-Type': 'multipart/form-data'}}, {withCredentials: true})
//            .then((res) => {
//                //console.log(res)
//                if (res.status === 200)
//                    return (this.setState({sucessmessage: "File uploaded successfullyS3"}))
//
//            })
//            .catch((error) => {
//                //console.error(error.response);
//                this.setState({errormessage:error.response.statusText+" Please select the file"})
//
//            })
//
//    };





    return (
            <div>
                    <Card>
                        <CardBody className="fs--1 font-weight-normal p-4">

                          
                          <h4 className="mb-1 mt-3 text-center"></h4>
                          <div>
                            <input 
                              type="file" 
                              onChange={(e) => manualFileUpload(e)}
                              accept=".jpg, .gif, .png" 
                              ref={InputFile}
                              className="d-none" />
                            <div className={`mb-2 p-3 border-dashed border-2x border-300 bg-light rounded-soft text-center cursor-pointer ${highlighted ? ' border-800' : ''}`}
                              onClick={(e) => onButtonClick(e)}
                              onDragEnter={() => {setHighlighted(true)}}
                              onDragLeave={() => {setHighlighted(false)}}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                              onDrop={(e) => {
                              e.preventDefault();
                              
                              }}
                            >
                            <Fragment>
                              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                                <img src={cloudUpload} alt="" width={25} className="mr-2" />
                              <Media>
                                <p className="fs-0 mb-0 text-700">{userFile ? userFile : "Upload your File"}</p>
                              </Media>
                              </Media>
                                <p className="mb-0 w-75 mx-auto text-500">Supported file types: .jpg, .gif file formats</p>
                            </Fragment>
                            </div>
                            </div>
                          <p className="fs-0 text-center">A clickable hyperlink will be included in your SMS where recipients can view / download your file.</p>
                          <Col className="text-center">
                            <Button disabled={!uploadFile} color="primary" onClick={() => { return dispatch(attachFile(uploadFile)), setShowFileModal(!showFileModal) }} className="my-3 text-white">
                            {attachFileCreate.loading ? "...processing" : "Attach File"}
                            </Button>
                          </Col>
                        </CardBody>
                      </Card>
            </div>
        )
    }

export default AttachFile