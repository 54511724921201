import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import Loader from '../common/Loader';
import Divider from '../common/Divider';
import Moment from 'react-moment';
import AdvanceTable from '../common/AdvanceTable';
import AdvanceTableFooter from '../common/AdvanceTableFooter';
import AdvanceTableSearchBox from '../common/AdvanceTableSearchBox';
import AdvanceTableWrapper from '../common/AdvanceTableWrapper';
import ContactEditModal from './ContactEditModal';
import GroupContactChoose from './GroupContactChoose';
import MoveCopy from './MoveCopy';
import { groupById, groupByIdTitle, updateContact, updateGroup, createBlockedContacts, deleteMultipleContacts, unsubscribeContacts } from '../../actions/index';
import CustomCardSummary from '../dashboard/CustomCardSummary';
import { getPageName } from '../../helpers/utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ADD_COMPOSE_GROUP, ADD_COMPOSE_CONTACT } from '../../actions/types';

const NewGroupEdit = ({match, tempGroupId, groupTitle, fromCompose, showEditGroupModal, setShowEditGroupModal}) => {

const windowSize = useWindowDimensions()

const GroupId = tempGroupId || match.params.id 

const history = useHistory();

const isSMSCompose = getPageName('sms-compose');



//--------------------------------------------------------------------------State-------------------------------------------------------------------------

const [showDeleteModal, setShowDeleteModal] = useState(false)
const [chooseImportModal, setChooseImportModal ] = useState(false);
const [showContactUpdateModal, setShowContactUpdateModal ] = useState(false);
const [showGroupEditModal, setShowGroupEditModal] = useState(false);
const [showMoveModal, setShowMoveModal] = useState(false);
const [showBlockModal, setShowBlockModal] = useState(false)
const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
const [changeText, setChangeText] = useState();
const [contact, setContact] = useState({ });
const [blockContact, setBlockContact] = useState()
const [showSaveModal, setShowSaveModal] = useState(false)

const [isSelected, setIsSelected] = useState(false);
const [selectedRows, setSelectedRows] = useState();
const [tempContact, setTempContact] = useState();
const [rowDelete, setRowDelete] = useState()
const [updateGroupEdit, setUpdateGroupEdit] = useState()
const [selectedContactRows, setSelectedContactRows] = useState()

const selectionCallback = useCallback((selectedFlatRows) => { return  setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id})), setSelectedContactRows(selectedFlatRows), setRowDelete([])}, 
[setIsSelected]);


const dispatch = useDispatch();

const groups = useSelector((state) => state.groups)
const fields = useSelector((state) => state.fields)
const conversations = useSelector((state) => state.conversations)

const contactCreate = useSelector((state) => state.createContact)
const { success: successCreate } = contactCreate

const contactDelete = useSelector((state) => state.deleteContact)
const { success: successDeleteContact } = contactDelete

const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
const { success:successBlockedCreate } = blockedContactCreate

const groupDetails = useSelector((state) => state.groupDetails)
const {success: successGet} = groupDetails

const groupUpdate = useSelector((state) => state.groupUpdate)
const { success: successUpdate } = groupUpdate

const groupMoveCopy = useSelector((state) => state.groupMoveCopy)
const { success: successMoveCopy } = groupMoveCopy

const contactUpdate = useSelector((state) => state.updateContact)
const { success: successContactUpdate } = contactUpdate

const groupReduxTitle = useSelector((state) => state.groupTitle)

const openGroups = []
  groups.map((g) => { if(!tempContact?.group.filter((cg) => cg === g._id)[0]) { openGroups.push(g) }})



//-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

const firstNameFormatter = (rowData, {avatar}) => {
    const { _id, firstName } = rowData.row.original
    return (
      <Link to={`/contact/${_id}`}>
        <Media tag={Flex} align="center">
          <Avatar name={firstName} {...avatar} />
          <Media body className="ml-2">
            <h5 className="mb-0 fs--1">{firstName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };
  
  const lastNameFormatter = (rowData) => {
    const { _id, lastName} = rowData.row.original
    return (
      <Link to={`/contact/${_id}`}>
        <Media tag={Flex} align="center">
          <Media body className="ml-2 mt-2">
            <h5 className="mb-0 fs--1">{lastName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };
  
  const phoneFormatter = (rowData) => {
    const { _id, phone_number } = rowData.row.original
    return (
        <Media tag={Flex} align="center">
          <Media body className="ml-2 mt-2">
            <h5 className="mb-0 fs--1">{phone_number}</h5>
          </Media>
        </Media>
    );
  };

  const createdFormatter = (rowData) => {
    const {created} = rowData.row.original
    
  return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={created}/></h5>
        </Media>
      </Media>
  )};
  
  const actionFormatter = (rowData) => {
    
    const { firstName, lastName, phone_number, _id} = rowData.row.original
    const composeContact = {firstName: firstName, lastName: lastName, phoneNumber: phone_number, _id: _id}
    return (
    <UncontrolledDropdown>
      <DropdownToggle onClick={() => {console.log("this ran")}}color="link" size="sm" className="text-600 btn-reveal bg-soft-primary">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu className="border py-2 mr-7 mr-xxl-8">
        <DropdownItem onClick={() => { return dispatch({type: ADD_COMPOSE_CONTACT, payload: { contactCompose: composeContact }})}} tag={Link} to="/pages/sms-compose" className="text-info"><FontAwesomeIcon icon="sms" className="fs--1" />{` Send sms to this contact`}</DropdownItem>
        <DropdownItem onClick={() => { return setTempContact({...rowData.row.original, fields: fields?.map((field) => { return {title: field.title, body: rowData.row.original.fields[field.title] }}), group: groups?.filter((el) => rowData?.row?.original?.group?.includes(el._id))}), setShowContactUpdateModal(!showContactUpdateModal)}}><FontAwesomeIcon icon="edit" className="fs--1" />{` Edit`}</DropdownItem>
        <DropdownItem onClick={() => { return setShowBlockModal(!showBlockModal), setBlockContact({_id: _id, phoneNumber: phone_number})}}><FontAwesomeIcon icon="ban" className="fs--1" />{` Block`}</DropdownItem>
        <DropdownItem onClick={() => { return setShowUnsubscribeModal(!showUnsubscribeModal), setBlockContact(composeContact)}}><FontAwesomeIcon icon="minus" className="fs--1" />{` Unsubscribe`}</DropdownItem>
        <DropdownItem onClick={() => { return setRowDelete([_id]), setShowDeleteModal(!showDeleteModal) }} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    )
};


  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    
    {
        accessor: 'firstName',
        Header: 'First Name',
        Cell: firstNameFormatter
    },
    {
        accessor: 'lastName',
        Header: 'Last Name',
        Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
    }
  },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
        accessor: 'phone_type',
        Header: 'Type', 
    },
    {
        accessor: 'created',
        Header: 'Created',
        Cell: createdFormatter
    },
    {
      accessor: 'actions',
      headerProps: {
          className: 'd-flex justify-content-end',
          
      },
      Cell: actionFormatter,
      sticky: 'right',
      
    }
  ];
  
 
  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------

  const toggleSave = () => { 
    if(changeText === true) {
    return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
    setShowContactUpdateModal(!showContactUpdateModal)
    
    }


  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => {setChooseImportModal(!setChooseImportModal)}}>
      &times;
    </button>
  );

  const closeMoveBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowMoveModal(!showMoveModal)}}>
      &times;
    </button>
  );

  const closeGroupEditBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowGroupEditModal(!showGroupEditModal)}}>
      &times;
    </button>
  );
  
  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------

  
  useEffect(() => {
    dispatch(groupByIdTitle(GroupId))
    dispatch(groupById(GroupId))   
  }, [ successGet, successUpdate, successCreate, successDeleteContact, successContactUpdate, successBlockedCreate, successMoveCopy])  

 
  return (
    <Card className="mb-3">
       {groupTitle ? <CustomCardSummary title="" rate={groupDetails?.length ? groupDetails?.length  + " contacts" : ""} color="success">
          {groupTitle}
          </CustomCardSummary> : <CustomCardSummary title="" rate={groupReduxTitle?.groupTitle?.data?.contacts?.length ? groupReduxTitle?.groupTitle?.data?.contacts?.length + " contacts" : ""} color="success">
          {groupReduxTitle?.groupTitle?.data?.title}
          </CustomCardSummary>
          }
       <FalconCardHeader title="" light={false}>
          <Fragment>
            
          <ButtonGroup className="mt-3">
          <ButtonIcon icon="chevron-left" onClick={() => { isSMSCompose ? setShowEditGroupModal(!showEditGroupModal) : history.goBack()}} transform="shrink-3 down-2" color="falcon-primary" size="sm" >
            Back</ButtonIcon>
            { windowSize?.width <= 545 && (
              <div>
            <UncontrolledDropdown>
            <DropdownToggle transform="shrink-3 down-2" color="falcon-success" size="sm" >
               <FontAwesomeIcon icon="chevron-down" className="fs--1" />{` Actions`}
            </DropdownToggle>
            <DropdownMenu className="mr-5">
                <DropdownItem onClick={() => { setChooseImportModal(true)}}><FontAwesomeIcon icon="plus" className="fs--1 falcon-success" />{` Contact`}</DropdownItem>
                <DropdownItem onClick={() => { if(!isSelected?.length) { return dispatch({type: ADD_COMPOSE_GROUP, payload: { groupCompose: selectedRows }})} else { return setShowEditGroupModal(!showEditGroupModal), dispatch({type: ADD_COMPOSE_CONTACT, payload: { contactCompose: selectedContactRows.map((el) => { return el.original}) }})}}} tag={Link} to="/pages/sms-compose"><FontAwesomeIcon icon="plus" className="fs--1" />{` Send SMS`}</DropdownItem>
                <DropdownItem disabled={!isSelected.length} onClick={() => { return setShowMoveModal(!showMoveModal)}}><FontAwesomeIcon icon="copy" className="fs--1" />{` Move/Copy`}</DropdownItem>
                <DropdownItem disabled={!isSelected.length} onClick={() => {setShowGroupEditModal(!showGroupEditModal)}}><FontAwesomeIcon icon="edit" className="fs--1" />{` Edit`}</DropdownItem>
                <DropdownItem disabled={!isSelected.length} onClick={() => { setShowDeleteModal(!showDeleteModal) }} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown>
            </div>
         
          )}
          { windowSize?.width > 545 && (
              <ButtonIcon icon="plus" onClick={() => { setChooseImportModal(true)}} transform="shrink-3 down-2" color="falcon-success" size="sm" >
              Contacts
            </ButtonIcon>
          )}
            <Modal isOpen={chooseImportModal} centered toggle={() => setChooseImportModal(!setChooseImportModal)}>
            <ModalHeader close={closeBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                How would you like to Import?
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                                <GroupContactChoose chooseImportModal={chooseImportModal} setChooseImportModal={setChooseImportModal}  GroupId={GroupId} />
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>

                { windowSize?.width > 545 && (
            <ButtonIcon icon="sms" tag={Link} to="/pages/sms-compose" onClick={() => { if(!isSelected?.length) { return dispatch({type: ADD_COMPOSE_GROUP, payload: { groupCompose: selectedRows }})} else { return setShowEditGroupModal(!showEditGroupModal), dispatch({type: ADD_COMPOSE_CONTACT, payload: { contactCompose: selectedContactRows.map((el) => { return el.original}) }})}}} transform="shrink-3 down-2" color="falcon-info" size="sm" >
               SMS
            </ButtonIcon>
             
          )}
          { windowSize?.width > 545 && (
            <ButtonIcon icon="copy" onClick={() => { return setShowMoveModal(!showMoveModal)}} disabled={!isSelected.length} transform="shrink-3" color="falcon-info" size="sm" >
             Move/Copy
            </ButtonIcon>
        
          )}
            <Modal isOpen={showMoveModal} centered toggle={() => setShowMoveModal(!showMoveModal)}>
            <ModalHeader close={closeMoveBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Move Contacts
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                              <MoveCopy isSelected={isSelected} groupDetails={groupReduxTitle?.groupTitle?.data?._id} showMoveModal={showMoveModal} setShowMoveModal={setShowMoveModal}/>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>

                { windowSize?.width > 545 && (
    
            <ButtonIcon icon="edit" onClick={() => {setShowGroupEditModal(!showGroupEditModal)}} disabled={!isSelected} transform="shrink-3 down-2" color="falcon-primary" size="sm" >
              Edit
            </ButtonIcon>
          )}
            <Modal isOpen={showGroupEditModal} centered toggle={() => setShowGroupEditModal(!showGroupEditModal)}>
            <ModalHeader close={closeGroupEditBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Update Group  
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                              <Label for="title">Group Name</Label>
                                <Input defaultValue={groupReduxTitle?.groupTitle?.data?.title} value={updateGroupEdit?.title?.value} onChange={(e) => {setUpdateGroupEdit({...updateGroupEdit, title: e.target.value})}} className="mb-3" name="title" id="title"/>
                              <Button block color="primary" className="mb-3" onClick={() => { return dispatch(updateGroup({title: updateGroupEdit?.title, _id: groupReduxTitle?.groupTitle?.data?._id})), setShowGroupEditModal(!showGroupEditModal)}}>Save</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>

            <Modal size="lg" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
                <ModalHeader toggle={toggleSave} className="bg-light border-bottom-0">
                  <ButtonIcon 
                  onClick={() => { return dispatch(updateContact(contact, tempContact._id)), setShowContactUpdateModal(!showContactUpdateModal)}} 
                  disabled={!changeText} 
                  transform="shrink-3 down-2" 
                  color="falcon-primary" 
                  icon=""
                  size="md">done</ButtonIcon>
                </ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <ContactEditModal 
                            contact={contact}
                            ContactId={tempContact?._id} 
                            changeText={changeText}
                            setContact={setContact} 
                            openGroups={openGroups}
                            successCreate={successCreate} 
                            contactGroups={tempContact?.group} 
                            contactFields={tempContact?.fields}  
                            setChangeText={setChangeText} />
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Label className="center">Are you sure you want to discard changes?</Label>
                          <Divider></Divider>   
                            <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal)}} block className="text-danger" color="light">Discard Changes</Button>
                            <Button onClick={() => { setShowSaveModal(!showSaveModal)}} block className="text-primary" color="light" >Keep editing</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              { windowSize?.width > 545 && (
            <ButtonIcon onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
           
          )}
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                      <Label className="d-flex justify-content-center" >Contacts will be permenantly deleted</Label>
                          <Divider></Divider> 
                      <Button 
                          block
                          onClick={() => { if(rowDelete?.length) { 
                            return dispatch(deleteMultipleContacts(rowDelete, conversations)), 
                            setShowDeleteModal(!showDeleteModal)
                            } else { 
                            return dispatch(deleteMultipleContacts(isSelected, conversations)), 
                            setShowDeleteModal(!showDeleteModal)
                            }}} 
                          className="text-danger" 
                          color="light">Delete contacts</Button>
                        <Button onClick={() => { setShowDeleteModal(!showDeleteModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // Blocked Modal-----------------------------------------
              }
              <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Label className="text-center">You will not recieve phone calls or messages from people on the block list.</Label>
                          <Divider></Divider>   
                            <Button onClick={() => { 
                                     return dispatch(createBlockedContacts({contacts: [{item: {_id: blockContact._id, phone_number: blockContact.phoneNumber}}]})), // data is in this format just to match for action, could just create a "singleBlockContact but didnt feel like it"
                                       setShowBlockModal(!showBlockModal)
                                       }} 
                                  block 
                                  className="text-danger" color="light">Block Contact</Button>
                            <Button onClick={() => { setShowBlockModal(!showBlockModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // Unsubscribe Modal---------------------------------------
              }
              <Modal isOpen={showUnsubscribeModal} centered toggle={() => {setShowUnsubscribeModal(!showUnsubscribeModal)}}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                      <Label className="text-center">Contact will be added to Unsubscribers group. If you Resubscribe contact they will go back to their original group</Label>
                      <Divider />
                        <Button onClick={() => {
                          return dispatch(unsubscribeContacts(blockContact)), 
                          setShowUnsubscribeModal(false)
                          }} 
                          block 
                          className="text-info" 
                          color="light">Unsubscribe contact</Button>
                        <Button onClick={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
            </ButtonGroup>
          </Fragment>
        
      </FalconCardHeader>
      <CardBody className="p-0">

      {groupDetails?.loading ? (
          <Card>
          <CardBody>
            <Loader />
          </CardBody>
        </Card>
        ) : (
            
            <Fragment>
                <Card>
                
                <AdvanceTableWrapper
                  columns={columns}
                  data={groupDetails}
                  sortable
                  pagination
                  perPage={12}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                >
                   
                  <Row className="flex-start-center mb-3 ml-1">
                    { groupTitle ? 
                    <div>
                      <Col >
                      <AdvanceTableSearchBox table/>
                      </Col>
                    </div> : <div>
                      <Col >
                        <AdvanceTableSearchBox table/> 
                      </Col>
                    </div>
                    }
                  </Row>
                 
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceTableFooter
                      rowCount={groupDetails?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
                
                </Card>
                </Fragment>
              )
            }
      </CardBody>
    </Card>
  );
};


export default NewGroupEdit;