import { combineReducers } from 'redux';
import authReducer from './authReducer';
import templateReducer from './templateReducer';
import profileReducer from './profileReducer';
import contactsReducer from './contactsReducer';
import contactsTotalReducer from './contactsTotalReducer';
import availNumbersReducer from './availNumberReducer';
import contactDeleteReducer from './contactDeleteReducer';
import templateDeleteReducer from './templateDeleteReducer';
import contactCreateReducer from './contactCreateReducer';
import groupCreateReducer from './groupCreateReducer';
import groupDeleteReducer from './groupDeleteReducer';
import groupReducer from './groupReducer.js';
import fieldCreateReducer from './fieldCreateReducer';
import fieldReducer from'./fieldReducer';
import fieldDeleteReducer from './fieldDeleteReducer';
import fieldUpdateReducer from './fieldUpdateReducer';
import fieldDetailsReducer from './fieldDetailsReducer';
import templateCreateReducer from './templateCreateReducer';
import csvUploadCreateReducer from './csvUploadCreateReducer';
import csvUploadDeleteReducer from './csvUploadDeleteReducer';
import csvUploadReducer from './csvUploadReducer';
import groupDetailsReducer from './groupDetailsReducer';
import contactDetailsReducer from './contactDetailsReducer';
import contactUpdateReducer from './contactUpdateReducer';
import numbersReducer from './numbersReducer';
import numberDetailsReducer from './numberDetailsReducer';
import numberCreateReducer from './numberCreateReducer';
import numberDeleteReducer from './numberDeleteReducer';
import numberUpdateReducer from './numberUpdateReducer';
import groupDetailsTitleReducer from './groupDetailsTitleReducer';
import primaryNumberReducer from './primaryNumberReducer';
import primaryNumberUpdateReducer from './primaryNumberUpdateReducer';
import creditCreateReducer from './creditCreateReducer';
import groupUpdateReducer from './groupUpdateReducer';
import blockedReducer from './blockedReducer';
import blockedContactCreateReducer from './blockedContactCreateReducer';
import blockedContactDeleteReducer from './blockedContactDeleteReducer';
import attachFileCreateReducer from './attachFileCreateReducer';
import templateUpdateReducer from './templateUpdateReducer';
import stateForDivReducer from './stateForDivReducer';
import calendarReducer from './calendarReducer';
import calendarCreateReducer from './calendarCreateReducer';
import calendarDeleteReducer from './calendarDeleteReducer';
import calendarUpdateReducer from './calendarUpdateReducer';
import keywordsReducer from './keywordsReducer';
import keywordCreateReducer from './keywordCreateReducer';
import keywordDeleteReducer from './keywordDeleteReducer';
import keywordDetailsReducer from './keywordDetailsReducer';
import composeReducer from './composeReducer';
import tabReduxReducer from './tabReduxReducer';
import attachMMSCreateReducer from './attachMMSCreateReducer';
import responderReducer from './responderReducer';
import responderDetailsReducer from './responderDetailsReducer';
import responderDeleteReducer from './responderDeleteReducer';
import responderUpdateReducer from './responderUpdateReducer';
import unSubsReducer from './unSubsReducer';
import sentMessagesReducer from './sentMessagesReducer';
import recievedMessagesReducer from './recievedMessagesReducer';
import groupMoveCopyReducer from './groupMoveCopyReducer';
import groupShowHideReducer from './groupShowHideReducer';
import groupShowHideCreateReducer from './groupShowHideCreateReducer'
import calendarPauseReducer from './calendarPauseReducer';
import eventsReducer from './eventsReducer';
import eventsPluginReducer from'./eventsPluginReducer';
import subscribeCancelReducer from './subscribeCancelReducer';
import invoiceReducer from './invoiceReducer';
import bulkTextReducer from './bulkTextReducer';
import planUpdateStateReducer from './planUpdateReducer';
import cardSecretReducer from './cardSecretReducer';
import confirmIntentReducer from './confirmIntentReducer';
import paymentMethodDeleteReducer from './paymentMethodDeleteReducer';
import paymentMethodCreateReducer from './paymentMethodCreateReducer';
import tokenCreateReducer from './tokenCreateReducer';
import callsReducer from './callsReducer';
import previewMediaReducer from './previewMediaReducer';
import chatsReducer from './chatsReducer';
import chatCreateReducer from './chatCreateReducer';
import chatDeleteReducer from './chatCreateReducer';
import chatUpdateReducer from './chatUpdateReducer';
import chatMessagesReducer from './chatMessagesReducer';
import chatMessagesDeleteReducer from './chatMessagesDeleteReducer';
import sentHistoryDeleteReducer from './sentHistoryDeleteReducer';
import activeChatReducer from './activeChatReducer';
import selectedRowsReducer from './selectedRowsReducer';
import selectedGroupRowsReducer from './selectedGroupRowsReducer';
import bulkSendCreateReducer from './bulkSendCreateReducer';
import fetchChatMessagesReducer from './fetchChatMessagesReducer';
import bulkMessageReducer from './bulkMessageReducer';
import recievedHistoryDeleteReducer from './recievedHIstoryDeleteReducer';
import bulkTextDetailsReducer from './bulkTextDetailsReducer';
import bulkTextDeliveredReducer from './bulkTextDeliveredReducer';
import bulkTextSentReducer from './bulkTextSentReducer';
import bulkTextFailedReducer from './bulkTextFailedReducer';
import bulkSendDeleteReducer from './bulkSendDeleteReducer';
import SentSelectedRowsReducer from './SentSelectedRowsReducer';
import callsDeleteReducer from './callsDeleteReducer';
import forgotPasswordCreateReducer from './forgotPasswordCreateReducer';
import responderMessagesReducer from './responderMessagesReducer';
import listResponderMessagesCreateReducer from './responderMessagesCreateReducer';
import calendarCompleteReducer from './calendarCompleteReducer';
import userUpdateReducer from './userUpdateReducer';
import keywordUpdateReducer from './keywordUpdateReducer';
import merchantDetailsReducer from './merchantDetailsReducer';
import customerProfileReducer from './customerProfileReducer';
import subscriptionDetailsReducer from './subscriptionDetailsReducer';
import accountDeleteReducer from './accountDeleteReducer';
import paginationReducer from './paginationReducer';
import contactsTableReducer from './contactsTableReducer';
import contactsTableRowReducer from './contactsTableRowReducer';

export default combineReducers ({
    contactsTable: contactsTableReducer,
    auth: authReducer,
    profile: profileReducer,
    compose: composeReducer,
    tabRedux: tabReduxReducer,
    creditCreate: creditCreateReducer,
    stateForDiv: stateForDivReducer,

    chats: chatsReducer,
    accountDelete: accountDeleteReducer,
    activeChatId: activeChatReducer,
    chatCreate: chatCreateReducer,
    chatDelete: chatDeleteReducer,
    chatUpdate: chatUpdateReducer,
    chatMessages: chatMessagesReducer,
    fetchChatMessages: fetchChatMessagesReducer,
    chatMessageDelete: chatMessagesDeleteReducer,

    calls: callsReducer,
    callsDelete: callsDeleteReducer,
    sentMessages: sentMessagesReducer,
    sentHistoryDelete: sentHistoryDeleteReducer,
    recievedMessages: recievedMessagesReducer,
    recievedHistoryDelete: recievedHistoryDeleteReducer,
    attachFileCreate: attachFileCreateReducer,
    attachMMSCreate: attachMMSCreateReducer,
    previewMedia: previewMediaReducer,



    blockedContacts: blockedReducer,
    blockedContactCreate: blockedContactCreateReducer,
    blockedContactDelete: blockedContactDeleteReducer,
    contacts: contactsReducer,
    contactsTotal: contactsTotalReducer,
    createContact: contactCreateReducer,
    deleteContact: contactDeleteReducer,
    updateContact: contactUpdateReducer,
    contactDetails: contactDetailsReducer,


    groups: groupReducer,
    groupCreate: groupCreateReducer,
    groupDelete: groupDeleteReducer,
    groupDetails: groupDetailsReducer,
    groupTitle: groupDetailsTitleReducer,
    groupUpdate: groupUpdateReducer,
    groupMoveCopy: groupMoveCopyReducer,
    groupsShowHide: groupShowHideReducer,
    groupShowHideCreate: groupShowHideCreateReducer,
    unSubs: unSubsReducer,

    csvUpload: csvUploadReducer,
    csvUploadCreate: csvUploadCreateReducer,
    csvUploadDelete: csvUploadDeleteReducer,
    

    templates: templateReducer,
    createTemplate: templateCreateReducer,
    updateTemplate: templateUpdateReducer,
    deleteTemplate: templateDeleteReducer,

    keywords: keywordsReducer,
    keywordCreate: keywordCreateReducer,
    keywordUpdate: keywordUpdateReducer,
    keywordDelete: keywordDeleteReducer,
    keywordDetails: keywordDetailsReducer,

    responders: responderReducer,
    responderDetails: responderDetailsReducer,
    responderDelete: responderDeleteReducer,
    responderUpdate: responderUpdateReducer,

    fields: fieldReducer,
    fieldCreate: fieldCreateReducer,
    fieldDelete: fieldDeleteReducer,
    fieldUpdate: fieldUpdateReducer,
    fieldDetails: fieldDetailsReducer,


    numbers: numbersReducer,
    numberDetails: numberDetailsReducer,
    availNumbers: availNumbersReducer,
    createNumber: numberCreateReducer,
    deleteNumber: numberDeleteReducer,
    updateNumber: numberUpdateReducer,

    primaryNumber: primaryNumberReducer,
    primaryNumberUpdate: primaryNumberUpdateReducer,

    campaignEvents: eventsReducer,
    eventsPlugin: eventsPluginReducer,
    calendar: calendarReducer,
    pausedCalendar: calendarPauseReducer,
    completedCalendar: calendarCompleteReducer,
    calendarCreate: calendarCreateReducer,
    calendarDelete: calendarDeleteReducer,
    calendarUpdate: calendarUpdateReducer,

    tokenCreate: tokenCreateReducer,

    cardSecret: cardSecretReducer,
    confirmIntent: confirmIntentReducer,
    planUpdateState: planUpdateStateReducer,
    subscribeCancel: subscribeCancelReducer,
    paymentMethodDelete: paymentMethodDeleteReducer,
    paymentMethodCreate: paymentMethodCreateReducer,
    invoices: invoiceReducer,
    bulkText: bulkTextReducer,

    selectedRows: selectedRowsReducer,
    sentSelectedRows: SentSelectedRowsReducer,
    selectedGroupRows: selectedGroupRowsReducer,

    bulkSendCreate: bulkSendCreateReducer,
    bulkSendDelete: bulkSendDeleteReducer,


    bulkMessageReducer: bulkMessageReducer,

    bulkTextDetails: bulkTextDetailsReducer,
    bulkTextDetailsDelivered: bulkTextDeliveredReducer,
    bulkTextDetailsSent: bulkTextSentReducer,
    bulkTextDetailsFailed: bulkTextFailedReducer,

    forgotPasswordCreate: forgotPasswordCreateReducer,

    responderMessages: responderMessagesReducer,
    responderMessagesFetch: listResponderMessagesCreateReducer,

    userUpdate: userUpdateReducer,

    merchantDetails: merchantDetailsReducer,
    customerProfile: customerProfileReducer,
    subscriptionDetails: subscriptionDetailsReducer,
    paginationRedux: paginationReducer,
    contactsTableRow: contactsTableRowReducer,


});