import { BULK_SEND_CREATE_REQUEST, BULK_SEND_CREATE_SUCCESS, BULK_SEND_CREATE_FAIL, BULK_SEND_CREATE_RESET } from '../actions/types';

export default function bulkSendCreateReducer(state = {}, action) {
 
    switch(action.type) {
        case BULK_SEND_CREATE_REQUEST:
            return { loading: true }
        case BULK_SEND_CREATE_SUCCESS:
            return { loading: false, success: true, info: action.payload}
        case BULK_SEND_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case BULK_SEND_CREATE_RESET:
            return { }
        default:
            return state
    }
}