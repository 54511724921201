import { DELETE_BULK_SEND_REQUEST, DELETE_BULK_SEND_SUCCESS, DELETE_BULK_SEND_FAIL, DELETE_BULK_SEND_RESET } from '../actions/types';

export default function bulkSendDeleteReducer(state = {}, action) {
    switch(action.type) {
        case DELETE_BULK_SEND_REQUEST:
            return { loading: true }
        case DELETE_BULK_SEND_SUCCESS:
            console.log("this ran for bulksendsuccess")
            return { loading: false, success: true}
        case DELETE_BULK_SEND_FAIL:
            return { loading: false, error: action.payload }
            case DELETE_BULK_SEND_RESET:
                return { }
        default:
            return state
    }
}