import { CREATE_TOKEN_REQUEST, CREATE_TOKEN_SUCCESS, CREATE_TOKEN_FAIL, CREATE_TOKEN_RESET } from '../actions/types';

export default function tokenCreateReducer(state = {}, action) {
    switch(action.type) {
        case CREATE_TOKEN_REQUEST:
            return { loading: true }
        case CREATE_TOKEN_SUCCESS:
            return { loading: false, success: true, data: action.payload}
        case CREATE_TOKEN_FAIL:
            return { loading: false, error: action.payload }
        case CREATE_TOKEN_RESET:
            return { loading: false, state: {} }
        default:
            return state
    }
}