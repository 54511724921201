import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DropdownToggle, Dropdown } from 'reactstrap';
import Avatar from '../common/Avatar';

import AppContext from '../../context/Context';


const ProfileDropdown = () => {
  const auth = useSelector((state) => {return state.auth})
   const { toggleModal } = useContext(AppContext);
  
  
  return (
    
    <Dropdown
      nav
      inNavbar
      toggle={toggleModal}
      
    >
      <DropdownToggle nav className="pr-0">
        { auth.image ? (
          <Avatar src={auth.image} />
        ) : (
          <Avatar icon={"user"} />
        )}
      </DropdownToggle>
    </Dropdown>
  );
};


export default  ProfileDropdown
