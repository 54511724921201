import React, { useEffect, Fragment, useState, useMemo, useCallback } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import Loader from '../common/Loader';
import Moment from 'react-moment';
import AdvanceTable from '../common/AdvanceTable';
import AdvanceTableFooter from '../common/AdvanceTableFooter';
import AdvanceTableSearchBox from '../common/AdvanceTableSearchBox';
import AdvanceTableWrapper from '../common/AdvanceTableWrapper';
import NewGroupEdit from '../contacts/NewGroupEdit';
import {listGroupsExceptUnsub, createGroup, updateGroup, deleteMultipleGroups} from '../../actions/index';
import { ADD_COMPOSE_GROUP, GROUP_CREATE_RESET, ADD_TO_FIELD_HTML } from '../../actions/types';

const AddGroupToTable = ({ bulkMessage, setBulkMessage, contactState, setContactState, groupsModal, setGroupsModal, recipients, setRecipients, toField, setToField, fromCompose}) => {


//--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch(); 

  const groups = useSelector((state) => state.groups)
  const selectedGroupRows = useSelector(((state) => state.selectedGroupRows))
  
  const contactDelete = useSelector((state) => state.deleteContact)
  const { success:successDelete } = contactDelete

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const {success: successUpdate} = groupUpdate

  const groupCreate = useSelector((state) => state.groupCreate)
  const { success:successCreate } = groupCreate

  const groupDelete = useSelector((state) => state.groupDelete)
  const { success:successGroupDelete } = groupDelete
  
  
  const [showEditGroupModal, setShowEditGroupModal] = useState(false)
  const [showRenameModal, setShowRenameModal ] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [tempGroup, setTempGroup] = useState()
  const [isSelected, setIsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState('0')
  const [tempGroupId, setTempGroupId] = useState()
  const [groupTitle, setGroupTitle] = useState()
  const [rowDelete, setRowDelete] = useState()

  const selectionCallback = useCallback((selectedFlatRows) => { return  setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id})), setSelectedRows(selectedFlatRows), setRowDelete([]) }, 
  [setIsSelected]);

  const [createGroupModal, setCreateGroupModal] = useState(false)
  const [newGroup, setNewGroup] = useState({ title: ''})
  const [group, setGroup] = useState({title: ''})




  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const titleFormatter = (rowData, {avatar}) => {
    const {title, contacts, _id} = rowData.row.original
    const groupCompose = {title: title, contacts: contacts, _id: _id}
    return (
        <Media className="cursor-pointer" tag={Flex} align="center" onClick={() => { return setIsSelected(_id), dispatch({type: ADD_COMPOSE_GROUP, payload: { groupCompose: groupCompose }}), setGroupsModal(!groupsModal)}}>
          <Avatar name={title} {...avatar} />
          <Media body className="ml-2">
            <h5 className="mb-0 fs--1">{title}</h5>
          </Media>
        </Media>
    );
  };
  
  const contactsFormatter = (rowData) => {
    const { contacts } = rowData.row.original
  return (
        <Media body className="ml-2 mt-1">
          { contacts.length < 1 ? 
            <Badge color="soft-danger" pill className="fs--1">
            {contacts.length}
            <FontAwesomeIcon icon="user-plus" transform="shrink-1" className=" ml-1" />
          </Badge> : 
          <Badge color="soft-success" pill className="fs--1">
          {contacts.length}
          <FontAwesomeIcon icon="check" transform="shrink-1" className=" ml-1" />
        </Badge>
        }
        </Media>
  );
};

  const createdFormatter = (rowData) => {
    const {created} = rowData.row.original
    
  return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={created}/></h5>
        </Media>
      </Media>
  )};
  
  const actionFormatter = (rowData) => {
    const { contacts, title, _id } = rowData.row.original
    const groupCompose = {title: title, contacts: contacts, _id: _id}
    return (
      <div>
    <UncontrolledDropdown>
      <DropdownToggle onClick={() => {console.log("this ran")}}color="link" size="sm" className="text-600 btn-reveal bg-soft-primary">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
        <DropdownItem onClick={() => { return setIsSelected(_id), dispatch({type: ADD_COMPOSE_GROUP, payload: { groupCompose: groupCompose }}), setGroupsModal(!groupsModal)}} className="text-info"><FontAwesomeIcon icon="sms" className="fs--1" />{` Text this group`}</DropdownItem>
        <DropdownItem onClick={() => { return setTempGroupId(_id), setGroupTitle(title), setShowEditGroupModal(!showEditGroupModal)}} ><FontAwesomeIcon icon="eye" className="fs--1" />{` Group details`}</DropdownItem>
        <DropdownItem onClick={() => { return setShowRenameModal(!showRenameModal), setTempGroup(rowData.row.original) }}><FontAwesomeIcon icon="edit" className="fs--1" />{` Rename`}</DropdownItem>
        <DropdownItem onClick={() => { return setTempGroup(rowData.row.original), dispatch(updateGroup({hide: true, _id: _id}))}}><FontAwesomeIcon icon="minus" className="fs--1" />{` Hide group`}</DropdownItem>
        <DropdownItem onClick={() => { return setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)}} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete group`}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    </div>
    )
};

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    
    {
        accessor: 'title',
        Header: 'Title',
        Cell: titleFormatter
    },
    {
        accessor: 'contacts',
        Header: '# of contacts',
        Cell: contactsFormatter
    },
    {
        accessor: 'created',
        Header: 'Created',
        Cell: createdFormatter
    },
    {
      accessor: 'actions',
      headerProps: {
          className: 'd-flex justify-content-end',
          
      },
      Cell: actionFormatter,
      sticky: 'right',
      
  }
  ];
  
 
  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------

  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => { setShowEditGroupModal(!showEditGroupModal)}}>
      &times;
    </button>
  );

  const closeCreateGroupBtn = (
    <button className="close font-weight-normal" onClick={() => { setCreateGroupModal(!createGroupModal)}}>
      &times;
    </button>
  );

  const closeRenameBtn = (
    <button className="close font-weight-normal" onClick={() => { setShowRenameModal(!showRenameModal)}}>
      &times;
    </button>
  );

  const addGroupsToDiv = async() => {
    let recipient = 0
    let groupsToSend = []
    const matchedGroupsArray = selectedGroupRows.map((group) => { 
      groupsToSend.push(group.original) // push entire group object into array for bulk message
      let match 
      const findMatches = contactState?.forEach((el) =>  { return el?.id === group?.original?._id ? match = el?.id : ""})
      if(match) {
      } else { 
        recipient = group.original.contacts.length + recipients
        return (
        `<label contentEditable="false" class="p-1 font-weight-bold bg-success ml-2 text-dark rounded-capsule shadow-none fs--3">${group.original.title + " (Group)"}
      <span class="badge fs--1 badge-soft-success badge-pill ml-2" name="groups-length">${group.original.contacts.length} members </span>
      <span name="indy-groups" class="d-none">${group.original.contacts.length}</span>
      <span class="cursor-pointer text-dark bg-soft-success text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${group.original._id} >x</span>
      </label>`
        )
      }
    })
    setBulkMessage({...bulkMessage, groupNames: groupsToSend})
    setRecipients(recipient)
    dispatch({type: ADD_TO_FIELD_HTML, payload: {html: toField.html + matchedGroupsArray.toString()}})
    setToField({...toField, html: toField.html + matchedGroupsArray.toString()}) // update state with array. DO NOT update INside loop. always update outside of loop with returned array
    const removeBtns = document.getElementsByName('remove-contact-btn')
    await setContactState(removeBtns) // <--- contact State holds button values, NOt entire html

    
  }

 
  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------
  useEffect(() => {
    if(!successCreate) return;
   toast(
     <Fragment>
       <strong>Group Created</strong>
     </Fragment>
   );
   dispatch({type: GROUP_CREATE_RESET})
 }, [successCreate]);


  useEffect(() => {
    dispatch(listGroupsExceptUnsub())
  },[successDelete, successCreate, successGroupDelete, successUpdate])

  const handleGroupSubmit = (e) => {
    e.preventDefault()
      dispatch(createGroup(newGroup))
      setCreateGroupModal(!createGroupModal)
   };

    const handleGroupChange = e => {
      setGroup({...group, [e.target.name]: e.target.value})
    }

  return (
    
    <Card>
       <FalconCardHeader title="" light={false}>
          <Fragment>
          <ButtonGroup className="mt-2" >
          <ButtonIcon onClick={() => { return addGroupsToDiv(), setGroupsModal(!groupsModal)}} icon="plus" disabled={!isSelected.length} transform="shrink-3 down-2" color="falcon-success" size="sm">
              {`Add ${selectedGroupRows.length} Groups`}
            </ButtonIcon>
            <ButtonIcon onClick={() => { return setCreateGroupModal(!createGroupModal)}} icon="plus" transform="shrink-3" color="falcon-success" size="sm">
              New Group
            </ButtonIcon>
                  <Modal isOpen={createGroupModal} centered toggle={() => setCreateGroupModal(!createGroupModal)}>
                <ModalHeader toggle={() => { return setCreateGroupModal(!createGroupModal)}} className="bg-light d-flex flex-between-center border-bottom-0" close={closeCreateGroupBtn}>
                Let's give the group a name
                  </ModalHeader>
                    <ModalBody className="p-0">
                      <Card>
                        <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Form onSubmit={handleGroupSubmit}>
                            <Label for="title">Group Name:</Label>
                              <Input value={newGroup.title.value} onChange={(e) => {setNewGroup({...newGroup, [e.target.name]: e.target.value})}} className="mb-3" name="title" id="title"/>
                              <Button block onClick={(e) => { return e.preventDefault(), dispatch(createGroup(newGroup)), setCreateGroupModal(false)}} color="primary" disabled={!newGroup.title}className="mb-3">Save</Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </ModalBody>
                </Modal>
                <Modal isOpen={showRenameModal} centered toggle={() => setShowRenameModal(!showRenameModal)}>
            <ModalHeader close={closeRenameBtn}  className="text-center bg-light d-flex flex-between-center border-bottom-0">Rename group</ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                              <Label for="title">Group Name:</Label>
                                <Input defaultValue={tempGroup?.title} value={group.title.value} onChange={handleGroupChange} className="mb-3" name="title" id="title"/>
                                <Button block onClick={() => { return setShowRenameModal(false), dispatch(updateGroup({title: group.title, _id: tempGroup._id}))}} color="primary" className="mb-3" >Save</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) } } disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                      <Button onClick={() => { if(rowDelete?.length) { 
                          return dispatch(deleteMultipleGroups(rowDelete)), 
                          setShowDeleteModal(!showDeleteModal)
                          } else { 
                          const deleteIds = selectedGroupRows.map((el) => { return el.original._id})
                          return dispatch(deleteMultipleGroups(deleteIds)), 
                          setShowDeleteModal(!showDeleteModal)
                          }}} 
                          block 
                          className="text-danger" 
                          color="light">Delete groups</Button>
                        <Button onClick={() => { setShowDeleteModal(!showDeleteModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // group Edit Modal NewGroupEdit
              }
            <Modal size="lg" isOpen={showEditGroupModal} centered toggle={() => setShowEditGroupModal(!showEditGroupModal)}>
                <ModalHeader className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
                {groupTitle}
                  </ModalHeader>
                    <ModalBody className="p-0">
                                < NewGroupEdit tempGroupId={tempGroupId} groupTitle={groupTitle} showEditGroupModal={showEditGroupModal} setShowEditGroupModal={setShowEditGroupModal} fromCompose={fromCompose} />
                    </ModalBody>
                </Modal>
            </ButtonGroup>
          </Fragment>
        
      </FalconCardHeader>
      { useMemo(() => (
      <CardBody className="p-0">
      
      {groups?.loading || groupDelete?.loading ? (
          <div>
            <Loader />
            </div>
        ) : (
         
            <Fragment>
                <Card>
                <AdvanceTableWrapper
                  columns={columns}
                  data={groups}
                  sortable
                  pagination
                  perPage={50}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                  
                >
                  <Row className="flex-start-center mb-3 ml-1">
                    <Col >
                      <AdvanceTableSearchBox table/>
                    </Col>
                  </Row>
                 
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceTableFooter
                      rowCount={groups?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
                
                </Card>
                </Fragment>
        
              )
            }
      </CardBody>
      
      ), [groups])}
    </Card>
  
  );
};


export default AddGroupToTable;