import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';
import { LIST_PRIMARY_NUMBER, UPDATE_PRIMARY_NUMBER } from '../actions/types';


export default function(state = [], action) {
    switch(action.type) {

        case LIST_PRIMARY_NUMBER:
            return action.payload

        case UPDATE_PRIMARY_NUMBER:
            console.log("update_primary_number state", state)
            console.log("update_primary_number_payload", action.payload)
            return [action.payload]
            default:
            return state;
    }
}