import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Button, Card, CardBody, Row, Col, Label, Modal, ModalBody} from 'reactstrap';
import Divider from '../common/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardSummary from './CardSummary';
import { Link } from 'react-router-dom'
import CalendarTabs from '../calendar/CalendarTabs';
import { SocketContext } from '../../context/Socket';
import { updateUserFirstLogin, fetchUser, fetchRegUser } from '../../actions/index';
import { USER_UPDATE_RESET } from '../../actions/types';



const Dashboard = () => {
  
 
  const [showBlastModal, setShowBlastModal] = useState(false);
  const [showFirstLoginModal, setShowFirstLoginModal] = useState(false)
  const auth = useSelector((state) => state.auth)

  const successUpdate = useSelector((state) => state.userUpdate)
  const { success: successUserUpdate} = successUpdate

//  const socket = useContext(SocketContext);

  const dispatch = useDispatch()

  
// socket.on('updateUserCredits', (user) => {
//   console.log("update c")
// })


  useEffect(() => {
    if(auth?.isFirstLogin) {
      setShowFirstLoginModal(!showFirstLoginModal)
    }
  }, [auth.loading])

  useEffect(() => {
    
    if(successUserUpdate) {
      dispatch(fetchUser())
      dispatch(fetchRegUser())
      dispatch({type: USER_UPDATE_RESET})


    }
  }, [successUserUpdate])


  return (


    <Fragment>
      <div className="card-deck">
      <CardSummary title="" rate="Live chat" tag={Link} to="/chat" color="info">
          Chat
        </CardSummary>
        <CardSummary to="/pages/contacts" title="" rate="Groups Contacts Fields" color="success" className="mr-5" >
          Contacts
          </CardSummary>
        <Modal isOpen={showBlastModal} centered toggle={() => setShowBlastModal(!showBlastModal)}>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <composeMsg />
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <Modal isOpen={showFirstLoginModal} centered>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Label className="">Welcome, We gifted you 15 credits! You are way ahead of the curve, Text Marketing is one of the best ROI Marketing solutions today. Take a look around. </Label>
                <Label>When you are ready, Head over to "Account" you can add credits to send messages, make calls, and get personal phone numbers. </Label>
     
                <Row>
                
                </Row>
                <Button color="primary" size="sm" onClick={() => { return dispatch(updateUserFirstLogin(auth._id, { isFirstLogin: false})),  setShowFirstLoginModal(!showFirstLoginModal)}}>
                <FontAwesomeIcon icon="caret-right" className="mr-1" /> Let's Go
              </Button>
              <Divider> Or</Divider>
              <Button  tag={Link} to="/get-credits" color="primary" size="sm" onClick={() => { return dispatch(updateUserFirstLogin(auth._id, { isFirstLogin: false})),  setShowFirstLoginModal(!showFirstLoginModal)}}>
                <FontAwesomeIcon icon="caret-right" className="mr-1" /> Add Credits Now
              </Button>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <CardSummary title="" rate="Sent Recieved" tag={Link} to="/pages/history" color="success">
          History
        </CardSummary>
      </div>
      
        <CalendarTabs className="mb-3" />
      
    </Fragment>
  );
};


export default Dashboard;
