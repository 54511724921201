import { PAYMENT_METHOD_DELETE_REQUEST, 
    PAYMENT_METHOD_DELETE_SUCCESS,
    PAYMENT_METHOD_DELETE_FAIL } from '../actions/types';
    
    export default function paymentMethodDeleteReducer(state = {}, action) {
        switch(action.type) {
            case PAYMENT_METHOD_DELETE_REQUEST:
                return { loading: true }
            case PAYMENT_METHOD_DELETE_SUCCESS:
                return { loading: false, success: true}
            case PAYMENT_METHOD_DELETE_FAIL:
                return { loading: false, error: action.payload }
            default:
                return state
        }
    }