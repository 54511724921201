import { LIST_GROUPS, LIST_SHOW_GROUPS, LIST_GROUPS_EXCEPT_UNSUB} from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        // show all groups
        case LIST_GROUPS:
            const unSubGroup = action.payload.filter((group) => group.title === "unsubscribers" && !group.hide)[0]
            const allOtherGroups = action.payload.filter((group) => group.title !== "unsubscribers" && !group.hide)
            allOtherGroups.push(unSubGroup)
            return allOtherGroups
            // this is to show groups NOT hidden
        case LIST_SHOW_GROUPS:
         
            if(action.payload.length) {
                   const unSubGroupShow = action.payload.filter((group) => group.title === "unsubscribers" && !group.hide)[0] // grabbing unsub group OBJECT
                   unSubGroupShow.contacts.filter((contact) => contact.blocked === false) 
                   const allOtherGroupsShow = action.payload.filter((group) => group.title !== "unsubscribers" && !group.hide) // grabbing all other groups
                   const removeUnsubContacts = allOtherGroupsShow.map((g) => {
                       return {
                       title: g.title,
                       created: g.created,
                       hide: g.hide,
                       _id: g._id,
                       contacts:g.contacts.filter((c) => c.blocked === false && !c.UnsubscribeDate)}})
                   removeUnsubContacts.push(unSubGroupShow) // putting unsub at end for table to show unsub as last group
            return removeUnsubContacts
        } else {
            return state
        }
                
            
            // this is for compose to show groups except unsubscriber group
        case LIST_GROUPS_EXCEPT_UNSUB:
            const listGroupsExceptUnsub = action.payload.filter((group) => group.title !== "unsubscribers" && !group.hide)
            return listGroupsExceptUnsub.map((g) => {
                return {
                title: g.title,
                created: g.created,
                hide: g.hide,
                _id: g._id,
                contacts:g.contacts.filter((c) => c.blocked !== true && !c.UnsubscribeDate)}})
            default:
            return state;
    }
}