import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce
} from 'react-table';
import matchSorter from 'match-sorter';
import { Input } from 'reactstrap'
import { useSticky } from 'react-table-sticky'
import { getPageName, getGroupPageName} from '../../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SELECTED_ROWS, UPDATE_SELECTED_GROUP_ROWS, UPDATE_SELECTED_SENT_ROWS  } from '../../actions/types';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);


    return (
      
      <Form.Check
        type="checkbox"
        className="form-check fs-1 mb-0 d-flex align-items-center p-3 pb-1 "
        
       // its registering form-check, just not registering the form-check variable for opacity and other styles?
      >
        <Form.Check.Input type="checkbox" className="w-50 h-50" ref={resolvedRef} {...rest} />
      </Form.Check>
     
    );
  }
);


const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  selectionCallback,
  pagination,
  perPage = 10
}) => {

  
  
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    
    const count = preFilteredRows?.length
  
    return (
      <Input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      
    }),
    []
  )


  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    selectedFlatRows,
    flatRows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, filters },
    setGlobalFilter
  } = useTable (
    {
      columns,
      data,
      defaultColumn,
      disableSortBy: !sortable,
      initialState: { pageSize: pagination ? perPage : data.length },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
    hooks => {
      if (selection) {
        
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
               return (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> //getToggleAllPageRowsSelectedProps was getToggleAllRowsSelectedProps
            )},
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth,
                
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                {
                  row?.original?.title === "unsubscribers" ? "" : <div>

                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                }
              </div>
            )
          },
          
          ...columns
        ]);
      }
    }
  );

  const isSMSCompose = getPageName('sms-compose')
  const isContactsPage = getPageName('contacts')
  const isDashboard = getPageName('dashboard')
  const isGroup = getGroupPageName('group')
  const isUnsubscribers = getGroupPageName('unsubscribers')
  const isNumbers = getPageName('numbers')
  const isHistory = getPageName('history')
  const isCampaign = getPageName('campaign')
  const activeContactsTableTab = useSelector((state) => state.tabRedux)
  const dispatch = useDispatch()
  const reduxSelectedRows = useSelector((state) => state.selectedRows)




  useEffect(() => {
      if(isContactsPage || isNumbers || isHistory ) {
        dispatch({type: UPDATE_SELECTED_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
        dispatch({type: UPDATE_SELECTED_SENT_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
      }


      if(isSMSCompose || isDashboard || isGroup || isUnsubscribers || isCampaign) {
        dispatch({type: UPDATE_SELECTED_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
      }


          dispatch({type: UPDATE_SELECTED_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
          dispatch({type: UPDATE_SELECTED_GROUP_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
  }, [selectedRowIds])



    useEffect(() => {
      selectionCallback(selectedFlatRows)
    }, [reduxSelectedRows])


  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            data,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            selectedFlatRows,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  return (
    <>{recursiveMap(children)}</>
  );
};

export default AdvanceTableWrapper;
