const LIST_BLOCKED = 'list_blocked';


export default function (state = [], action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case LIST_BLOCKED:
            return action.payload  
            default:
            return state;
    }
}