import { FIELD_UPDATE_REQUEST, FIELD_UPDATE_SUCCESS, FIELD_UPDATE_FAIL, FIELD_UPDATE_RESET } from '../actions/types';

export default function fieldUpdateReducer(state = {}, action) {
    switch(action.type) {
        case FIELD_UPDATE_REQUEST:
            return { loading: true }
        case FIELD_UPDATE_SUCCESS:
            return { loading: false, success: true, fieldInfo: action.payload}
        case FIELD_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}