import {ADD_EVENTS_PLUGIN} from '../actions/types';

export default function eventsPluginReducer(state = [], action) {
  const { type, payload} = action;
  switch (type) {

    case ADD_EVENTS_PLUGIN:
      return [...state, payload];
      
      default:
        return state;
    }
  };
