import { CUSTOMER_PROFILE_REQUEST, CUSTOMER_PROFILE_SUCCESS, CUSTOMER_PROFILE_FAIL } from '../actions/types';


export default function creditCreateReducer(state = [], action) {
    switch(action.type) {
        case CUSTOMER_PROFILE_SUCCESS:
            return action.payload.response.profile.paymentProfiles
        default:
            return state
    }
}