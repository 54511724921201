import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect, useDispatch } from 'react-redux';
import { fetchUser } from '../actions/index';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import loadable from '@loadable/component';
import PreviewMedia from '../components/compose/PreviewMedia';
import PasswordReset from '../components/auth/split/PasswordReset';
import Logout from '../components/auth/basic/Logout';
import PrivateRoute from '../components/auth/PrivateRoute'; // dont forget to implement
import ConfirmLinkContent from '../components/auth/ConfirmLinkContent';
import ConfirmMailContent from '../components/auth/ConfirmMailContent';

const history = createBrowserHistory();
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));

const Layout = () => {
   
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    AuthBasicLayout.preload();
    AuthCardRoutes.preload();
  });

  return (
    <Router fallback={<span />} history={history}>
      <Switch>
        <Route path="/password-reset/:ident" exact component={PasswordReset} />
        <Route path={`/media/:imageID`} exact component={PreviewMedia} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/authentication/basic" component={AuthBasicLayout} />
        <Route path="/logout" component={Logout} />
        <Route path="/confirm-mail" exact component={ConfirmLinkContent} />
        <Route path="/authentication/basic/confirm-mail" component={ConfirmMailContent} />
        <PrivateRoute path="/" component={DashboardLayout} />
       
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default connect(null)(Layout); // the null argument is for mapStateToProps, the second argument is for all the different action creators we want to wire up
