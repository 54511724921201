import { FIELD_CREATE_REQUEST, FIELD_CREATE_SUCCESS, FIELD_CREATE_FAIL } from '../actions/types';

export default function fieldCreateReducer(state = {}, action) {
    switch(action.type) {
        case FIELD_CREATE_REQUEST:
            return { loading: true }
        case FIELD_CREATE_SUCCESS:
            return { loading: false, success: true}
        case FIELD_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}