import React, { useMemo, useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';
import Loader from '../common/Loader';
import Moment from 'react-moment';
import _ from 'lodash';
import AdvanceTable from '../common/AdvanceTable';
import AdvanceTableFooter from '../common/AdvanceTableFooter';
import AdvanceTableSearchBox from '../common/AdvanceTableSearchBox';
import AdvanceTableWrapper from '../common/AdvanceTableWrapper';
import {CompositeDecorator, convertFromRaw, ContentState, EditorState} from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import editorStyles from '../compose/SimpleMentionEditor.module.scss';
import mentionsStyles from '../compose/MentionsStyles.module.scss';
import {deleteTemplate, deleteMultipleTemplates, listTemplates } from '../../actions/index';
import { getPageName } from '../../helpers/utils';
import TemplateCreator from './TemplateCreator';
import { ADD_COMPOSE_TEMPLATE, RESET_COMPOSE_GROUP } from '../../actions/types';

const TemplateTableV3 = ({hasLabel, fromCompose, fromChat, fromFlow, fromCalendar, showTemplateModal, setShowTemplateModal }) => {

//--------------------------------------------------------------------------State------------------------------------------------------------------------------------

const dispatch = useDispatch();

const [showEditTemplateModal, setShowEditTemplateModal] = useState(false)
const [showDeleteModal, setShowDeleteModal] = useState(false)
const [temporaryId, setTemporaryId] = useState()
const [isSelected, setIsSelected] = useState(false);
const isTemplateTable = getPageName('template-table')


//-------------------------LOCAL STATE------------------------------------------------------------------------------------------------------------------------------
const templates = useSelector((state) => state.templates)
const templateDelete = useSelector((state) => state.deleteTemplate)
const { success:successDelete } = templateDelete
const templateCreate = useSelector((state) => state.createTemplate)
const { success:successCreate } = templateCreate
const templateUpdate = useSelector((state) => state.updateTemplate)
const { success:successUpdate} = templateUpdate
//--------------------------REDUX STATE------------------------------------------------------------------------------------------------------------------------------

const selectionCallback = useCallback((selectedFlatRows) => { return  setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id}))}, 
[setIsSelected]);

const toggleTemplate = () => { return setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId("")}

const closeBtn = (
  <button className="close font-weight-normal" onClick={toggleTemplate}>
    &times;
  </button>
);

//----------------------------------------------------------------------

const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
  const mentionPlugin = createMentionPlugin({
    entityMutability: 'IMMUTABLE',
    theme: mentionsStyles,
    //mentionPrefix: '@',
    supportWhitespace: true,
  });
  const emojiPlugin = createEmojiPlugin({
    useNativeArt: true
  });

  const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
  // eslint-disable-next-line no-shadow
  const { MentionSuggestions } = mentionPlugin;
  // eslint-disable-next-line no-shadow
  const plugins = [mentionPlugin, emojiPlugin];
  
  return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
}, [templates]);


//-------------------------------Formatters for columns-------------------------------------------------------------------------------------------------
// <------------------ Link onClick needs to set editorState for compose message message div
  const nameFormatter = (rowData, {avatar}) => {
    const {title, body, _id} = rowData.row.original
    const composeTemplate = { body: body}
    return (
        <Media className="cursor-pointer" onClick={() => { return fromCompose ? ( dispatch({type: ADD_COMPOSE_TEMPLATE, payload: { composeTemplate: composeTemplate }}), setShowTemplateModal(!showTemplateModal)) : fromChat ? ( dispatch({type: ADD_COMPOSE_TEMPLATE, payload: { composeTemplate: composeTemplate }}), setShowTemplateModal(!showTemplateModal)) : fromFlow ? ( dispatch({type: ADD_COMPOSE_TEMPLATE, payload: { composeTemplate: composeTemplate }}), setShowTemplateModal(!showTemplateModal)) : " "}} tag={Flex} align="center">
          <Media body className="ml-2">
            <h5 className="mb-0 mt-3 fs--1">{title}</h5>
            { fromCompose || fromChat || fromFlow ? (
              <Badge color="soft-success" pill className="fs--1 mt-2">
                 <FontAwesomeIcon icon="plus" transform="shrink-1 down-1" className=" mr-1" />
              Click to insert
            </Badge>
            ) : (" ")}
          </Media>
        </Media>
    );
  };

  
  const bodyFormatter = (rowData) => {
      const { _id, body } = rowData.row.original
      let editorState
      let contentState
      if(!body.entityMap) {
        contentState = { entityMap: {},
                          blocks: body.blocks}
        editorState = EditorState.createWithContent(convertFromRaw(contentState))
      } else { 
        const decorators = _.flattenDeep(plugins.map((plugin) => plugin.decorators));
        const decorator = new CompositeDecorator(decorators.filter((decorator, index) => index !== 1) );
        editorState = EditorState.createWithContent(
        convertFromRaw(body), decorator)
  }
      
    return (
          <Media body className="ml-2 mt-2" tag={Flex} align="center">
            <div 
                style={{minHeight: "5em", minWidth: "15em", maxWidth: "15em", maxHeight: "10em", overflow: "auto"}}
                className={`border border-2x border-300 bg-light rounded-soft fs-1 ${editorStyles.editor}` }
              >
              <Editor
                editorKey={'editor'}
                currentContent={ContentState}
                editorState={editorState}
                readOnly={true}
                onChange={() => {}}
              />
            </div>
        </Media>
    )};
  

    const createdFormatter = (rowData) => {
      const {created} = rowData.row.original
      
    return (
        <Media tag={Flex} align="center">
          <Media body className="ml-2">
          <Badge color="soft-success" pill className="fs--1">
          <Moment format="MM/DD/YYYY" date={created}/>
            </Badge>
          </Media>
        </Media>
    )};
  
  const actionFormatter = (rowData) => {
    const { body } = rowData.row.original
    const composeTemplate = { body: body}
    return (
      <UncontrolledDropdown>
        <DropdownToggle onClick={() => {console.log("this ran")}} color="link" size="sm" className="text-600 btn-reveal bg-soft-primary">
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>
        <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
          { !fromCalendar && !isTemplateTable && !fromChat ? (
          <DropdownItem onClick={() => {return fromCompose || fromChat || fromFlow ? dispatch({type: ADD_COMPOSE_TEMPLATE, payload: { composeTemplate: composeTemplate }}, setShowTemplateModal(!showTemplateModal) )  : dispatch({type: ADD_COMPOSE_TEMPLATE, payload: { composeTemplate: composeTemplate }})}} tag={Link} to={!fromCompose || !fromChat || !fromFlow  ? "/dashboard/pages/sms-compose" : null} className="text-info"><FontAwesomeIcon icon="sms" className="fs--1" />{fromCompose || fromFlow ? " Insert template" : " Send SMS with this template"}</DropdownItem>
    ) : <div></div>}
          <DropdownItem onClick={() => {return setTemporaryId(rowData.row.original), setShowEditTemplateModal(!showEditTemplateModal)}}><FontAwesomeIcon icon="edit" className="fs--1" />{` Edit`}</DropdownItem>
          <DropdownItem onClick={() => {return setTemporaryId(rowData.row.original), setShowDeleteModal(!showDeleteModal)}} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      )
};

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    
    {
        accessor: 'title',
        Header: 'Title',
        Cell: nameFormatter,
    },
    {
        accessor: 'body',
        Header: 'Body',
        Cell: bodyFormatter,
    },
    {
        accessor: 'created',
        Header: 'Created',
        Cell: createdFormatter
    },
    {
      accessor: 'actions',
      headerProps: {
          className: 'd-flex justify-content-end',
          
      },
      Cell: actionFormatter,
      sticky: 'right',
      
  }
  ];
  
 


  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------

  useEffect(() => {
    dispatch(listTemplates())
    dispatch({type: RESET_COMPOSE_GROUP})

  }, [successCreate, successDelete, successUpdate])

  return (
    <Card className="mb-3">
      { fromCompose || fromChat || fromFlow ? ("") : (<FalconCardHeader title="Templates"></FalconCardHeader>)}
      <FalconCardHeader title="" light={false}>
          <Fragment>
            <Row>
              <ButtonGroup className="mt-2">
                {
                  fromCompose ? (
                    <div>
                    <ButtonIcon onClick={() => {setShowEditTemplateModal(!showEditTemplateModal)}} icon="plus" transform="shrink-3 down-2" color="falcon-success" size="sm">
                      New template
                    </ButtonIcon>
                    <Modal isOpen={showEditTemplateModal} centered toggle={() => { return setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId("")}}>
                    <ModalHeader close={closeBtn}>Design your template</ModalHeader>
                      <ModalBody className="p-0">
                        <TemplateCreator fromCompose={fromCompose} temporaryId={temporaryId} setTemporaryId={setTemporaryId} hasLabel={hasLabel} showEditTemplateModal={showEditTemplateModal} setShowEditTemplateModal={setShowEditTemplateModal} />
                      </ModalBody>
                  </Modal>
                  <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
                  <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                      <ModalBody className="p-0">
                        <Card>
                          <CardBody className="fs--1 font-weight-normal p-4"> 
                            <Button onClick={() => { return temporaryId ? dispatch(deleteTemplate(temporaryId._id)) : dispatch(deleteMultipleTemplates(isSelected)), setTemporaryId(""), setShowDeleteModal(!showDeleteModal)}} block className="text-danger" color="light">Delete templates</Button>
                            <Button onClick={() => { setShowDeleteModal(!showDeleteModal)}} block className="text-primary" color="light" >Cancel</Button>
                          </CardBody>
                        </Card>
                      </ModalBody>
                  </Modal>
                  </div>
                  ) : (
                    <div>
            <ButtonIcon onClick={(() => setShowEditTemplateModal(!showEditTemplateModal))}icon="plus" transform="shrink-3 down-2" color="falcon-success" size="sm">
              New Template
            </ButtonIcon>
            <Modal isOpen={showEditTemplateModal} centered toggle={() => { return setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId("")}}>
              <ModalHeader close={closeBtn}>Design your template</ModalHeader>
                <ModalBody className="p-0">
                  <TemplateCreator temporaryId={temporaryId} setTemporaryId={setTemporaryId} hasLabel={hasLabel} showTemplateModal={showTemplateModal} setShowTemplateModal={setShowTemplateModal} showEditTemplateModal={showEditTemplateModal} setShowEditTemplateModal={setShowEditTemplateModal} />
                </ModalBody>
            </Modal>
            <ButtonIcon onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <Button onClick={() => { return temporaryId ? dispatch(deleteTemplate(temporaryId._id)) : dispatch(deleteMultipleTemplates(isSelected)), setTemporaryId(""), setShowDeleteModal(!showDeleteModal)}} block className="text-danger" color="light">Delete templates</Button>
                        <Button onClick={() => { setShowDeleteModal(!showDeleteModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              </div>
              )
                }

            </ButtonGroup>
            </Row>
          </Fragment>
      </FalconCardHeader>
      <CardBody className="p-0">

      {templates.loading ? (
          <div>
            <Loader />
            </div>
        ) : (
            
            <Fragment>
                <Card>
              
                <AdvanceTableWrapper
                  columns={columns}
                  data={templates}
                  sortable
                  pagination
                  perPage={12}
                  selection={fromCompose ? false : true} // shows checkbox
                  selectionColumnWidth={25}
                  isSelected={isSelected}
                  selectionCallback={selectionCallback}
                >
                  { fromCompose || fromChat || fromFlow ? ( 
                      <Row className="flex-start-center mb-3 mx-1">
                      <Col>
                        <AdvanceTableSearchBox table/>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="flex-start-center mb-3 ml-1">
                    <Col xs="auto" sm={6} lg={4}>
                      <AdvanceTableSearchBox table/>
                    </Col>
                  </Row>
                   )}
                
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceTableFooter
                      rowCount={templates?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
                
                </Card>
                </Fragment>
              )
            }
      </CardBody>
    </Card>
  );
};


export default TemplateTableV3;