import React, { useState, useEffect, useContext, forwardRef, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Card,
  CardBody,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '../common/Divider';
import Flex from '../common/Flex';
import { useForm } from 'react-hook-form';
import MessageCreateForm from '../compose/MessageCreateForm';
import Select from 'react-select';
import Moment from 'react-moment';
import moment from 'moment';
import momentt from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { calendarCreate, calendarUpdate } from '../../actions/index';
import makeAnimated from 'react-select/animated';
import AppContext from '../../context/Context';
import { RESET_EVENT, CALENDAR_CREATE_RESET } from '../../actions/types';
import SuccessAddCalendar from './SuccessAddCalendar'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import WizardInput from '../auth/wizard/WizardInput';
import uuid from 'uuid';


const ScheduleBulkText = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  addScheduleStartDate,
  fromCalendar,
  modalEventContent,
  isOpenModal,
  setIsOpenModal,
  eventId, 
  setShowDetailModal
}) => {

  const { register } = useForm();

  const {
    isDark,
  } = useContext(AppContext);
  

  const animatedComponents = makeAnimated();

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  const campaignEvents = useSelector((state) => state.campaignEvents)

  const successCalendarCreate = useSelector((state) => state.calendarCreate)
  const {success: successCreateCalendar} = successCalendarCreate

  const { _id } = isOpenModal && modalEventContent.event.extendedProps;

  
  


  //console.log("campaignEvents--------------------", campaignEvents)
  const [formObj, setFormObj] = useState({ // resetting this formObj back to original state, was showin undefined If i just empty the state
    className: 'bg-soft-success',
    startDate: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(),
      repeatCampaign: "noRepeat",
        repeatCampaignHourCycles: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
          repeatCampaignDayTimes: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
          repeatCampaignType: "onDay",
          repeatCampaignEnd: "Never",
          repeatCampaignWeekdays: {
            "sunday": false,
            "monday": false, 
            "tuesday": false, 
            "wednesday": false, 
            "thursday": false, 
            "friday": false, 
            "saturday": false 
         },
        repeatCampaignWeekdayTimes: { 
           sunday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           monday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           tuesday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           wednesday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           thursday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           friday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           saturday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}]
           },
        repeatCampaignWeekCount: 1,
        repeatCampaignMonthOnDay: "1",
        repeatCampaignMonthTimes: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
        repeatCampaignMonthOnThe: {first_part: "First", day: "Sunday"},
        timezone: auth.timezone
    })
  const weekDaysArr = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const [weeksToTrigger, setWeeksToTrigger] = useState(1)
  const [daysToTrigger, setDaysToTrigger] = useState(1)
  const [monthDayState, setMonthDayState] = useState(1)
  const [textSession, setTextSession] = useState(0)
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [timezone, setTimezone] = useState()
  const [initialTimezone, setInitialTimezone] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [timezones, setTimezones] = useState()
  const windowSize = useWindowDimensions()



  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => { 
  
      return setStartDate(new Date()),
      setFormObj({ ...formObj, start: "", startDate: "" }),
      setIsOpenScheduleModal(!isOpenScheduleModal), dispatch({type: RESET_EVENT, payload: {}})}}>
      &times;
    </button>
  );

  const closeSuccessBtn = (
    <button className="close font-weight-normal" 
    onClick={() => { 
      return setStartDate(new Date()),
      setFormObj({ ...formObj, start: "", startDate: "" }),
       dispatch({type: RESET_EVENT, payload: {}}),
       setShowSuccessModal(!showSuccessModal),
       setIsOpenScheduleModal(false)
       }}>
      &times;
    </button>
  );




  useEffect(() => {
    if(successCreateCalendar) {
      dispatch({type: CALENDAR_CREATE_RESET})
      //setIsOpenScheduleModal(false)
      setShowSuccessModal(!showSuccessModal)}
  }, [successCalendarCreate])




  useEffect(() => {
    if(campaignEvents.length) {
      setTimezone(campaignEvents[0]?.event?.timezone)
    } else {
      setTimezone(auth.timezone)
      setInitialTimezone(auth.timezone)
      setFormObj({...formObj, timezone: auth.timezone})

    }
  }, [auth?.timezone, formObj?.timezone, campaignEvents])


  useEffect(() => {

    const timeZones = momentt.tz.names();
    const offsetTmz=[];
  
  for(var i in timeZones)
  {
      offsetTmz.push({UTC: " (GMT"+momentt.tz(timeZones[i]).format('Z')+") " + timeZones[i], zoneTitle: timeZones[i] });
  }

  setTimezones(offsetTmz)

  }, [])
 

  //-------------------------------------------------------------------------Change handlers------------------------------------------------------------------------

  const handleChange = target => {
    setFormObj({ ...formObj, [target.name]: target.value });
  };


  const handleMonthDayChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if(value < 31 && value) {
    setMonthDayState(parseInt(value));
  }
  };

  const handleSessionChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if(value < 99 && value) {
    setTextSession(parseInt(value));
  }
  };


//---------Month view On x day of month
  const incrementMonthDay = () => {
    const value = monthDayState + 1
    if(value < 32 && value > 0) {
      setMonthDayState(value);
    }
  }

  const decrementMonthDay = () => {
    const value = monthDayState - 1
    if(value < 32 && value > 0 ) {
      setMonthDayState(value);
    }
  }


//----------Days to trigger
const incrementDays = () => {
  const value = daysToTrigger + 1
  if(value < 32 && value > 0) {
    setDaysToTrigger(value);
  }
}

const decrementDays = () => {
  const value = daysToTrigger - 1
  if(value < 52 && value > 0 ) {
    setDaysToTrigger(value);
  }
}




//----------Weeks to trigger
  const incrementWeeks = () => {
    const value = weeksToTrigger + 1
    if(value < 32 && value > 0) {
      setWeeksToTrigger(value);
    }
  }

  const decrementWeeks = () => {
    const value = weeksToTrigger - 1
    if(value < 52 && value > 0 ) {
      setWeeksToTrigger(value);
    }
  }


//----------SMS sessions
  const incrementSession = () => {
    const value = textSession + 1
    if(value < 99 && value > 0) {
      setTextSession(value);
    }
  }

  const decrementSession = () => {
    const value = textSession - 1
    if(value < 99 && value > 0 ) {
      setTextSession(value);
    }
  }

  //-------------------------------------------------------------------------React-select-styles------------------------------------------------------------------------

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? "bold" : "normal",
      // match with the menu
      borderColor: isDark ? 'dark' : 'light',
      // Removes weird border around container
    }),
    input: (base) => ({
      ...base,
        color: isDark ? 'dark' : 'light'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#2c7be5',
      fontWeight: "bold",
    }),
    option: (base, state) => ({
      ...base,
      color:  '#d8e2ef', // 
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? "bold" : "normal",
      backgroundColor: state.isFocused ? '#2c7be5' : '#0b1727'
    }),
    singleValue: (base) => ({ // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: "bold",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      background: isDark ? 'dark' : 'light',
      // kill the gap
      marginTop: 15
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      
      background:  isDark ? 'dark' : 'light',
    })

  };

  //-------------------------------------------------------------------------Use Effects------------------------------------------------------------------------


    useEffect(() => {
      const newD = new Date(startDate)
      setEndDate(newD.setHours(newD.getHours() + 1))
    }, [startDate])



  useEffect(() => {
    if(isOpenScheduleModal) {


      if(campaignEvents.length) {
  
        
       setFormObj({...formObj,
        title: campaignEvents[0]?.event?.title, 
        className: campaignEvents[0]?.event?.className,
        start: campaignEvents[0]?.event?.startDate,
        startDate: campaignEvents[0]?.event?.startDate,
        end: campaignEvents[0]?.event?.end,
        endDate: campaignEvents[0]?.event?.endDate,
        messageDetails: campaignEvents[0]?.event?.messageDetails,
        messageContents: campaignEvents[0]?.event?.messageContents,
        repeatCampaign: campaignEvents[0]?.event?.repeatCampaign,
        repeatCampaignHourCycles: campaignEvents[0]?.event?.repeatCampaignHourCycles,
        repeatCampaignDayTimes: campaignEvents[0]?.event?.repeatCampaignDayTimes,
        repeatCampaignDayCount: campaignEvents[0]?.event?.repeatCampaignDayCount,
        repeatCampaignWeekdayTimes: campaignEvents[0]?.event?.repeatCampaignWeekdayTimes,
        repeatCampaignWeekCount: campaignEvents[0]?.event?.repeatCampaignWeekCount,
        repeatCampaignMonthTimes: campaignEvents[0]?.event?.repeatCampaignMonthTimes,
        repeatCampaignMonthOnDay: campaignEvents[0]?.event?.repeatCampaignMonthOnDay,
        repeatCampaignMonthOnThe: campaignEvents[0]?.event?.repeatCampaignMonthOnThe,
        repeatCampaignEnd: campaignEvents[0]?.event?.repeatCampaignEnd,
        repeatCampaignEndSessions: campaignEvents[0]?.event?.repeatCampaignEndSessions,
        repeatCampaignFirstWeek: campaignEvents[0]?.event?.repeatCampaignFirstWeek,
        repeatCampaignFirstWeekday: campaignEvents[0]?.event?.repeatCampaignFirstWeekday,
        repeatCampaignType: campaignEvents[0]?.event?.repeatCampaignType,
        repeatCampaignWeekdays: campaignEvents[0]?.event?.repeatCampaignWeekdays,
        timezone: campaignEvents[0]?.event?.timezone,
        campaignId: _id ? _id : eventId
      })

      if(campaignEvents[0]?.event?.repeatCampaign === "Daily") {
        setDaysToTrigger(campaignEvents[0]?.event?.repeatCampaignDayCount)

      }



        setTextSession(campaignEvents[0]?.event?.repeatCampaignEndSessions)
    }



      if(addScheduleStartDate && !campaignEvents.length) {
       // console.log("addSchedulestartDate", addScheduleStartDate)
       // console.log("moment, addScheduleStart", moment(addScheduleStartDate))
       const newD = new Date(addScheduleStartDate)
       if(newD.getHours() === new Date().getHours) {
         setStartDate(newD.setHours(new Date().getHours() + 1))

       } else {
        setStartDate(addScheduleStartDate)
       }


      } else if(campaignEvents?.length) {
        const newSd = new Date(campaignEvents[0]?.event?.startDate)
        const getSdTimezone = momentt.tz(newSd.toISOString().replace("Z", ""), campaignEvents[0].event.timezone).format() //2022-11-04T16:00:00-06:00 take the start and apply the -06:00 to it and turn it into an iso string <--custom not local
        // remove chosen offset ex.. -05:00, 14 becomes 9
        const removeEventTimezoneOffset = newSd.setTime(newSd.getTime() + moment.parseZone(getSdTimezone).utcOffset() * 60_000); //1667556000000 get time since UTC convert the -06 into -360 and get Time
        const removedOffset = new Date(removeEventTimezoneOffset).toISOString()
        // add the local offset ex.. -07:00, 9 becomes 16
        const addLocalTimezoneOffset = newSd.setTime(newSd.getTime() - moment.parseZone().utcOffset() * 60_000); //1667556000000 get time since UTC convert the -06 into -360 and get Time
        const addedLocal = new Date(addLocalTimezoneOffset).toISOString()
        // setStartDate with new Date so ISO 16 becomes a local date object for ex -7 so 16 is 16-07:00 only doing this bcuz datepicker NEEDS date object with local timezone
        setStartDate(new Date(addedLocal))



      } else {
        return
      }
    }

    return () => {
      setFormObj({
        className: 'bg-soft-success',
    startDate: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(),
      repeatCampaign: "noRepeat",
        repeatCampaignHourCycles: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
          repeatCampaignDayTimes: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
          repeatCampaignType: "onDay",
          repeatCampaignEnd: "Never",
          repeatCampaignWeekdays: {
            "sunday": false,
            "monday": false, 
            "tuesday": false, 
            "wednesday": false, 
            "thursday": false, 
            "friday": false, 
            "saturday": false 
         },
        repeatCampaignWeekdayTimes: { 
           sunday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           monday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           tuesday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           wednesday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           thursday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           friday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
           saturday: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}]
           },
        repeatCampaignWeekCount: 1,
        repeatCampaignMonthOnDay: "1",
        repeatCampaignMonthTimes: [{trigger_time: momentt.tz(new Date().toISOString().replace("Z", ""), auth.timezone).format(), dpTime: new Date(), uid: uuid()}],
        repeatCampaignMonthOnThe: {first_part: "First", day: "Sunday"},
        timezone: auth.timezone
      })
    }


    // eslint-disable-next-line
  }, [isOpenScheduleModal]);



 
 useEffect(() => {
  const newArr = formObj?.messageDetails?.groupNames?.map((el) => el._id)
  const contactLength = formObj?.messageDetails?.groups?.filter((el) => { return !newArr.includes(el.group)})
  setFormObj({...formObj, contactLength: contactLength })
 }, [formObj?.messageDetails?.groups])
 

  useEffect(() => {
    setFormObj({...formObj, repeatCampaignEndSessions: textSession})
  }, [textSession])

  useEffect(() => {
    setFormObj({...formObj, repeatCampaignMonthOnDay: monthDayState})
  }, [monthDayState])

  useEffect(() => {
    setFormObj({...formObj, repeatCampaignDayCount: daysToTrigger})
  }, [daysToTrigger])

  useEffect(() => {
    setFormObj({...formObj, repeatCampaignWeekCount: weeksToTrigger})
  }, [weeksToTrigger])



  useEffect(() => {

    if(campaignEvents[0]?.event?.repeatCampaignEndSessions) {
      return setTextSession(campaignEvents[0]?.event?.repeatCampaignEndSessions)
    }


    if(campaignEvents[0]?.event?.repeatCampaignMonthOnDay) {
      setMonthDayState(campaignEvents[0]?.event?.repeatCampaignMonthOnDay)
    }

  }, [campaignEvents, isOpenScheduleModal])


 


  const convertTriggerTime = useCallback((triggerTime, fromTz, toTz) => {
    const ttDate = new Date(triggerTime);
    const getISO = ttDate.toISOString();
    const getAuthTimezone = moment.tz(getISO.replace("Z", ""), fromTz).format();
    const removeInitialTimezoneOffset = ttDate.setTime(ttDate.getTime() + moment.parseZone(getAuthTimezone).utcOffset() * 60_000);
    const timeWithoutInitialOffset = new Date(removeInitialTimezoneOffset).toISOString().replace("Z", "");
    const toTzTime = moment.tz(timeWithoutInitialOffset, toTz).format();
    return moment.utc(toTzTime).format();
  }, []);
  
  useEffect(() => {
    console.log("this function ran")
        if (timezone !== initialTimezone) {

      const getNewStartUTCTime = convertTriggerTime(formObj.startDate, initialTimezone, timezone);
      setFormObj({ ...formObj, startDate: getNewStartUTCTime });
  
      const updateTriggerTimes = (triggerTimes) => {
        return triggerTimes.map((el) => {
          const getNewUTCTime = convertTriggerTime(el.trigger_time, initialTimezone, timezone);
          return { ...el, trigger_time: getNewUTCTime };
        });
      };
  
      if (formObj?.repeatCampaign === "Hourly") {
        setFormObj({ ...formObj, repeatCampaignHourCycles: updateTriggerTimes(formObj?.repeatCampaignHourCycles) });
      }
  
      if (formObj?.repeatCampaign === "Daily") {
        setFormObj({ ...formObj, repeatCampaignDayTimes: updateTriggerTimes(formObj?.repeatCampaignDayTimes) });
      }
  
      if (formObj?.repeatCampaign === "Weekly") {
        let newWeekdayTimes = {};
        for (let [key, value] of Object.entries(formObj.repeatCampaignWeekdayTimes)) {
          newWeekdayTimes = { ...newWeekdayTimes, [key]: updateTriggerTimes(value) };
        }
        setFormObj({ ...formObj, repeatCampaignWeekdayTimes: newWeekdayTimes });
      }
  
      if (formObj?.repeatCampaign === "Monthly") {
        setFormObj({ ...formObj, repeatCampaignMonthTimes: updateTriggerTimes(formObj?.repeatCampaignMonthTimes) });
      }
  
      setInitialTimezone(timezone);
    }
  }, [timezone, formObj.repeatCampaign, initialTimezone, formObj, convertTriggerTime]);

 
    // Input for Select Start time=============
    const ExampleCustomInputStart = forwardRef(({ value, onClick }, ref) => {
     
      
      return (
     
        <Button 
        className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
        onClick={onClick} 
        ref={ref}
        >
       <Moment 
          format="MM-DD-YYYY hh:mm a" 
          trim  
          date={startDate} 
          ></Moment>
      </Button>
      )});

      const ExampleCustomInputEnd = forwardRef(({ value, onClick }, ref) => (
        <Button 
        className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
        onClick={onClick} 
        ref={ref}
        >
       <Moment 
          format="MM-DD-YYYY hh:mm a" 
          trim  
          date={endDate} 
          ></Moment>
      </Button>
      ));

      const filterTime = (date) => {
        const isPastTime = new Date().getTime() > date.getTime();
        return !isPastTime;
        };

        const filterEndTime = (date) => {
      //    const newD = new Date(startDate)
     // setEndDate(newD.setHours(newD.getHours() + 1))
          const isPastTime = new Date(startDate).getTime() > date.getTime();
          return !isPastTime;
          };

  //-------------------------------------------------------------------------HTML------------------------------------------------------------------------

  return (
    <Modal isOpen={isOpenScheduleModal} toggle={() => { return setIsOpenScheduleModal(!isOpenScheduleModal), dispatch({type: RESET_EVENT, payload: {}})}} className="modal-lg" modalClassName="theme-modal" contentClassName="border">
     
        <ModalHeader 
        toggle={() => { return setIsOpenScheduleModal(!isOpenScheduleModal), dispatch({type: RESET_EVENT, payload: {}})}} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
        {campaignEvents?.length ? "Edit Campaign" : "New Campaign"}
        </ModalHeader>
        <ModalBody>
        <Modal isOpen={showSuccessModal} centered toggle={() => { return setShowSuccessModal(!showSuccessModal)}}>
              <ModalHeader close={closeSuccessBtn}></ModalHeader>
                  <ModalBody className="p-0">
                    <SuccessAddCalendar setStartDate={setStartDate} formObj={formObj} setFormObj={setFormObj} setIsOpenScheduleModal={setIsOpenScheduleModal} showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} />
                  </ModalBody>
            </Modal> 
        <Card>
          <CardBody>
          <FormGroup>
          <Divider className="mt-4 fs-2"><strong> <Label className="fs-0" for="eventTitle">
                Campaign Title
                </Label> </strong></Divider> 
            <Input name="title" id="eventTitle" defaultValue={campaignEvents?.length ? campaignEvents[0].event.title : ""} required onChange={({ target }) => handleChange(target)} />
          </FormGroup>
          </CardBody>
          </Card>

          <Card className="mt-2">
            <CardBody>
            <FormGroup>
            <Col //name="portal_column_start" 
            className="col-xs-4">
               <Divider className="mt-4 fs-2"><strong> <Label className="fs-0" for="eventStart">
                First Send Date
                </Label> </strong></Divider> 
            
            <DatePicker
                //portalId="portal_column_start" // this fixed the bleedthroug
                showPopperArrow={false}
                selected={startDate}
                onChange={(date) => {
                  const newD = new Date(date)
                  const applyOffset = newD.setTime(newD.getTime() - newD.getTimezoneOffset() * 60_000);
                  const actualTime = new Date(applyOffset).toISOString().replace("Z", "")
                  const toTz = momentt.tz(actualTime, timezone).format()
                  const getUTCTime = momentt.utc(toTz).format()

                 
                 return setStartDate(date),
                  setFormObj({...formObj,
                    start: getUTCTime,
                    startDate: getUTCTime
                  })
                }}
               
                minDate={moment().toDate()}
                customInput={<ExampleCustomInputStart />}
                timeIntervals={5}
                showTimeSelect
                timeCaption="Time"
                dateFormat="h:mm aa"
                filterTime={filterTime}
              />
            
            </Col>
          </FormGroup>
         

          <FormGroup>
          <Divider className="mt-4 fs-2"><strong> <Label className="fs-0">
                Repeat Campaign
                </Label> </strong></Divider> 
            <Row>
              <UncontrolledDropdown >
                <DropdownToggle  size="md" innerRef={register} type="button" className="fs--1 border-0 bg-primary text-white mx-3" id="noRepeat" value={formObj?.repeatCampaign} name="repeatCampaign">
                  {formObj?.repeatCampaign === "noRepeat" ? " None (Trigger only once) " 
                  : formObj?.repeatCampaign === "Hourly" ? " Hourly " 
                  : formObj?.repeatCampaign === "Daily" ? " Daily " 
                  : formObj?.repeatCampaign === "Weekly" ? " Weekly "
                  : formObj?.repeatCampaign === "Monthly" ? " Monthly "
                  : ""}
                <FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" />
                </DropdownToggle>
                <DropdownMenu className="border py-2 ml-3">
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaign: "noRepeat"})}} id="noRepeat" value="noRepeat" name="repeatCampaign">{` None (Trigger only once) `}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaign: "Hourly"})}} id="Hourly" value="Hourly" name="repeatCampaign">{` Hourly `}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaign: "Daily"})}} id="Daily" value="Daily" name="repeatCampaign">{` Daily `}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaign: "Weekly"})}} id="Weekly" value="Weekly" name="repeatCampaign">{` Weekly `}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaign: "Monthly"})}} id="Monthly" value="Monthly" name="repeatCampaign">{` Monthly `}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              </Row>

              </FormGroup>

          

              {
                //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------

                (formObj?.repeatCampaign === "Hourly") && (
                  <div>
                        {
                          formObj?.repeatCampaignHourCycles?.map((el, i) => {


                                const CustomInputHourStart = forwardRef(({ value, onClick }, ref) => (
                                  <Button 
                                  className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
                                  onClick={onClick} 
                                  ref={ref}
                                  >
                                 <Moment 
                                    format="hh:mm a" 
                                    trim  
                                    date={new Date(el.dpTime)} 
                                    ></Moment>
                                </Button>
                                ));


                          return (
                            <div key={i}>
                              <Row className="mt-2" >
                                <Col className="col-3">
                                  <Label>Run</Label>
                                </Col>
                                <Col className="col-6">
                                    <DatePicker
                                       key={i}
                                       selected={new Date(el.dpTime)}
                                       showPopperArrow={false}
                                       
                                       onChange={(date) => { 
                                          const ttDate = new Date(date)
                                          const applyOffset = ttDate.setTime(ttDate.getTime() - ttDate.getTimezoneOffset() * 60_000);
                                          const actualTime = new Date(applyOffset).toISOString().replace("Z", "")
                                          const toTz = momentt.tz(actualTime, timezone).format()
                                          const getUTCTime = momentt.utc(toTz).format()

                                            
                                            return setFormObj(
                                              {...formObj,
                                                 repeatCampaignHourCycles: formObj?.repeatCampaignHourCycles.map((obj) => { if(obj.uid === el.uid) { return {trigger_time: getUTCTime, dpTime: date, uid: obj.uid, triggered: false} } else { return obj}})})

                                        }}
                                       customInput={<CustomInputHourStart />}
                                       showTimeSelect
                                       showTimeSelectOnly
                                       timeIntervals={5}
                                       timeCaption="Time"
                                     />
                                </Col>
                                  <Col className="col-3">
                                    <Button  onClick={() => { 
                                      setFormObj({...formObj, repeatCampaignHourCycles: formObj?.repeatCampaignHourCycles.filter((obj) => { return obj.uid !== el.uid})})
                                    }}
                                    className={ isDark ? "bg-soft-danger border-0 w-100" : "bg-soft-danger border-0 w-100 text-dark"}>
                                      <FontAwesomeIcon icon="times" transform="shrink-1 down-1" />
                                    </Button>
                                  </Col>
                              </Row>
                            </div>
                          )})
                        }

                      
                            <Row className="mt-2">
                              <Col className="col-4"> 
                                <Label>Add</Label>
                              </Col>
                              <Col className="col-4">
                                <Button onClick={() => { 
                                  if(formObj?.repeatCampaignHourCycles?.length < 24) {
                                    setFormObj({...formObj, 
                                      repeatCampaignHourCycles: [...formObj?.repeatCampaignHourCycles, 
                                        {trigger_time: new Date(), 
                                          dpTime: new Date().toISOString(), 
                                          uid: uuid(), 
                                          triggered: false}] 
                                        })}
                                    }} 
                                    className={ isDark ? "bg-soft-success border-0 w-100" : "bg-soft-success border-0 w-100 text-dark"}>
                                  <FontAwesomeIcon icon="plus" transform="shrink-1 down-1" />
                                </Button>
                              </Col>
                              <Col className="col-4">
                              </Col>
                            </Row>

                  </div>
                )
              }





                {
                //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------


                  (formObj?.repeatCampaign === "Daily") && (

                      <div>
                      
                               <Row className="mt-2">
                                      <Col className="col-3 col-md-2 col-lg-2"> 
                                        <Label>Trigger Every</Label>
                                      </Col>
                                      <Col className="col-6 col-md-6 col-lg-3">
                                        <Row>
                                          <Button onClick={() => {return incrementDays()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-up" transform="shrink-1 down-1" /></Button>
                                          <Button onClick={() => {return decrementDays()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" /></Button>
                                          <Input readOnly value={daysToTrigger} style={{maxWidth: 80}} />
                                        </Row>
                                      </Col>
                                      <Col className="justify-content-center col-3 col-md-4 col-lg-7">
                                        <Label>Days</Label>
                                      </Col>
                                    </Row>
                          
                          

                        {
                          formObj?.repeatCampaignDayTimes?.map((el, i) => {


                              const CustomInputDayTimes = forwardRef(({ value, onClick }, ref) => (
                                <Button 
                                className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
                                onClick={onClick} 
                                ref={ref}
                                >
                               <Moment 
                                  format="hh:mm a" 
                                  trim  
                                  date={new Date(el.dpTime)} 
                                  ></Moment>
                              </Button>
                              ));
                         

                          return  (
                            <div key={i}>
                              <Row className="mt-2" >
                                <Col className="col-3">
                                  <Label>Run</Label>
                                </Col>
                                <Col className="col-6">
                                    <DatePicker
                                      key={i}
                                      selected={new Date(el.dpTime)} // this datePicker has to have a different date so Instead of the actual trigger_time ISO we are feeding it a dateobject
                                       showPopperArrow={false}
                                       onChange={(date) => { 

                                          const ttDate = new Date(date)
                                          const applyOffset = ttDate.setTime(ttDate.getTime() - ttDate.getTimezoneOffset() * 60_000);
                                          const actualTime = new Date(applyOffset).toISOString().replace("Z", "")
                                          const toTz = momentt.tz(actualTime, timezone).format()
                                          const getUTCTime = momentt.utc(toTz).format()
                                      
                                        return setFormObj(
                                          {...formObj,
                                             repeatCampaignDayTimes: formObj?.repeatCampaignDayTimes.map((obj) => { if(obj.uid === el.uid) { return {trigger_time: getUTCTime, dpTime: date, uid: obj.uid, triggered: false} } else { return obj}})})
                                      
                                        }}
                                       customInput={<CustomInputDayTimes />}
                                       showTimeSelect
                                       showTimeSelectOnly
                                       timeIntervals={5}
                                       timeCaption="Time"
                                     />
                                </Col>
                                  <Col className="col-3">
                                    <Button onClick={() => { 
                                      setFormObj({...formObj, repeatCampaignDayTimes: formObj?.repeatCampaignDayTimes.filter((obj) => { return obj.uid !== el.uid})})
                                    }}
                                    className={ isDark ? "bg-soft-danger border-0 w-100" : "bg-soft-danger border-0 w-100 text-dark"}>
                                      <FontAwesomeIcon icon="times" transform="shrink-1 down-1" />
                                    </Button>
                                  </Col>
                              </Row>
                            </div>
                          )})
                        }

                      
                            <Row className="mt-2">
                              <Col className="col-4"> 
                                <Label>Add</Label>
                              </Col>
                              <Col className="col-4">
                                <Button onClick={() => { 
                                  if(formObj?.repeatCampaignDayTimes?.length < 24) {
                                    setFormObj({...formObj, repeatCampaignDayTimes: [...formObj?.repeatCampaignDayTimes, {trigger_time: startDate, dpTime: new Date(startDate).toISOString(), uid: uuid(), triggered: false}] })}
                                    }} 
                                  className={ isDark ? "bg-soft-success border-0 w-100" : "bg-soft-success border-0 w-100 text-dark"}>
                                  <FontAwesomeIcon icon="plus" transform="shrink-1 down-1" />
                                </Button>
                              </Col>
                              <Col className="col-4">
                              </Col>
                            </Row>
                      </div>
                  )
                }










{
//----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------


formObj?.repeatCampaign === "Weekly" && (
  <div>
  <Row className="mt-2">
         <Col className="col-3 col-md-2 col-lg-2"> 
           <Label>Trigger Every</Label>
         </Col>
         <Col className="col-6 col-md-6 col-lg-3">
           <Row>
             <Button onClick={() => {return incrementWeeks()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-up" transform="shrink-1 down-1" /></Button>
             <Button onClick={() => {return decrementWeeks()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" /></Button>
             <Input readOnly value={weeksToTrigger} style={{maxWidth: 80}} />
           </Row>
         </Col>
         <Col className="justify-content-center col-3 col-md-4 col-lg-7">
           <Label>{weeksToTrigger > 1 ? "Weeks" : "Week"}</Label>
         </Col>
       </Row>
       
</div>
)}



        {
          formObj?.repeatCampaign === "Weekly" && weekDaysArr.map((el) =>  (
           <div>
            <Divider className="mt-4 fs-2">
                  <WizardInput
               type="switch"
               id={el}
               tag={CustomInput}
               label= {<strong>{el}</strong>}
               onChange={({target}) => { if(formObj.repeatCampaignWeekdays[target.value]) { 
                   setFormObj({...formObj, repeatCampaignWeekdays: {...formObj.repeatCampaignWeekdays, [el]: false}})
                 } else { 
                   setFormObj({...formObj, repeatCampaignWeekdays: {...formObj.repeatCampaignWeekdays, [el]: true} })
                 }
               }}
               checked={formObj.repeatCampaignWeekdays[el] === true}
               className="custom-switch-adaptive"
               value={el}
               errors={""}
           /></Divider> 
            
             {

                          formObj?.repeatCampaignWeekdayTimes[el]?.map((wkday, index) => {

                          
                            const CustomInputWeekdayTimes = forwardRef(({ value, onClick }, ref) => (
        
                              <Button 
                              className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
                              onClick={onClick} 
                              ref={ref}
                              >
                             <Moment 
                                format="hh:mm a" 
                                trim  
                                date={wkday?.dpTime} 
                                ></Moment>
                            </Button>
                            ));

                           return (
                            <div className={formObj?.repeatCampaignWeekdays[el] === false ? "d-none" : ""} key={index}>
                              <Row className="mt-2" >
                                <Col className="col-3">
                                  <Label>Run</Label>
                                </Col>
                                <Col className="col-6">
                                 
                                    <DatePicker
                                      key={index}
                                       showPopperArrow={false}
                                       onChange={(date) => { 
                                        const ttDate = new Date(date)
                                        const applyOffset = ttDate.setTime(ttDate.getTime() - ttDate.getTimezoneOffset() * 60_000);
                                        const actualTime = new Date(applyOffset).toISOString().replace("Z", "")
                                        const toTz = momentt.tz(actualTime, timezone).format()
                                        const getUTCTime = momentt.utc(toTz).format()
                                       
                                        return setFormObj(
                                          {...formObj,
                                             repeatCampaignWeekdayTimes: {...formObj?.repeatCampaignWeekdayTimes, [el]: formObj?.repeatCampaignWeekdayTimes[el].map((obj) => {if(obj.uid === wkday.uid) { return {trigger_time: getUTCTime, dpTime: date, uid: obj.uid, triggered: false} } else { return obj } })}
                                            })
                                     
                                        }}
                                       selected={new Date(wkday?.dpTime)}
                                       customInput={<CustomInputWeekdayTimes />}
                                       showTimeSelect
                                       showTimeSelectOnly
                                       timeIntervals={5}
                                       timeCaption="Time"
                                     />
                                </Col>
                                  <Col className="col-3">
                                    <Button onClick={() => { 
                                      setFormObj(
                                        {...formObj,
                                           repeatCampaignWeekdayTimes: {...formObj?.repeatCampaignWeekdayTimes, [el]: formObj?.repeatCampaignWeekdayTimes[el].filter((times) => { return times.uid !== wkday.uid })}
                                          })
                                    }}
                                    className={ isDark ? "bg-soft-danger border-0 w-100" : "bg-soft-danger border-0 w-100 text-dark"}>
                                      <FontAwesomeIcon icon="times" transform="shrink-1 down-1" />
                                    </Button>
                                  </Col>
                              </Row>
                            </div>
                          )})
                        }

                          <div className={formObj?.repeatCampaignWeekdays[el] === false ? "d-none" : ""}>
                            <Row className="mt-2">
                              <Col className="col-4"> 
                                <Label>Add</Label>
                              </Col>
                              <Col className="col-4">
                                <Button onClick={() => {
                                  setFormObj({...formObj, repeatCampaignWeekdayTimes: {...formObj?.repeatCampaignWeekdayTimes, [el]: formObj?.repeatCampaignWeekdayTimes[el] ? [...formObj?.repeatCampaignWeekdayTimes[el], {trigger_time: startDate, dpTime: new Date().toISOString(), uid: uuid(), triggered: false}] : [{trigger_time: startDate, dpTime: new Date().toISOString(), uid: uuid(), triggered: false}] }})
                                }}
                                   className={ isDark ? "bg-soft-success border-0 w-100" : "bg-soft-success border-0 w-100 text-dark"}>
                                  <FontAwesomeIcon icon="plus" transform="shrink-1 down-1" />
                                </Button>
                              </Col>
                              <Col className="col-4">
                              </Col>
                            </Row>
                            </div>
            </div>
            
            )
            )
          }
        











        {//---------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
          ( formObj?.repeatCampaign === "Monthly") && (
              <div>
                <Row className="mt-3">
                  <Input 
                  className="mt-2 ml-1"
                  style={{width: 17, height: 17}}
                  type="radio"
                  checked={formObj?.repeatCampaignType === "onDay"}
                  onChange={() => { setFormObj({...formObj, repeatCampaignType: "onDay", repeatCampaignMonthOnDay: monthDayState})}}
                  />
                  <h5 className="ml-4 mr-2 mt-1">On day</h5>
                <Input onChange={handleMonthDayChange} value={monthDayState} style={{maxWidth: 80}} />
                  <Button onClick={() => {return incrementMonthDay()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-up" transform="shrink-1 down-1" /></Button>
                  <Button onClick={() => {return decrementMonthDay()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" /></Button>
                  </Row>


                
                  <Row className="mt-3 flex-column flex-sm-row">
                    
                  <Input 
                  className="mt-2 ml-1"
                  style={{width: 17, height: 17}}
                  type="radio"
                  checked={formObj?.repeatCampaignType === "onThe"}
                  onChange={() => { setFormObj({...formObj, repeatCampaignType: "onThe", repeatCampaignMonthOnDay: 0})}}
                  />
                  <h5 className="ml-4 mt-1">On the</h5>
                  <UncontrolledDropdown>
                    {
                      //on the first x
                    }
                <DropdownToggle size="md" innerRef={register} type="button" className="fs--1 border-0 bg-primary text-white mx-3 mt-1" id="noRepeat" value={formObj?.repeatCampaignMonthOnThe?.first_part} >
                  {formObj?.repeatCampaignMonthOnThe?.first_part + " "}
                <FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" />
                </DropdownToggle>
                {
                  // on the x sunday
                }
                <DropdownMenu className="border py-2 ml-3">
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, first_part: "First"}})}} name="repeatCampaign">{`First`}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, first_part: "Second"}})}} name="repeatCampaign">{`Second`}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, first_part: "Third"}})}} name="repeatCampaign">{`Third`}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, first_part: "Fourth"}})}} name="repeatCampaign">{`Fourth`}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, first_part: "Last"}})}} name="repeatCampaign">{`Last`}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle size="md" innerRef={register} type="button" className="fs--1 border-0 bg-primary text-white mt-1" id="noRepeat" value={formObj?.repeatCampaign} name="repeatCampaign" >
                  {formObj?.repeatCampaignMonthOnThe?.day + " "}
                <FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" />
                </DropdownToggle>
                <DropdownMenu className="border py-2 ml-3">
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Sunday"}})}} >{`Sunday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Monday"}})}} >{`Monday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Tuesday"}})}} >{`Tuesday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Wednesday"}})}} >{`Wednesday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Thursday"}})}} >{`Thursday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Friday"}})}} >{`Friday`}</DropdownItem>
                  <DropdownItem onClick={() => {return setFormObj({...formObj, repeatCampaignMonthOnThe: {...formObj.repeatCampaignMonthOnThe, day: "Saturday"}})}} >{`Saturday`}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                  </Row>
        

                  
                  { formObj?.repeatCampaignMonthTimes?.map((el) => {

                    const CustomInputMonthDayTimes = forwardRef(({ value, onClick }, ref) => (

                      <Button 
                        className={ isDark ? "bg-light border-0 w-100" : "rounded-capsule bg-light border-0 w-100 text-dark"}
                        onClick={onClick} 
                        ref={ref}
                        >
                       <Moment 
                          format="hh:mm a" 
                          trim  
                          date={new Date(el?.dpTime)} 
                          ></Moment>
                      </Button>
                      ));

                    return (
                    <div>
                      <Row className="mt-4" >
                        <Col className="col-3">
                          <Label>Run</Label>
                        </Col>
                        <Col className="col-6">
                            <DatePicker
                               showPopperArrow={false}
                               selected={new Date(el?.dpTime)}
                               onChange={(date) => {
                                
                                const ttDate = new Date(date)
                                const applyOffset = ttDate.setTime(ttDate.getTime() - ttDate.getTimezoneOffset() * 60_000);
                                const actualTime = new Date(applyOffset).toISOString().replace("Z", "")
                                const toTz = momentt.tz(actualTime, timezone).format()
                                const getUTCTime = momentt.utc(toTz).format()

  
                                return setFormObj(
                                {...formObj,
                                   repeatCampaignMonthTimes: formObj?.repeatCampaignMonthTimes.map((obj) => { if(obj.uid === el.uid) { return {trigger_time: getUTCTime, dpTime: date, uid: obj.uid, triggered: false} } else { return obj}})})
                                  }}
                               customInput={<CustomInputMonthDayTimes />}
                               showTimeSelect
                               showTimeSelectOnly
                               timeIntervals={5}
                               timeCaption="Time"
                             />
                        </Col>
                          <Col className="col-3">
                            <Button  onClick={() => { 
                            setFormObj({...formObj, repeatCampaignMonthTimes: formObj?.repeatCampaignMonthTimes.filter((obj) => { return obj.uid !== el.uid})})
                          }}
                            className={ isDark ? "bg-soft-danger border-0 w-100" : "bg-soft-danger border-0 w-100 text-dark"}>
                              <FontAwesomeIcon icon="times" transform="shrink-1 down-1" />
                            </Button>
                          </Col>
                      </Row>
                    </div>



                    )
                  })  
        }
             

                  <div >
                    <Row className="mt-2">
                      <Col className="col-4"> 
                        <Label>Add</Label>
                      </Col>
                      <Col className="col-4">
                        <Button onClick={() => {
                          if(formObj?.repeatCampaignMonthTimes?.length < 24) {
                            setFormObj({...formObj, repeatCampaignMonthTimes: [...formObj?.repeatCampaignMonthTimes, {trigger_time: startDate, dpTime: new Date(), uid: uuid(), triggered: false}] })}
                            
                        }}
                           className={ isDark ? "bg-soft-success border-0 w-100" : "bg-soft-success border-0 w-100 text-dark"}>
                          <FontAwesomeIcon icon="plus" transform="shrink-1 down-1" />
                        </Button>
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                    </div>
              </div>
          )
        }






























        { //--------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------END DATE VIEW------------------------------------------------------------------------------------------

        
        
        
        formObj?.repeatCampaign !== "noRepeat" && (
          <div>
       
          <Divider className="mt-4 fs-2"><strong> <Label className="fs-0">
                End Date
                </Label> </strong></Divider> 
            <Row >
              <UncontrolledDropdown>
                <DropdownToggle size="md" innerRef={register} type="button" className="fs--1 border-0 bg-primary text-white mx-3" id="never" value={formObj?.repeatCampaignEnd} name="repeatCampaignEnd" >
                {formObj?.repeatCampaignEnd + " "}
                <FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" />
                </DropdownToggle>
                <DropdownMenu className="border py-2 ml-3">
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignEnd: "Never"})}} id="never" value="never" name="repeatCampaignEnd">{`Never `}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignEnd: "After"})}} id="After" value="After" name="repeatCampaignEnd">{`After `}</DropdownItem>
                  <DropdownItem onClick={(e) => {return setFormObj({...formObj, repeatCampaignEnd: "OnDate"})}} id="OnDate" value="OnDate" name="repeatCampaignEnd">{`On Date `}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Row>


          {
            (formObj?.repeatCampaignEnd === "After") && (
              <div>
                <Row className="mt-4">
                <Input onChange={handleSessionChange} value={textSession} style={{maxWidth: 80}} />
                  <Button onClick={() => {return incrementSession()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-up" transform="shrink-1 down-1" /></Button>
                  <Button onClick={() => {return decrementSession()}} className="btn-sm bg-soft-primary text-primary border-0" type="button"><FontAwesomeIcon icon="chevron-down" transform="shrink-1 down-1" /></Button>
                  <h5 className="mx-2 mt-1">SMS sessions</h5>
                  </Row>
              </div>
            )
          }


          {
            (formObj?.repeatCampaignEnd === "OnDate") && (
              <div>
                <Row className="mt-4">
                <DatePicker
                minDate={startDate}
                showPopperArrow={false}
                selected={endDate}
                onChange={(date) => { return setEndDate(date), setFormObj({...formObj, endDate: date}) }}
                customInput={<ExampleCustomInputEnd />}
                timeIntervals={5}
                showTimeSelect
                timeCaption="Time"
                dateFormat="h:mm aa"
                filterTime={filterEndTime}
           
              />
              
                </Row>
              </div>
            )
          }
          
      
        </div>
        )}
         
          <Divider className="mt-4 fs-2"><strong> <Label className="fs-0" for="timezone">
                Timezone
                </Label> </strong></Divider> 
            <Select
              name="timezone"
              required={true}
              className="mb-3"
              styles={customStyles}
              components={animatedComponents}
              closeMenuOnSelect={true}
              options={timezones}
              defaultValue={{UTC: timezone}}
              getOptionLabel={({UTC}) => UTC}
              getOptionValue={({zoneTitle}) => zoneTitle}
              onChange={(timezone) => { return setTimezone(timezone.zoneTitle), setFormObj({...formObj, timezone: timezone.zoneTitle})}}
              placeholder="select Timezone"
              isSearchable={true}
              innerRef={register({
                required: "Add your timezone"
              })}
            />
        

            </CardBody>
          </Card>

        <Card className="mt-2">
          <CardBody>
      
          <Divider className="mt-4 fs-2"><strong> <Label className="fs-0" for="timezone">
                Tag color on calendar
                </Label> </strong></Divider> 
              <Row className="justify-content-between">
                <div className="btn-group btn-group-toggle mx-auto" data-toggle="buttons">
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-success text-success mx-1" id="soft-success" value="soft-success" name="soft-success" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-success"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-success" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" />{windowSize?.width > 477 ? ` Green` : ''}
                  </Button>
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-info text-info mx-1" id="soft-info" name="soft-info" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-info"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-info" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" />{windowSize?.width > 477 ? ` Light-blue` : ''}
                  </Button>
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-primary text-primary mx-1" id="soft-primary" name="soft-primary" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-primary"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-primary" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" />{windowSize?.width > 477 ? ` Dark-blue` : ''}
                  </Button>
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-danger text-danger mx-1" id="soft-danger" name="soft-danger" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-danger"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-danger" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" />{windowSize?.width > 477 ? ` Red` : ''}
                  </Button>
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-warning text-warning mx-1" id="soft-warning" name="soft-warning" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-warning"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-warning" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" /> {windowSize?.width > 477 ? ` Orange` : ''}
                  </Button>
                  <Button type="button" className="fs--1 border-0 btn-sm bg-soft-secondary text-secondary mx-1" id="soft-secondary" name="soft-secondary" onClick={(e) => {return setFormObj({...formObj, className:"bg-soft-secondary"})}}>
                      <FontAwesomeIcon icon={formObj?.className === "bg-soft-secondary" ? "check-circle" : "circle"} transform="grow-3" className=" ml-1 mr-1" /> {windowSize?.width > 477 ? ` Gray` : ''}
                  </Button>
                </div>
            </Row>
     
          </CardBody>
        </Card>


          <div className="mt-2">
              <MessageCreateForm formObj={formObj} setFormObj={setFormObj} fromCalendar={fromCalendar}/>
          </div>
      
        </ModalBody>


        <ModalFooter tag={Flex} justify="end" align="center" className={windowSize?.width > 491 ? "bg-light border-top-0" : "bg-light border-top-0 pb-7"}>

        
          
          {campaignEvents?.length ?
          <div>
              <Button 
              color="primary" 
              onClick={e => {
                e.preventDefault();
                return setIsOpenScheduleModal(false),
                setShowDetailModal(false),
                
                dispatch(calendarUpdate(formObj)),
                dispatch({type: RESET_EVENT, payload: {}}),
                setIsOpenModal(false)
              }} 
              className="px-4" 
              
              disabled={!formObj.title || !formObj.start || !formObj?.messageDetails?.groups.length || formObj?.messageDetails?.bulkNumber === "Select your number" || !formObj?.messageDetails?.bulkNumber || formObj?.messageContents?.plainText.length < 4 || !formObj.messageContents || !formObj?.timezone}>
              Save Changes
            </Button>
          </div>
          : 
              <div>
             
                <Button 
                  color="primary" 
                  onClick={e => {
                    e.preventDefault();
                    //setIsOpenScheduleModal(false);
                    dispatch(calendarCreate(formObj)) 
                    dispatch({type: RESET_EVENT, payload: {}})

                  }} 
                  className="px-4" 
                  disabled={!formObj?.title || !formObj?.start || !formObj?.messageDetails?.groups.length || formObj?.messageDetails?.bulkNumber === "Select your number" || !formObj?.messageDetails?.bulkNumber || formObj?.messageContents?.plainText.length < 4 || !formObj?.messageContents || !formObj?.timezone}>
                  {successCalendarCreate.loading ? "...saving" : "Save to calendar"}
                </Button>
              </div>
          }
        </ModalFooter>
    </Modal>
  );
};

export default ScheduleBulkText;
