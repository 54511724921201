import React, { useState, useRef, useMemo, useCallback, createRef, useEffect, Fragment } from 'react';
import {useHistory, Link} from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  Col,
  CustomInput,
  Row,
  Input,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '../common/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../common/FalconCardHeader';
import AddContactToTable from '../compose/AddContactToTable';
import ContentEditable from 'react-contenteditable';
import TemplateTableV3 from '../templates/TemplateTableV3';
import AddGroupToTable from './AddGroupToTable';
import _ from 'lodash';
import { CompositeDecorator, Modifier, convertFromRaw, convertToRaw, ContentState, EditorState } from 'draft-js';
import Calendar from '../calendar/Calendar';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/mention/lib/plugin.css'; //MentionCSS
import '@draft-js-plugins/emoji/lib/plugin.css'; // Emoji CSS
import '@draft-js-plugins/linkify/lib/plugin.css'; // Linkify CSS
import editorStyles from '../compose/SimpleMentionEditor.module.scss';
import mentionsStyles from '../compose/MentionsStyles.module.scss';
import draftToHtml from 'draftjs-to-html';
import { createField, listNumbers, getContactsByIds, listGroupsExceptUnsub, listFields, listPrimaryNumbers } from '../../actions/index';
import PreviewAndSend from './PreviewAndSend';
import BulkTextSending from './BulkTextSending';
import AttachFile from './AttachFile';
import { getPageName } from '../../helpers/utils';
import { RESET_COMPOSE_GROUP, ATTACH_FILE_CREATE_RESET, ADD_CONTENT, ADD_TO_FIELD_HTML} from '../../actions/types';
import useWindowDimensions from '../../hooks/useWindowDimensions';



const MessageCreateForm = ({formObj, setFormObj, fromCalendar, forwardState}) => {

  const dispatch = useDispatch()

  const history = useHistory()
//-----------------------------------------------REFS---------------------------------------------------------------------------------------------------------------
  const bulkMessageReducer = useSelector((state) => state.bulkMessageReducer)
  const toFieldRef = useRef()
  const messageFieldRef = createRef()
  // putting this here so we can setContactState with remove btns --> adds an event listener function to remove contact or group
//-----------------------------------------------LOCAL-STATE---------------------------------------------------------------------------------------------------------------
  const [bulkMessage, setBulkMessage] = useState({groups: "", contacts: "", groupNames: [], messageContents: "", bulkNumber: "" });

  const [contactModal, setContactModal] = useState(false);
  const [groupsModal, setGroupsModal] = useState(false);
  const [highlighted, setHighlighted] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [fromCompose, setFromCompose] = useState(true); // <--- state to pass to insert Template for template table to know we are coming from messageCreateForm.js
  const [showFileModal, setShowFileModal] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarView, setCalendarView] = useState('Month View')
  const [showSendModal, setShowSendModal] = useState(false);
  const [showBulkTextModal, setShowBulkTextModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [field, setField] = useState({title: ''});
  const [toField, setToField] = useState({value: " ", html: " "});
  const [messageLength, setMessageLength] = useState("");
  const [recipients, setRecipients] = useState(0);
  const [contactState, setContactState] = useState([]);
  const [editorState, setEditorState] = useState(() => {  return EditorState.createEmpty()}); // check bookmark for updating html npm install html-to-draftjs
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const windowWidth = useWindowDimensions();

  useEffect(() => {
    const removeBtns = document.getElementsByName('remove-contact-btn')
    setContactState(removeBtns) // <--- contact State holds button values, NOt entire html // nodelist
  }, []);

  const isSMSCompose = getPageName("sms-compose")

  

  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState)
  const FinalMessageText = markup.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ');
  const contentState = editorState.getCurrentContent();
  const len = contentState.getPlainText(' ')
  
  //-----------------------------------------------REDUX STATE---------------------------------------------------------------------------------------------------------------


  const bulkTextData = useSelector((state) => state.bulkText)
  const fields = useSelector((state) => state.fields);
  const numbers = useSelector((state) => state.numbers);
  const contacts = useSelector((state) => state.contacts);
  const compose = useSelector((state) => state.compose);
  const groups = useSelector((state) => state.groups);
  const campaignEvents = useSelector((state) => state.campaignEvents)
  const attachFileCreate = useSelector((state) => state.attachFileCreate);
  const {success: successAttachFile } = attachFileCreate
  const fieldCreate = useSelector((state) => state.fieldCreate);
  const { success: successFieldCreate } = fieldCreate

  const groupMoveCopy = useSelector((state) => state.groupMoveCopy)
  const { success: successMoveCopy } = groupMoveCopy

  

  const toggleTemplate = () => { return setShowTemplateModal(!showTemplateModal), setFromCompose(false)}


    const closeBtn = (
      <button className="close font-weight-normal" onClick={toggleTemplate}>
        &times;
      </button>
    );

    const closeFileBtn = (
      <button className="close font-weight-normal" onClick={() => {setShowFileModal(!showFileModal)}}>
        &times;
      </button>
    );

    const closeCntBtn = (
      <button className="close font-weight-normal" onClick={() => {setContactModal(!contactModal)}}>
        &times;
      </button>
    );

    const closeGrpBtn = (
      <button className="close font-weight-normal" onClick={() => {setGroupsModal(!groupsModal)}}>
        &times;
      </button>
    );

    const closeFieldBtn = (
      <button className="close font-weight-normal" onClick={() => {setShowFieldModal(!showFieldModal)}}>
        &times;
      </button>
    );

    const closeSendBtn = (
      <button className="close font-weight-normal" onClick={() => {setShowSendModal(!showSendModal)}}>
        &times;
      </button>
    );

    const closeBulkTextBtn = (
      <button className="close font-weight-normal" onClick={() => {setShowBulkTextModal(!showBulkTextModal)}}>
        &times;
      </button>
    );

    const closePreviewBtn = (
      <button className="close font-weight-normal" onClick={() => {setShowPreviewModal(!showPreviewModal)}}>
        &times;
      </button>
    );
//-----------------------------------------------------------------TO: HANDLERS------------------------------------------------------------------------------------------    
  


  const handleSubmit = e => {
    e.preventDefault();
    const rawsContentState = convertToRaw(editorState.getCurrentContent());
    const obj = rawsContentState?.entityMap
    const mentionIds = []
    Object.keys(obj).forEach(key => {      // the name of the current key.
      if (obj[key].data.mention) { mentionIds.push({ key: key, mentionId: obj[key].data.mention.id}) }
    });
    const newArr = bulkMessage?.groupNames?.map((el) => el._id)
    const contactLength = bulkMessage?.groups?.filter((el) => { return !newArr.includes(el.group)})
    setBulkMessage({...bulkMessage, contactLength: contactLength, messageContents: { plainText: FinalMessageText, mentions: mentionIds, mentionLocations: rawsContentState?.blocks?.map((el) => el.entityRanges) }})
    //dispatch({type: ADD_BULK_MESSAGE_CONTENT, payload: {contactLength: contactLength, messageContents: { plainText: FinalMessageText, mentions: mentionIds, mentionLocations: rawsContentState?.blocks?.map((el) => el.entityRanges) }}})
    if(e.target.id === "previewBtn") {
      setShowPreviewModal(!showPreviewModal)
    } else {
    setShowSendModal(!showSendModal)
  }
  };


  const formatForScheduling = e => {
    e.preventDefault();
    const rawsContentState = convertToRaw(editorState.getCurrentContent());
    const obj = rawsContentState?.entityMap
    const mentionIds = []
    Object.keys(obj).forEach(key => {      // the name of the current key.
      if (obj[key].data.mention) { mentionIds.push({ key: key, mentionId: obj[key].data.mention.id}) }
    });
    const newArr = bulkMessage?.groupNames?.map((el) => el._id)
    const contactLength = bulkMessage?.groups?.filter((el) => { return !newArr.includes(el.group)})
    setBulkMessage({...bulkMessage, contactLength: contactLength, messageContents: { plainText: FinalMessageText, mentions: mentionIds, mentionLocations: rawsContentState?.blocks?.map((el) => el.entityRanges) }})
    //dispatch({type: ADD_BULK_MESSAGE_CONTENT, payload: {contactLength: contactLength, messageContents: { plainText: FinalMessageText, mentions: mentionIds, mentionLocations: rawsContentState?.blocks?.map((el) => el.entityRanges) }}})
   
  };
//-----------------------------------------------------------------TO: HANDLERS------------------------------------------------------------------------------------------    


  // set recipient number count 
  const handleToChange = async(evt) => {
   const indyContacts = document.getElementsByName('indy-contacts')
   const indyGroups = document.getElementsByName('indy-groups')
   let groupContactNumber = 0
    //                            setting 0            el.innerHTML contains string ex.. "99" for contacts.length
   await indyGroups.forEach((el) => { return groupContactNumber = groupContactNumber + parseInt(el.innerHTML)})
   setRecipients(indyContacts?.length + groupContactNumber)
   await setToField({html: evt.target.value}) // only needed to update typed info to state

};
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------


const handleToClick = async(evt) => {
 const indyContacts = document.getElementsByName('indy-contacts')
 const indyGroups = document.getElementsByName('indy-groups')
 let groupContactNumber = 0
  //                            setting 0            el.innerHTML contains string ex.. "99" for contacts.length
await indyGroups.forEach((el) => { return groupContactNumber = groupContactNumber + parseInt(el.innerHTML)})
await setRecipients(indyContacts?.length + groupContactNumber)
};
 //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------


 const handleToFocus = async(evt) => {
  if(contactState?.length) {
  contactState.forEach((el) => { return el.addEventListener('click', (e) => { return removeContact(e) })})
 } 
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------


const removeContact = function(e) { 
 // console.log("-----------Remove Contact ran---------")
  const remainderHtml = []
  const groupNames = []
  const removedContact = [...toFieldRef?.current?.children].forEach((el) => { // <--wanted to just map but couldnt, so did forEach made into array
    return remainderHtml.push(el)
  })
  let allHTML = "" //< --- create empty string
  remainderHtml.map((el) => { // now I map
    if(el.children[2]?.id !== e.target.id && el.children[1]?.id !== "addContactTo") { // if el.children id is not the clicked id then create with el
      groupNames.push(el.children[2]?.id)
      allHTML += el.outerHTML // create the outer HTML with ones that DONT match what we clicked on thus creating a new html without this item
   } else if(el.children[2]?.id !== e.target.id) {
      allHTML += el.outerHTML
   }
 })
  setBulkMessage({...bulkMessage, groupNames: bulkMessage.groupNames.filter((el) => {return groupNames.includes(el._id)})})
  dispatch({type: ADD_TO_FIELD_HTML, payload: {html: allHTML}})
  setToField({...toField.html, html: allHTML})
      const indyContacts = document.getElementsByName('indy-contacts')
      const indyGroups = document.getElementsByName('indy-groups')
      let groupContactNumber = 0
      indyGroups.forEach((el) => { return groupContactNumber = groupContactNumber + parseInt(el.innerHTML)})
      setRecipients(indyContacts?.length + groupContactNumber)

      contactState.forEach((el) => { return el.addEventListener('click', (e) => { return removeContact(e) })})

}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// adding contact to TO Field when coming from contacts page, triggered in useEffect on page load to see if composeContact reducer has any value
const fromContactToDiv = async(composeContact) => {
      let matchedContactsArray 
      if(composeContact[0]?.contactCompose?.length) {
        matchedContactsArray = composeContact[0]?.contactCompose?.map((contact) => {
          let match 
          const findMatches = contactState?.forEach((el) =>  { return el?.id === contact?.contactCompose?._id ? match = el?.id : ""})
          if(match) {
          } else {

          return (
          `<label contentEditable="false" class="p-1 font-weight-bold bg-primary ml-2 text-white rounded-capsule shadow-none fs--3">${contact.firstName + " " + contact.lastName + " "}
        <span class="badge fs--1 badge-soft-primary badge-pill ml-2">${contact.phone_number}</span>
        <span name="indy-contacts" id="contactToAdd" class="d-none">${contact._id}</span>
        <span class="cursor-pointer text-dark bg-soft-primary text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${contact._id} >x</span>
        </label>`)
      }}) } else { 
          matchedContactsArray = composeContact?.map((contact) => { 
        let match 
        const findMatches = contactState?.forEach((el) =>  { return el?.id === contact?.contactCompose?._id ? match = el?.id : ""})
        if(match) {
        } else { return (
          `<label contentEditable="false" class="p-1 font-weight-bold bg-primary ml-2 text-white rounded-capsule shadow-none fs--3">${contact.contactCompose.firstName + " " + contact.contactCompose.lastName + " "}
        <span class="badge fs--1 badge-soft-primary badge-pill ml-2">${contact.contactCompose.phoneNumber}</span>
        <span name="indy-contacts" id="contactToAdd" class="d-none">${contact.contactCompose._id}</span>
        <span class="cursor-pointer text-dark bg-soft-primary text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${contact.contactCompose._id} >x</span>
        </label>`)
        }
      })
    }
   dispatch({type: ADD_TO_FIELD_HTML, payload: { html: matchedContactsArray.toString()}})
     setToField({...toField, html: toField.html + matchedContactsArray.toString()})
      const removeBtns = document.getElementsByName('remove-contact-btn')
      await setContactState(removeBtns) // <--- contact State holds button values, NOt entire html // nodelist

    }

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// adding group to TO Field when coming from group page, triggered in useEffect on page load to see if groupCompose reducer has any value

const fromGroupToDiv = async(groupCompose) => {
      let recipient = 0
      let groupsToSend = []
      let matchedGroupsArray
      if(groupCompose[0]?.groupCompose?.length) {
        const noUnsub = groupCompose[0]?.groupCompose?.filter((group) => group.title !== "unsubscribers") // filter out unsubscribers group (case we do select all from groups table)
         matchedGroupsArray = noUnsub?.map((group) => { // map over our groups object without unsubs
          groupsToSend.push(group) // push entire group object into array for bulk message
          let match 
          const findMatches = contactState?.forEach((el) =>  { return el?.id === group?._id ? match = el?.id : ""})
          if(match) {
          } else { 
            recipient = group.contacts.length + recipients // this is where it was resetting recipeints to zero
            return (
            `<label contentEditable="false" class="p-1 font-weight-bold bg-success ml-2 text-dark rounded-capsule shadow-none fs--3">${group.title + " (Group)"}
          <span class="badge fs--1 badge-soft-success badge-pill ml-2" name="groups-length">${group.contacts.length} members </span>
          <span name="indy-groups" class="d-none">${group.contacts.length}</span>
          <span class="cursor-pointer text-dark bg-soft-success text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${group._id} >x</span>
          </label>`
            )
          }
        })
      } else {
      matchedGroupsArray = groupCompose.map((group) => { 
        groupsToSend.push(group?.groupCompose) // push entire group object into array for bulk message
        let match 
        const findMatches = contactState?.forEach((el) =>  { return el?.id === group?.groupCompose?._id ? match = el?.id : ""})
        if(match) {
        } else { 
          recipient = group.groupCompose.contacts.length + recipients
          return (
          `<label contentEditable="false" class="p-1 font-weight-bold bg-success ml-2 text-dark rounded-capsule shadow-none fs--3">${group.groupCompose.title + " (Group)"}
        <span class="badge fs--1 badge-soft-success badge-pill ml-2" name="groups-length">${group.groupCompose.contacts.length} members </span>
        <span name="indy-groups" class="d-none">${group.groupCompose.contacts.length}</span>
        <span class="cursor-pointer text-dark bg-soft-success text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${group.groupCompose._id} >x</span>
        </label>`
          )
        }
      })}
      await setBulkMessage({...bulkMessage, groupNames: groupsToSend})
      dispatch({type: ADD_TO_FIELD_HTML, payload: { html: matchedGroupsArray.toString()}})
      await setToField({...toField, html: toField.html + matchedGroupsArray.toString()}) // update state with array. DO NOT update INside loop. always update outside of loop with returned array
      const removeBtns = document.getElementsByName('remove-contact-btn')
      await setContactState(removeBtns) // <--- contact State holds button values, NOt entire html

    }


    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 
 // triggered on useEffect when page loads, this component is also used in calendar, this function is for the calendar, to load groups and contacts into 
 // the TO Field when editing a campaign
 const ContactsAndGroupsToDiv = async(composeContact) => {
    
  let matchedContactsArray;
  let matchedGroupsArray;
  let groupsToSend = [];
  let recipient = 0;

  for (let item of composeContact) {
    if (item.contactCompose) {
      matchedContactsArray = item.contactCompose.map((contact) => {
        let match = contactState.some((el) => el.id === contact._id);
        if(!match) {
          return (
          `<label contentEditable="false" class="p-1 font-weight-bold bg-primary ml-2 text-white rounded-capsule shadow-none fs--3">${contact.firstName + " " + contact.lastName + " "}
        <span class="badge fs--1 badge-soft-primary badge-pill ml-2">${contact.phone_number}</span>
        <span name="indy-contacts" id="contactToAdd" class="d-none">${contact._id}</span>
        <span class="cursor-pointer text-dark bg-soft-primary text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${contact._id} >x</span>
        </label>`);
        }
      });
    } else if (item.groupCompose) {
      const noUnsub = item.groupCompose.filter((group) => group.title !== "unsubscribers");
      groupsToSend.push(...noUnsub);

      matchedGroupsArray = noUnsub.map((group) => {
        let match = contactState.some((el) => el.id === group._id);
        if(!match) {
          recipient += group.contacts.length;
          return (
            `<label contentEditable="false" class="p-1 font-weight-bold bg-success ml-2 text-dark rounded-capsule shadow-none fs--3">${group.title + " (Group)"}
            <span class="badge fs--1 badge-soft-success badge-pill ml-2" name="groups-length">${group.contacts.length} members </span>
            <span name="indy-groups" class="d-none">${group.contacts.length}</span>
            <span class="cursor-pointer text-dark bg-soft-success text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${group._id} >x</span>
            </label>`);
          }
        });
      }
    }
  
    if(groupsToSend.length) {
      await setBulkMessage({...bulkMessage, groupNames: groupsToSend})

    }
  
    let htmlString = "";
  
    if(matchedContactsArray) {
      htmlString += matchedContactsArray.join("");
    }
  
    if(matchedGroupsArray) {
      htmlString += matchedGroupsArray.join("");
    }
  
    await setToField({...toField, html: toField.html + htmlString});
    
    

  }


//-----------------------------------------------------------------MESSAGE FIELD HANDLERS------------------------------------------------------------------------------------------

    function Entry(props) {
      const {
        mention,
        theme,
        searchValue, 
        isFocused, 
        ...parentProps
      } = props;
    
      return (
        <div {...parentProps}>
          <div className={theme?.mentionSuggestionsEntryContainer}>
            <div className={theme?.mentionSuggestionsEntryContainerLeft}>
              <img
                src={mention.avatar}
                className={theme?.mentionSuggestionsEntryAvatar}
                role="presentation"
              />
            </div>
      
            <div className={theme?.mentionSuggestionsEntryContainerRight}>
             <div className={theme?.mentionSuggestionsEntryText}>
               {mention.name}
             </div>
      
             <div className={theme?.mentionSuggestionsEntryTitle}>
                {mention.title}
              </div>
            </div>
          </div>
        </div>
      );
    }


//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const onSearchChange = useCallback(({ value }) => {
  }, []);

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const insertText = (textToInsert) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    let newContent = Modifier.replaceText( currentContent, currentSelection, textToInsert);
    const textToInsertSelection = currentSelection.set('focusOffset', currentSelection.getFocusOffset() + textToInsert?.length);
    let inlineStyles = editorState.getCurrentInlineStyle();
    inlineStyles.forEach(inLineStyle => newContent = Modifier.applyInlineStyle(newContent, textToInsertSelection, inLineStyle));
    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(newState, textToInsertSelection.set('anchorOffset', textToInsertSelection.getAnchorOffset() + textToInsert.length));

    return newState;
  }

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

useEffect(() => {
  if(forwardState) {
    const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
    const decorator = new CompositeDecorator(decorators);
    setEditorState(EditorState.createWithContent(
      ContentState.createFromText(forwardState), decorator)) 
  }
}, [])

//-----------------------------------------------------------------USE EFFECT------------------------------------------------------------------------------------------

useEffect(() => {
  dispatch(listNumbers())
  dispatch(listPrimaryNumbers())
}, [])
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  dispatch(listGroupsExceptUnsub())
}, [successMoveCopy])
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  dispatch(listFields())
}, [successFieldCreate])

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  if(formObj && !bulkMessage?.bulkNumber && campaignEvents.length) {
  setFormObj({...formObj, messageDetails: {groupNames: bulkMessage?.groupNames, groups: bulkMessage?.groups, messageContents: bulkMessage?.messageContents, bulkNumber: campaignEvents[0]?.event?.messageDetails?.bulkNumber }})
} 
  if(formObj && bulkMessage?.bulkNumber) {
    setFormObj({...formObj, messageDetails: {groupNames: bulkMessage?.groupNames, groups: bulkMessage?.groups, messageContents: bulkMessage?.messageContents, bulkNumber: bulkMessage?.bulkNumber}})

  }
  dispatch({type: ADD_CONTENT, payload: bulkMessage})

}, [bulkMessage])


useEffect(() => {
  if(setFormObj) {
  setFormObj({...formObj, messageDetails: campaignEvents[0]?.event?.messageDetails})
}
  dispatch({type: ADD_CONTENT, payload: bulkMessage})

}, [campaignEvents])


//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  if(formObj) {
  const rawsContentState = convertToRaw(editorState.getCurrentContent());
  const obj = rawsContentState?.entityMap
  const mentionIds = []
  Object.keys(obj).forEach(key => {      // the name of the current key.
    if (obj[key].data.mention) { mentionIds.push({ key: key, mentionId: obj[key].data.mention.id}) }
  });
setFormObj({...formObj, messageContents: { plainText: FinalMessageText, mentions: mentionIds, body: rawsContentState}})
   }
}, [editorState])
//-------------------------------COMPOSE TEMPLATE TO UPDATE EDITOR STATE-------------------------------------------------------------------------------------------------------------------------------------------

// when redux state changes, trigger this useEffect
useEffect(() => {

  if(compose[0]?.composeTemplate) {

      if(!compose[0]?.composeTemplate.body.entityMap) {
        const contentState = { entityMap: {},
                          blocks: compose[0]?.composeTemplate.body.blocks}
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState)))
      } else { 
        const contentState = { entityMap: compose[0]?.composeTemplate?.body?.entityMap,
                          blocks: compose[0]?.composeTemplate.body.blocks }
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState))) }
  }
  dispatch({type: RESET_COMPOSE_GROUP})
}, [compose.length])

//-------------------------------When first in createBulk trigger this to check for contentState in bulkMessageReducer-------------------------------------------------------------------------------------------------------------------------------------------

// when redux state changes, trigger this useEffect
useEffect(() => {

  if(bulkMessageReducer.contentState && !compose[0]?.composeTemplate) {
      const { contentState } = bulkMessageReducer
      if(!contentState.entityMap) {
        contentState = { entityMap: {},
        blocks: contentState.blocks}
        const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
        const decorator = new CompositeDecorator(decorators);
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState)))
      } else { 
        const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
        const decorator = new CompositeDecorator(decorators);
        setEditorState(EditorState.createWithContent(
        convertFromRaw(contentState), decorator)) }
  }

}, [])


//----------------------------------------SET SUGGESTIONS----------------------------------------------------------------------------------------------------------------------------------

useEffect(() => {
  const OurFields = fields.map((el) => { return { name: `{${el.title}}` , id: el._id}})
  setSuggestions([{name: "{First name}", id: "700"}, {name: "{Last name}", id: "701"}, {name: "{Company name}", id: "702"}, {name: "{Phone}", id: "703"}, {name: "{Email}", id: "704"}, ...OurFields])
}, [fields])


//-----------------------------------------TRIGGER INSERT TEXT ---------------------------------------------------------------------------------------------------------------------------------

useEffect(() => { // Inserts link into Message draft-js as text
  if(successAttachFile) {
    setEditorState(insertText(attachFileCreate?.payload?.tempUrl))
    dispatch({type: ATTACH_FILE_CREATE_RESET})
}

}, [successAttachFile])
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

useEffect(() => {
  if(campaignEvents?.length) {
  
  let contentState
  if(!campaignEvents[0]?.event?.messageContents?.body?.entityMap) {
    contentState = { entityMap: {},
                      blocks: campaignEvents[0]?.event?.messageContents?.body?.blocks}
    const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
    const decorator = new CompositeDecorator(decorators);
    setEditorState(EditorState.createWithContent(convertFromRaw(contentState), decorator))
  } else { 
      const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
      const decorator = new CompositeDecorator(decorators);
    setEditorState(EditorState.createWithContent(
    convertFromRaw(campaignEvents[0]?.event?.messageContents?.body), decorator))  }
  }
  
}, [])
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

useEffect(() => {
  if(contactState?.length) {
    contactState.forEach((el) => { return el.addEventListener('click', (e) => { return removeContact(e) })})
      const indyContacts = document.getElementsByName('indy-contacts')
      const indyGroups = document.getElementsByName('indy-groups')
      let groupContactNumber = 0
      indyGroups.forEach((el) => { return groupContactNumber = groupContactNumber + parseInt(el.innerHTML)})
      setRecipients(indyContacts?.length + groupContactNumber)
  }
}, [contactState, bulkMessageReducer.html])

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => { // <-------- setting message length
  if(len) {
  setMessageLength(len.length)
  } else {
  setMessageLength(0)
}
}, [editorState])



//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  const newArr = []
  return [...toFieldRef?.current?.children]?.forEach((element) => { newArr.push({ group: element?.children[2]?.id})   }),
  setBulkMessage({...bulkMessage, groups: newArr})
  // not changing look, just adding contents to state
}, [toFieldRef?.current?.children?.length])

//-------------------------------------------ADD CONTACTS AND GROUPS to TO FIELD -------------------------------------------------------------------------------------------------------------------------------

useEffect(() => {
  if(compose[0]?.contactCompose) {
    fromContactToDiv(compose)
  } else if(compose[0]?.groupCompose) {
    fromGroupToDiv(compose)
  }
}, [groupsModal, compose])
//--------------------------------------------ADD GROUPS to TO FIELD FOR EDIT CAMPAIGN------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {

  const fetchContactsAndGroups = async () => {
    let compose = []
  
    if(campaignEvents[0]?.event?.messageDetails?.groups) { // groups is actually .contacts
      // groups is not actually groups, its groups and contacts ids
      const contactIds = campaignEvents[0]?.event?.messageDetails?.groups.map((contact) => contact.group);
      const fullContactObjNoUndefined = await dispatch(getContactsByIds(contactIds));
  
      // No need to filter as we're getting exact contacts we need
      const contactCompose = fullContactObjNoUndefined?.map((el) => { 
        if(el) { 
          return { 
            firstName: el.firstName,
            lastName: el.lastName,
            phone_number: el.phone_number,
            _id: el._id
          }
        }
      });
  
      compose.push({contactCompose: contactCompose}) // doing this to match what object looks like to avoid writing another fromGroupToDiv function
    }
      
    if(campaignEvents[0]?.event?.messageDetails?.groupNames?.length) { // groupNames is groups
      const groupCompose = campaignEvents[0]?.event?.messageDetails?.groupNames?.map((el) => { 
          return { 
            title: el.title,
            contacts: el.contacts,
            _id: el._id
          }
      });
  
      compose.push({groupCompose: groupCompose}) // doing this to match what object looks like to avoid writing another fromGroupToDiv function
    }
  
    if(campaignEvents[0]?.event) {
      ContactsAndGroupsToDiv(compose)
  
    }
  }

  fetchContactsAndGroups();
}, [])
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const handleFieldChange = e => {
  setField({...field, [e.target.name]: e.target.value})
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------


//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
useEffect(() => {
  if(formObj) { 
  setFormObj({...formObj, cost: `$${((Math.ceil(messageLength / 153) * 0.04) * recipients).toFixed(2)}` })
}
}, [messageLength, recipients])


defaultTheme.emojiSelectPopover = defaultTheme.emojiSelectPopover + "emojiSelectPopover"
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
  const mentionPlugin = createMentionPlugin({
    entityMutability: 'IMMUTABLE',
    theme: mentionsStyles,
    //mentionPrefix: '@',
    supportWhitespace: true,
  });
  const emojiPlugin = createEmojiPlugin({
    selectButtonContent: <FontAwesomeIcon
    icon={['far', 'laugh-beam']}
    transform="shrink-4"
  />,
    theme: defaultTheme,
    useNativeArt: true
  });
  const linkifyPlugin = createLinkifyPlugin()

  const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
  // eslint-disable-next-line no-shadow
  const { MentionSuggestions } = mentionPlugin;
  // eslint-disable-next-line no-shadow
  const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin];
  return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
}, []);


  

   

//-----------------------------------------------------------------RENDER HTML------------------------------------------------------------------------------------------
  return (
    <Card>
      {!fromCalendar && !forwardState ? (
        <div>
      <FalconCardHeader
        title="New Message"
        titleClass="align-items-center"
      />
      </div>
      ): ""}
      <CardBody className="p-3">
        <Form onSubmit={handleSubmit}>
          
          <h5>To:</h5>
         <ContentEditable
         name="to"
         innerRef={toFieldRef} // passing our ref instead of state
         html={isSMSCompose ? bulkMessageReducer.html : toField.html} // the html = our ref.current property
         onChange={handleToChange} // this sets our refs.current = event.target.value
         onFocus={handleToFocus}
         onClick={handleToClick}
         style={{minHeight: "7em", maxHeight: "10em", overflow: "auto"}}
         className="border border-2x border-300 bg-light rounded-soft fs-1"
         >
         </ContentEditable>
         { recipients < 1 ? 
              <Badge color="soft-danger" pill className="fs--1">
              {`Recipients: ${recipients}`}
            </Badge> : 
            <Badge color="soft-success" pill className="fs--1">
            {`Recipients: ${recipients}`}
            <FontAwesomeIcon icon="users" transform="shrink-1" className=" ml-1" />
          </Badge>
          }
         <div className="justify-content-between mt-1 px-1 pb-1">
            <Button color="light" onClick={() => { setContactModal(!contactModal) }} align="center" className="rounded-capsule shadow-none fs--1 ml- mb-0">
              <FontAwesomeIcon icon="user" > </FontAwesomeIcon>
                 {` Contacts`}</Button>
                  <Modal size="lg" isOpen={contactModal} centered toggle={() => setContactModal(!contactModal)}>
                    <ModalHeader close={closeCntBtn}>Select contacts</ModalHeader>
                      <ModalBody className="p-0">
                     
                            <AddContactToTable 
                            contactModal={contactModal} 
                            setContactModal={setContactModal} 
                            toField={toField} 
                            setToField={setToField} 
                            recipients={recipients} 
                            setRecipients={setRecipients}
                            contactState={contactState}
                            setContactState={setContactState} 
                            fromCompose={fromCompose} 
                            fromCalendar={fromCalendar}
                            />
                    
                      </ModalBody>
                    </Modal>
                  </div>
                  <div className="justify-content-between mt-1 px-1 pb-1">
              <Button color="light" onClick={() => {setGroupsModal(!groupsModal)}} className="rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon="users" />
                {` Groups`}
              </Button>
              <Modal isOpen={groupsModal} centered toggle={() => setGroupsModal(!groupsModal)}>
              <ModalHeader close={closeGrpBtn}>Select Groups</ModalHeader>
                    <ModalBody className="p-1">
                          < AddGroupToTable 
                          groupsModal={groupsModal} 
                          setGroupsModal={setGroupsModal} 
                          toField={toField} 
                          setToField={setToField} 
                          toFieldRef={toFieldRef} 
                          recipients={recipients} 
                          setRecipients={setRecipients} 
                          contactState={contactState}
                          setContactState={setContactState}
                          bulkMessage={bulkMessage}
                          setBulkMessage={setBulkMessage} 
                          fromCompose={fromCompose}
                          />
                    </ModalBody>
                  </Modal>
              </div>
              <div className="justify-content-between mt-1 px-1 pb-1">
              <Button color="light" tag={Link} to="/pages/contacts-import" className="rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon="user-plus" />
                {` Or Import a new file (.csv)`}
              </Button>
              </div>

              <div>
                  <Card>
                    <CardBody>
                      <h5 className="mt-2">From:</h5>
                      {
                        numbers.length ? (
                          campaignEvents.length ? (
                            <CustomInput
                              type="select"
                              id="from"
                              name="from"
                              className="mb-3"
                              onChange={({ target }) => { return setBulkMessage({...bulkMessage, bulkNumber: target.value}) }}
                            >                     
                              <option>Select your number</option>
                              <optgroup className="font-weight-normal" color="grey" label="Bulk Text Numbers">
                                {numbers.map((el) => { 
                                    const truePrimary = Object.keys(el.primary).filter(k => el.primary[k] === true)
                                    return (
                                      <Fragment key={el.phoneNumber}>
                                        <option 
                                          selected={JSON.parse(campaignEvents[0]?.event?.messageDetails?.bulkNumber)?.phoneNumber === el.phoneNumber ? true 
                                          : bulkTextData?.bulkNumber === el.phoneNumber ? true 
                                          : false} 
                                          value={JSON.stringify(el)}
                                        >{el.phoneNumber + `${el.nickName ? " (" + el.nickName + ") " 
                                        : ""}${truePrimary.length ? ` Current Primary for ${truePrimary}` 
                                        : " "}`}
                                        </option>
                                      </Fragment>
                                    )
                                  }
                                )} 
                              </optgroup>
                            </CustomInput>
                          ) : (
                            <CustomInput
                              type="select"
                              id="from"
                              name="from"
                              className="mb-3"
                              onChange={({ target }) => { return setBulkMessage({...bulkMessage, bulkNumber: target.value}) }}
                            >                     
                              <option>Select your number</option>
                              <optgroup className="font-weight-normal" color="grey" label="Bulk Text Numbers">
                                {numbers.map((el) => { 
                                    const truePrimary = Object.keys(el.primary).filter(k => el.primary[k] === true)
                                    return (
                                      <Fragment key={el.phoneNumber}>
                                        <option 
                                          selected={bulkTextData?.bulkNumber === el.phoneNumber ? true 
                                          : false} 
                                          value={JSON.stringify(el)}
                                        >{el.phoneNumber + `${el.nickName ? " (" + el.nickName + ") " 
                                        : ""}${truePrimary.length ? ` Current Primary for ${truePrimary}` 
                                        : " "}`}
                                        </option>
                                      </Fragment>
                                    )
                                  }
                                )} 
                              </optgroup>
                            </CustomInput>
                          )
                        ) : (
                          <CustomInput
                            type="select"
                            id="from"
                            name="from"
                            className="mb-3"
                            onChange={({ target }) => {
                              if (target.value === 'purchase') {
                                history.push('/services/numbers');
                              } else {
                                return setBulkMessage({...bulkMessage, bulkNumber: target.value})
                              }
                            }}
                          >                     
                            <option>Select your number</option>
                            <option value="purchase">Purchase a new number</option>
                          </CustomInput>
                        )
                      }
                    </CardBody>
                  </Card>
                </div>
              {
//--------------------------------------------------------------------------MESSAGE CONTENTEDITABLE-----------------------------------------------------------------
              }
              <Row className="justify-content-between">
                <h5 className="mt-3 ml-3">Message:</h5>
                <Button color="light" onClick={() => {setShowFieldModal(!showFieldModal)}} className="rounded-capsule shadow-none fs--1 mr-3 mb-2 mt-1" >
                  <FontAwesomeIcon icon='plus' transform="down-1 left-2"/>
                  {`  new custom field`}
                </Button>
                <Modal isOpen={showFieldModal} centered toggle={() => {setShowFieldModal(!showFieldModal)}}>
                <ModalHeader toggle={() => {setShowFieldModal(!showFieldModal)}} close={closeFieldBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Create a new Field
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                              <Label for="title">Field Name:</Label>
                                <Input value={field.title.value} onChange={handleFieldChange} className="mb-3" name="title" id="title"/>
                              <Button disabled={!field?.title} block onClick={() => { return dispatch(createField(field)), setShowFieldModal(false)}} color="primary" className="mb-3">Save</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                  </Modal>
              </Row>
            <div 
              style={{minHeight: "7em", maxHeight: "10em", overflow: "auto"}}
              className={`border border-2x border-300 bg-light rounded-soft fs-1 ${editorStyles.editor}` }
              onClick={() => { messageFieldRef.current.focus(); }}
            >
            <Editor
              editorKey={'editor'}
              currentContent={ContentState}
              editorState={editorState}
              onChange={setEditorState}
              plugins={plugins}
              ref={messageFieldRef}
            />
            <EmojiSuggestions />
            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={onSearchChange}
              onAddMention={(e) => {// get the mention object selected
              }}
              entryComponent={Entry}
              />
            </div>
            <div>
                <EmojiSelect closeOnEmojiSelect />
              <span color="light" className="px-3 py-1 bg-soft-info rounded-capsule shadow-none fs--1 ml-3" >
                <FontAwesomeIcon icon="tags" transform="left-3"/>
                 Type <strong>@</strong> for custom fields
              </span>
            </div>
        <Col className="mt-2">
            <Badge color="soft-success" pill className="fs--1">{`Characters: ${messageLength}/918`}</Badge>
        
            <Badge color="soft-info" pill className="fs--1 ml-2">{`Parts ${Math.ceil(messageLength / 153)}/6`}</Badge>

          <Label className="ml-4" >{`Credit cost: ${(Math.ceil(messageLength / 153) * recipients)}`}</Label>
        
        </Col>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button color="light" onClick={() => {return setFromCompose(true), setShowTemplateModal(!showTemplateModal)}} className="rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon={['fab', 'trello']} transform="left-3"/>
                {` Insert Template`}
              </Button>
              <Modal size="lg" isOpen={showTemplateModal} centered toggle={() => {return setFromCompose(false), setShowTemplateModal(!showTemplateModal)}}>
                <ModalHeader toggle={() => {return setFromCompose(false), setShowTemplateModal(!showTemplateModal)}} close={closeBtn}>Templates</ModalHeader>
                    <ModalBody className="p-0">
                          <TemplateTableV3 
                            fromCompose={fromCompose}
                            fromCalendar={fromCalendar}
                            showTemplateModal={showTemplateModal}
                            setShowTemplateModal={setShowTemplateModal}
                            />
                    </ModalBody>
                  </Modal>
            </div>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button color="light" onClick={() => {setShowFileModal(!showFileModal)}} className="rounded-capsule shadow-none fs--1 ml- mb-0" >
              <FontAwesomeIcon icon="paperclip" transform="left-3"/>
                {` Attach File`}
              </Button>
              <Modal isOpen={showFileModal} centered toggle={() => {setShowFileModal(!showFileModal)}}>
                <ModalHeader toggle={() => {setShowFileModal(!showFileModal)}} close={closeFileBtn}>Attach a file - 10MB limit</ModalHeader>
                    <ModalBody className="p-0">
                      <AttachFile 
                          highlighted={highlighted} 
                          setHighlighted={setHighlighted} 
                          showFileModal={showFileModal} 
                          setShowFileModal={setShowFileModal}
                           />
                    </ModalBody>
                  </Modal>
            </div>
          <Modal isOpen={showPreviewModal} centered toggle={() => { return setShowPreviewModal(!showPreviewModal)}}>
              <ModalHeader close={closePreviewBtn}>Preview</ModalHeader>
                  <ModalBody className="p-0">
                    <PreviewAndSend recipients={recipients} contacts={contacts} groups={groups} fields={fields} bulkMessage={bulkMessage} />
                  </ModalBody>
            </Modal> 
           
            {!fromCalendar ? ( 
              <div>
              <Divider className="mt-4"></Divider> 
              {showCalendar && (
                <Calendar bulkMessage={bulkMessage} setBulkMessage={setBulkMessage} recipients={recipients} calendarView={calendarView} setCalendarView={setCalendarView} showCalendar={showCalendar} setShowCalendar={setShowCalendar} showTimeModal={showTimeModal} setShowTimeModal={setShowTimeModal} isOpenScheduleModal={isOpenScheduleModal} setIsOpenScheduleModal={setIsOpenScheduleModal}/>
              )}
          <Row className="d-flex justify-content-between">
            { !forwardState && (
             <Button onClick={(e) => { return setShowCalendar(!showCalendar), formatForScheduling(e)}}  className={!showCalendar ? " d-flex ml-1 soft-info" : "d-flex ml-1"} color={!showCalendar ? "soft-info" : "falcon-danger"} size="sm">
            <FontAwesomeIcon
                    icon={!showCalendar ? "calendar-alt" : "caret-up"} transform=" down-3 left-4" 
                  />
               {!showCalendar ? 'Set for later' : (showCalendar && windowWidth.width < 500) ? 'Cancel' : 'Cancel Scheduling'}
              </Button> 
         
            )}
            <Button disabled={!bulkMessage.groups.length || !bulkMessage.bulkNumber} className={!showCalendar ? "d-flex ml-auto mr-3" : "d-none"} onClick={(e) => { handleSubmit(e)} } id="previewBtn" color="primary" size="sm">
            <FontAwesomeIcon
                    icon="eye" transform="down-3 left-4" 
                  />
                Preview
              </Button> 

            <Button disabled={!bulkMessage?.bulkNumber || !bulkMessage?.groups?.length} 
            className={!showCalendar ? "d-flex ml-auto mr-3" : "d-none"} onClick={(e) => { handleSubmit(e)}} color="primary" size="sm">
            <FontAwesomeIcon
              icon="paper-plane" transform="down-3 left-4" />
                Send
              </Button> 
              {
                // Initiate bulk text modal
              }
            <Modal isOpen={showSendModal} centered toggle={() => setShowSendModal(!showSendModal)}>
              <ModalHeader close={closeSendBtn} className="d-flex justify-content-center" >Initiate BulkText</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <Button onClick={() => {return setShowSendModal(!showSendModal), setShowBulkTextModal(!showBulkTextModal)}} block className="text-white" color="primary">
                        <FontAwesomeIcon icon="paper-plane" transform="down-2 left-5" />
                          Send now</Button>
                            <Button onClick={() => { setShowPreviewModal(!showPreviewModal)}} block className="text-primary" color="light" >Preview</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // sending bulk Text modal
              }
            <Modal size="lg" isOpen={showBulkTextModal} centered toggle={() => setShowBulkTextModal(!showBulkTextModal)}>
              <ModalHeader close={closeBulkTextBtn} className="d-flex justify-content-center" >BulkText Details</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <BulkTextSending recipients={recipients} contacts={contacts} groups={groups} fields={fields} bulkMessage={bulkMessage} />
                          < hr />
                            <Button onClick={() => { setShowBulkTextModal(!showBulkTextModal)}} block className="text-primary" color="light" >Run in background</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
            </Row>
            </div>
            ): ""}
        </Form>
      </CardBody>
    </Card>
  );
};

export default MessageCreateForm;

