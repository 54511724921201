import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonGroup, Label, Card, CardBody, Form, Modal, ModalBody, ModalHeader} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '../common/Divider';
import ContactForm from './ContactForm';
import NewAddContactsToGroup from './NewAddContactsToGroup';

const GroupContactChoose = ({GroupId, chooseImportModal, setChooseImportModal}) => {

  

  // State
  const [showContactModal, setShowContactModal] = useState(false);
  const [showExistingUserModal, setShowExistingUserModal] = useState(false);
  // Handler
  
  const closeContactBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowContactModal(!showContactModal)}}>
      &times;
    </button>
  );

  const closeExistingBtn = (
    <button className="close font-weight-normal" onClick={() => {setShowExistingUserModal(!showExistingUserModal)}}>
      &times;
    </button>
  );

  return ( 
  <>
    <Form >
      
    <ButtonGroup className="btn-group-toggle btn-block">
          <Button color="theme-default" onClick={() => {setShowContactModal(!showContactModal)}} className={classNames('custom-radio-success')}>
            <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="user-plus" transform="grow-7" className="mt-3 fs-4" />
            <h6 className="mt-2">Create a new contact</h6>
            </Label>
          </Button>
          
         <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
         <ModalHeader toggle={() => {setShowContactModal(!showContactModal)}} close={closeContactBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Create a new contact
                  </ModalHeader>
                   <ModalBody className="p-0">
                       <Card>
                           <CardBody className="fs--1 font-weight-normal p-4"> 
                               <ContactForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
                           </CardBody>
                       </Card>
                   </ModalBody>
               </Modal>
               
          <Button  color="theme-default" a href="/pages/contacts-import" className={classNames('custom-radio-success')}>
          <Label className="cursor-pointer hover-overlay">
          <FontAwesomeIcon icon="file-alt" transform="grow-7" className="fs-4 mt-3" />
            <h6 className="mt-2" >Import from CSV</h6>
            </Label>
          </Button>
          
          <Button color="theme-dark" onClick={() => { setShowExistingUserModal(!showExistingUserModal) }} className={classNames('custom-radio-success')}>
          <Label className="cursor-pointer hover-overlay">
           <FontAwesomeIcon icon="users" transform="grow-7" className="fs-4 mt-3" />
           <h6 className="mt-2" >Add from existing</h6>
           </Label>
          </Button>

          <Modal size="lg" isOpen={showExistingUserModal} centered toggle={() => setShowExistingUserModal(!showExistingUserModal)}>
          <ModalHeader toggle={() => {setShowExistingUserModal(!showExistingUserModal)}} close={closeExistingBtn} className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Choose from existing contacts
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                                <NewAddContactsToGroup chooseImportModal={chooseImportModal} setChooseImportModal={setChooseImportModal} showExistingUserModal={showExistingUserModal} setShowExistingUserModal={setShowExistingUserModal} GroupId={GroupId}/>
                                <Button block onClick={() => setShowExistingUserModal(false)}>close</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
        </ButtonGroup>
    </Form>
  </>
 
    
  );
};

GroupContactChoose.propTypes = {
  
  hasLabel: PropTypes.bool
};

GroupContactChoose.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default GroupContactChoose;