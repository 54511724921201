import { RESPONDER_DELETE_REQUEST, RESPONDER_DELETE_SUCCESS, RESPONDER_DELETE_FAIL } from '../actions/types';

export default function responderDeleteReducer(state = {}, action) {
    switch(action.type) {
        case RESPONDER_DELETE_REQUEST:
            return { loading: true }
        case RESPONDER_DELETE_SUCCESS:
            return { loading: false, success: true}
        case RESPONDER_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}