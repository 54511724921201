import { PREVIEW_MMS_CREATE_REQUEST, PREVIEW_MMS_CREATE_SUCCESS, PREVIEW_MMS_CREATE_FAIL, PREVIEW_MMS_RESET } from '../actions/types';

export default function previewMediaReducer(state = {}, action) {
    switch(action.type) {
        case PREVIEW_MMS_CREATE_REQUEST:
            return { loading: true }
        case PREVIEW_MMS_CREATE_SUCCESS:
            return { loading: false, success: true, payload: action.payload.data}
        case PREVIEW_MMS_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case PREVIEW_MMS_RESET:
                return {};
        default:
            return state
    }
}