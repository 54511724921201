import { CALENDAR_CREATE_REQUEST, CALENDAR_CREATE_SUCCESS, CALENDAR_CREATE_FAIL, CALENDAR_CREATE_RESET } from '../actions/types';

export default function calendarCreateReducer(state = {}, action) {
    switch(action.type) {
        case CALENDAR_CREATE_REQUEST:
            return { loading: true }
        case CALENDAR_CREATE_SUCCESS:
            return { loading: false, success: true}
        case CALENDAR_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case CALENDAR_CREATE_RESET:
            return { loading: false, state: {} }
        default:
            return state
    }
}