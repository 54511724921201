import { BLOCKED_CONTACT_DELETE_REQUEST, BLOCKED_CONTACT_DELETE_SUCCESS, BLOCKED_CONTACT_DELETE_FAIL, BLOCKED_CONTACT_DELETE_RESET } from '../actions/types';

export default function blockedContactDeleteReducer(state = {}, action) {
    switch(action.type) {
        case BLOCKED_CONTACT_DELETE_REQUEST:
            return { loading: true }
        case BLOCKED_CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true}
        case BLOCKED_CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload }
            case BLOCKED_CONTACT_DELETE_RESET:
                return { }
        default:
            return state
    }
}