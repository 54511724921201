import { PAYMENT_METHOD_CREATE_REQUEST, PAYMENT_METHOD_CREATE_SUCCESS, PAYMENT_METHOD_CREATE_FAIL, PAYMENT_METHOD_CREATE_RESET } from '../actions/types';

export default function paymentMethodCreateReducer(state = {}, action) {
    switch(action.type) {
        case PAYMENT_METHOD_CREATE_REQUEST:
            return { loading: true }
        case PAYMENT_METHOD_CREATE_SUCCESS:
            return { loading: false, success: true}
        case PAYMENT_METHOD_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case PAYMENT_METHOD_CREATE_RESET:
            return { loading: false, state: {} }
        default:
            return state
    }
}