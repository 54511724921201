import axios from 'axios';

import {
AVAIL_NUMBERS_REQUEST,
AVAIL_NUMBERS_SUCCESS,
AVAIL_NUMBERS_FAIL,
FETCH_REG_USER,
FETCH_USER,
DELETE_USER,
LIST_TEMPLATES,
LIST_GROUPS,
LIST_SHOW_GROUPS,
LIST_SHOW_AND_HIDE_GROUPS,
LIST_UNSUBS,
LIST_GROUPS_EXCEPT_UNSUB,
LIST_FIELDS,
LIST_RESPONDERS,
LIST_CSVUPLOAD,
LIST_NUMBERS,
LIST_CONTACTS,
LIST_CONTACTS_TOTAL,
LIST_BLOCKED,
LIST_KEYWORDS,
LIST_PRIMARY_NUMBER,
LIST_CALENDAR,
LIST_PAUSED_CALENDAR,
LIST_COMPLETED_CALENDAR,
LIST_INVOICES,
GET_PROFILE_INFO,
LOGOUT_USER,
//-------------------------------------------------Numbers---------------------------------------------------------------------------------------------
NUMBER_CREATE_SUCCESS,
NUMBER_CREATE_REQUEST,
NUMBER_CREATE_FAIL,

NUMBER_DETAILS_REQUEST,
NUMBER_DETAILS_SUCCESS,
NUMBER_DETAILS_FAIL,

NUMBER_UPDATE_REQUEST,
NUMBER_UPDATE_SUCCESS,
NUMBER_UPDATE_FAIL,

PRIMARY_NUMBER_UPDATE_REQUEST,
PRIMARY_NUMBER_UPDATE_SUCCESS,
PRIMARY_NUMBER_UPDATE_FAIL,

NUMBER_DELETE_SUCCESS,
NUMBER_DELETE_REQUEST,
NUMBER_DELETE_FAIL,
//-------------------------------------------------Contacts---------------------------------------------------------------------------------------------
CONTACT_CREATE_SUCCESS,
CONTACT_CREATE_REQUEST,
CONTACT_CREATE_FAIL,

CONTACT_DELETE_SUCCESS,
CONTACT_DELETE_REQUEST,
CONTACT_DELETE_FAIL,

CONTACT_DETAILS_REQUEST,
CONTACT_DETAILS_SUCCESS,
CONTACT_DETAILS_FAIL,

CONTACT_UPDATE_REQUEST,
CONTACT_UPDATE_SUCCESS,
CONTACT_UPDATE_FAIL,

BLOCKED_CONTACT_CREATE_SUCCESS,
BLOCKED_CONTACT_CREATE_REQUEST,
BLOCKED_CONTACT_CREATE_FAIL,

BLOCKED_CONTACT_DELETE_SUCCESS,
BLOCKED_CONTACT_DELETE_REQUEST,
BLOCKED_CONTACT_DELETE_FAIL,
//-------------------------------------------------Csv-Upload---------------------------------------------------------------------------------------------
CSVUPLOAD_CREATE_SUCCESS,
CSVUPLOAD_CREATE_REQUEST,
CSVUPLOAD_CREATE_FAIL,

CSVUPLOAD_DELETE_SUCCESS,
CSVUPLOAD_DELETE_REQUEST,
CSVUPLOAD_DELETE_FAIL,
//-------------------------------------------------Template---------------------------------------------------------------------------------------------
TEMPLATE_CREATE_SUCCESS,
TEMPLATE_CREATE_REQUEST,
TEMPLATE_CREATE_FAIL,

TEMPLATE_DELETE_REQUEST,
TEMPLATE_DELETE_SUCCESS,
TEMPLATE_DELETE_FAIL,

TEMPLATE_UPDATE_REQUEST,
TEMPLATE_UPDATE_SUCCESS,
TEMPLATE_UPDATE_FAIL,
//-------------------------------------------------Group---------------------------------------------------------------------------------------------
GROUP_CREATE_REQUEST,
GROUP_CREATE_SUCCESS,
GROUP_CREATE_FAIL,
GROUP_CREATE_RESET,

GROUP_DELETE_REQUEST,
GROUP_DELETE_SUCCESS,
GROUP_DELETE_FAIL,

GROUP_DETAILS_REQUEST,
GROUP_DETAILS_SUCCESS,
GROUP_DETAILS_FAIL,

GROUP_DETAILS_TITLE_REQUEST,
GROUP_DETAILS_TITLE_SUCCESS,
GROUP_DETAILS_TITLE_FAIL,

GROUP_UPDATE_REQUEST,
GROUP_UPDATE_SUCCESS,
GROUP_UPDATE_FAIL,

GROUP_SHOW_HIDE_REQUEST,
GROUP_SHOW_HIDE_SUCCESS,
GROUP_SHOW_HIDE_FAIL,

GROUP_MOVE_COPY_REQUEST,
GROUP_MOVE_COPY_SUCCESS,
GROUP_MOVE_COPY_FAIL,
//-------------------------------------------------Field---------------------------------------------------------------------------------------------
FIELD_CREATE_REQUEST,
FIELD_CREATE_SUCCESS,
FIELD_CREATE_FAIL,

FIELD_DELETE_REQUEST,
FIELD_DELETE_SUCCESS,
FIELD_DELETE_FAIL,

FIELD_UPDATE_REQUEST,
FIELD_UPDATE_SUCCESS,
FIELD_UPDATE_FAIL,

FIELD_DETAILS_REQUEST,
FIELD_DETAILS_SUCCESS,
FIELD_DETAILS_FAIL,

//-------------------------------------------------Keywords---------------------------------------------------------------------------------------------
KEYWORD_CREATE_REQUEST,
KEYWORD_CREATE_SUCCESS,
KEYWORD_CREATE_FAIL,

KEYWORD_UPDATE_REQUEST,
KEYWORD_UPDATE_SUCCESS,
KEYWORD_UPDATE_FAIL,

KEYWORD_DELETE_REQUEST,
KEYWORD_DELETE_SUCCESS,
KEYWORD_DELETE_FAIL,

//-------------------------------------------------Flows---------------------------------------------------------------------------------------------
RESPONDER_CREATE_REQUEST,
RESPONDER_CREATE_SUCCESS,
RESPONDER_CREATE_FAIL,

RESPONDER_DELETE_REQUEST,
RESPONDER_DELETE_SUCCESS,
RESPONDER_DELETE_FAIL,

RESPONDER_UPDATE_REQUEST,
RESPONDER_UPDATE_SUCCESS,
RESPONDER_UPDATE_FAIL,

//-------------------------------------------------Attach-file---------------------------------------------------------------------------------------------
ATTACH_FILE_CREATE_REQUEST,
ATTACH_FILE_CREATE_SUCCESS,
ATTACH_FILE_CREATE_FAIL,

//-------------------------------------------------Attach-mms---------------------------------------------------------------------------------------------
ATTACH_MMS_CREATE_REQUEST,
ATTACH_MMS_CREATE_SUCCESS,
ATTACH_MMS_CREATE_FAIL,

PREVIEW_MMS_CREATE_REQUEST,
PREVIEW_MMS_CREATE_SUCCESS,
PREVIEW_MMS_CREATE_FAIL,

//-------------------------------------------------Schedule---------------------------------------------------------------------------------------------

CALENDAR_CREATE_REQUEST,
CALENDAR_CREATE_SUCCESS,
CALENDAR_CREATE_FAIL, 
CALENDAR_CREATE_RESET,


CALENDAR_DELETE_REQUEST,
CALENDAR_DELETE_SUCCESS,
CALENDAR_DELETE_FAIL,

CALENDAR_UPDATE_REQUEST,
CALENDAR_UPDATE_SUCCESS,
CALENDAR_UPDATE_FAIL,
CALENDAR_UPDATE_RESET,

LIST_SENT_MESSAGES,
LIST_SENT_MESSAGES_CREATE_REQUEST,
LIST_SENT_MESSAGES_CREATE_SUCCESS,
LIST_SENT_MESSAGES_CREATE_FAIL,

LIST_RECIEVED_MESSAGES,
LIST_RECIEVED_MESSAGES_CREATE_REQUEST,
LIST_RECIEVED_MESSAGES_CREATE_SUCCESS,
LIST_RECIEVED_MESSAGES_CREATE_FAIL,

USER_UPDATE_REQUEST,
USER_UPDATE_SUCCESS,
USER_UPDATE_FAIL,

//----------------------------------------------------------------------------------------------------------------------------------------------

ACCOUNT_DELETE_REQUEST,
ACCOUNT_DELETE_SUCCESS,
ACCOUNT_DELETE_FAIL,
UPDATE_PRIMARY_NUMBER



} from './types';


//-----------------------------------------------------------------------Numbers----------------------------------------------------------------------
        export const findANumber = (country, areaCode, numberType ) => async (dispatch) => {
               try {
                       dispatch({type: AVAIL_NUMBERS_REQUEST})
                         await axios({
                                url:"https://my.bulktext.com/find-a-number",
                                method:"POST",
                                data: { country: country, 
                                        areaCode: areaCode,
                                        numberType: numberType},
                                withCredentials: true
                                })
                        .then( res => dispatch({type: AVAIL_NUMBERS_SUCCESS, payload: res.data}))
                } catch (error) {
                        dispatch({type: AVAIL_NUMBERS_FAIL,
                       payload:
                       error.response && error.response.data.message
                       ? error.response.data.message
                       : error.message
                })
        }}


        export const createNumber = ( selectedNumber, country, numberType, nickName, history ) => async (dispatch) => {
        
                try {
                        dispatch({type: NUMBER_CREATE_REQUEST})

                                await axios({
                                url:"https://my.bulktext.com/number/create",
                                method:"POST",
                                data: {
                                   selectedNumber: selectedNumber,
                                   country: country,
                                   numberType: numberType,
                                   nickName: nickName
                                },
                                withCredentials: true
                              }).then((res => { if(res.data.messageFail) {
                                      history.push('/dashboard/get-credits')
                                      alert("You need more credits")
                              } else {
                                      return dispatch({type: NUMBER_CREATE_SUCCESS})
                        }
                              })

                              )} catch (error) {
                        dispatch({type: NUMBER_CREATE_FAIL,
                                  payload:
                                  error.response && error.response.data.message
                                  ? error.response.data.message
                                  : error.message
                                })
                }
        }

        export const listNumbers = () => {
                return async function(dispatch) {
                        await 
                        axios({
                        url:"https://my.bulktext.com/number/create",
                        method:"GET", 
                        withCredentials: true
                        }).then( res => dispatch({type: LIST_NUMBERS, payload: res.data}))
                        }
        }

        export const numberById = (TempId) => async (dispatch) => {
                try{
                        dispatch({type: NUMBER_DETAILS_REQUEST})
                        await axios
                        .get(`https://my.bulktext.com/number/${TempId}`)
                        .then(res => dispatch({type: NUMBER_DETAILS_SUCCESS, payload: res.data}))

                } catch(error) {
                      dispatch({type: NUMBER_DETAILS_FAIL,
                      payload:
                      error.message && error.response.data.message 
                      ? error.response.data.message :
                      error.message
                      })
                }
        }

        export const deleteNumber = (tempId) => async (dispatch) => {
                console.log(tempId)
                try {
                        dispatch({type: NUMBER_DELETE_REQUEST})

                                axios({
                                url:`https://my.bulktext.com/number/${tempId.tempId}`,
                                method:"DELETE", 
                                withCredentials: true
                                }).then( res => dispatch({type: NUMBER_DELETE_SUCCESS, payload: res.data}))
                } catch (error) {
                         dispatch({
                               type: NUMBER_DELETE_FAIL,
                                 payload:
                                 error.response && error.response.data.message
                                 ? error.response.data.message
                                 : error.message
                         })
                 }
         }


         export const deleteMultipleNumbers = (isSelected) => async (dispatch) => {
                
                try {
                        dispatch({type: NUMBER_DELETE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/numbers-remove`,
                                method:"POST",
                                data: {isSelected},
                                withCredentials: true
                                })

                        dispatch({type: NUMBER_DELETE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: NUMBER_DELETE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

        // really just forwarding
         export const updateNumber = (numberObject, id) => async (dispatch) => {
                 console.log(id)
                try{
                        dispatch({type: NUMBER_UPDATE_REQUEST})

                        const { data } = await axios({
                                url:`https://my.bulktext.com/number/${id}`,
                                method: "PUT",
                                data: numberObject,
                                withCredentials: true})

                        dispatch({
                                type: NUMBER_UPDATE_SUCCESS,
                                payload: data
                        })
                } catch (error) {
                        dispatch({
                                type: NUMBER_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const editNickname = (nickName, tempId) => async (dispatch) => {
                
               try{
                       dispatch({type: NUMBER_UPDATE_REQUEST})

                                await axios({
                               url:`https://my.bulktext.com/number-nickname`,
                               method: "POST",
                               data: {nickName, tempId},
                               withCredentials: true})
                               .then((res) => {
                                dispatch({type: NUMBER_UPDATE_SUCCESS, payload: res.data})
                                })

               } catch (error) {
                       dispatch({
                               type: NUMBER_UPDATE_FAIL,
                               payload: 
                               error.response && error.response.data.message 
                               ? error.response.data.message 
                               : error.message
                       })
               }
       }


        export const removeForwarding = (tempId) => async (dispatch) => {
                console.log("tempId", tempId)
               try{
                       dispatch({type: NUMBER_UPDATE_REQUEST})

                                 await axios({
                               url:`https://my.bulktext.com/number-forward-remove`,
                               method: "POST",
                               data: tempId,
                               withCredentials: true})
                               .then((res) => {
                                dispatch({type: NUMBER_UPDATE_SUCCESS, payload: res.data})     
                               })
               } catch (error) {
                       dispatch({
                               type: NUMBER_UPDATE_FAIL,
                               payload: 
                               error.response && error.response.data.message 
                               ? error.response.data.message 
                               : error.message
                       })
               }
       }


         export const listPrimaryNumbers = () => {
                return async function(dispatch) {
                        await 
                        axios({
                        url:"https://my.bulktext.com/number-primary",
                        method:"GET", 
                        withCredentials: true
                        }).then( res => dispatch({type: LIST_PRIMARY_NUMBER, payload: res.data}))
                        }
        }

         export const makePrimaryNumber = (id) => async (dispatch) => { 
                console.log("makeP{rimary number id", id)
                try {
                        dispatch({type: PRIMARY_NUMBER_UPDATE_REQUEST})

                                await axios({
                                url:"https://my.bulktext.com/number-primary",
                                method:"POST",
                                data: {
                                   numberId: id
                                },
                                withCredentials: true
                              }).then((res) => { return dispatch({type: UPDATE_PRIMARY_NUMBER, payload: res.data }),
                                        dispatch({type: PRIMARY_NUMBER_UPDATE_SUCCESS})

                        })} catch (error) {
                                        dispatch({type: PRIMARY_NUMBER_UPDATE_FAIL,
                                                payload:
                                                error.response && error.response.data.message
                                                ? error.response.data.message
                                                : error.message
                                })
                }

         }

//-----------------------------------------------------------------------Templates----------------------------------------------------------------------

        export const listTemplates = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/templates/create",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_TEMPLATES, payload: res.data}))
                }
        }

        export const createTemplate = (template) => async (dispatch) => {
                        try {
                                dispatch({type: TEMPLATE_CREATE_REQUEST})
        
                                        await axios({
                                        url:"https://my.bulktext.com/templates/create",
                                        method:"POST",
                                        data: template,
                                        withCredentials: true
                                      }).then((res) => {
                                              dispatch({type: TEMPLATE_CREATE_SUCCESS})

                                      })


                                      } catch (error) {
                                dispatch({type: TEMPLATE_CREATE_FAIL,
                                          payload:
                                          error.response && error.response.data.message
                                          ? error.response.data.message
                                          : error.message
                                        })
                        }
             }


        
        export const deleteTemplate = (id) => async (dispatch, getState) => {
                console.log(id)
                try {
                        dispatch({
                                 type: TEMPLATE_DELETE_REQUEST
                        })
    
                        await axios.delete(`https://my.bulktext.com/templates/${id}`)
    
                        dispatch({
                                type: TEMPLATE_DELETE_SUCCESS,
                       })
                       
                } catch (error) {
                        dispatch({
                               type: TEMPLATE_DELETE_FAIL,
                                 payload:
                                 error.response && error.response.data.message
                                 ? error.response.data.message
                                 : error.message
                        })
                }
        }


        export const updateTemplate = (template, id) => async (dispatch) => {
                try{
                        dispatch({type: TEMPLATE_UPDATE_REQUEST})

                        const { data } = await axios({
                                url:`https://my.bulktext.com/templates/${id}`,
                                method: "PUT",
                                data: template,
                                withCredentials: true})

                        dispatch({type: TEMPLATE_UPDATE_SUCCESS, payload: data})

                } catch (error) {
                        dispatch({
                                type: TEMPLATE_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const deleteMultipleTemplates = (isSelected) => async (dispatch) => {
                try {
                        dispatch({type: TEMPLATE_DELETE_REQUEST})
        
                        await axios({
                                url:`https://my.bulktext.com/templates-remove`,
                                method:"POST",
                                data: isSelected, 
                                withCredentials: true
                                })
        
                        dispatch({type: TEMPLATE_DELETE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: TEMPLATE_DELETE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

//-----------------------------------------------------------------------Contacts----------------------------------------------------------------------

                export const getContactsByIds = (ids) => {
                        return async function(dispatch) {
                          return axios.post('https://my.bulktext.com/contacts/byIds', { ids }, { withCredentials: true })
                          .then(res => {
                            return res.data;
                          })
                          .catch(error => {
                            throw error;
                          });
                        }
                    }
            
            
            
            
                export const listContacts = (search, page = 1, limit = 25) => {
                        return async function(dispatch) {
                        
                          // Add the query parameters to the URL
                          const url = `https://my.bulktext.com/contacts/create?search=${search}&page=${page}&limit=${limit}`;
                        
                          return axios({
                            url,
                            method: "GET",
                            withCredentials: true
                          })
                          .then(res => {
                            dispatch({type: LIST_CONTACTS, payload: res.data});
                            return res.data; // Resolve promise with data
                          })
                          .catch(error => {
                            // Add error handling logic here if needed
                            throw error; // If there is an error, the promise is rejected
                          });
                        }
                      }


                      export const listContactsTotal = (search, page = 1, limit = 25) => {
                        return async function(dispatch) {
                          // Add the query parameters to the URL
                          const url = `https://my.bulktext.com/contacts-total`;
                      
                          await axios({
                            url,
                            method: "GET",
                            withCredentials: true
                          })
                          .then(res => dispatch({type: LIST_CONTACTS_TOTAL, payload: res.data}))
                          .catch(error => {
                            // Add error handling logic here if needed
                          });
                        }
                      }

   

        export const createContact = (contact) => async (dispatch) => {
                   try {
                           dispatch({type: CONTACT_CREATE_REQUEST})
   
                           await axios({
                                   url:"https://my.bulktext.com/contacts/create",
                                   method:"POST",
                                   data: contact,
                                   withCredentials: true
                                 }).then((res) => {
                                         dispatch({type: CONTACT_CREATE_SUCCESS, payload: res.data}) // updates our state with response object
                                 })
   
                        } catch (error) {
                                dispatch({type: CONTACT_CREATE_FAIL,
                                     payload:
                                     error.response && error.response.data.message
                                     ? error.response.data.message
                                     : error.message
                                })
                }
        }
        export const deleteContact = (id) => async (dispatch) => {
                try {
                        dispatch({type: CONTACT_DELETE_REQUEST})

                await axios.delete(`https://my.bulktext.com/contacts/${id}`, {withCredentials: true})

                dispatch({type: CONTACT_DELETE_SUCCESS})

                } catch (error) {
                        dispatch({
                                type: CONTACT_DELETE_FAIL,
                                payload:
                                error.response && error.response.data.message
                                ? error.response.data.message
                                : error.message
                        })
                }
        }

        export const deleteMultipleContacts = (isSelected, conversation) => async (dispatch) => {
                
                try {
                        dispatch({type: CONTACT_DELETE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/contacts-remove`,
                                method:"POST",
                                data: {isSelected, conversation},
                                withCredentials: true
                                })

                        dispatch({type: CONTACT_DELETE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: CONTACT_DELETE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

         export const deleteContactFromGroup = (selectedContactInfo, GroupId) => async (dispatch) => {
                try {
        
                        await axios.delete(`https://my.bulktext.com/group-remove`, { data: { groupId: GroupId, selectedContacts: selectedContactInfo } }, {withCredentials: true})
        
                        dispatch({
                                type: CONTACT_DELETE_SUCCESS,
                       })
                } catch (error) {
                         dispatch({
                               type: CONTACT_DELETE_FAIL,
                                 payload:
                                 error.response && error.response.data.message
                                 ? error.response.data.message
                                 : error.message
                         })
                 }
        }

        export const resubscribeContacts = (selectedContactInfo, GroupId) => async (dispatch) => {
                console.log(selectedContactInfo, GroupId)
                try {
                        await axios({
                                url:`https://my.bulktext.com/resubscribe`,
                                method:"POST",
                                data: { groupId: GroupId, selectedContacts: selectedContactInfo },
                                withCredentials: true
                                })
                        dispatch({
                                type: CONTACT_DELETE_SUCCESS,
                       })
                } catch (error) {
                         dispatch({
                               type: CONTACT_DELETE_FAIL,
                                 payload:
                                 error.response && error.response.data.message
                                 ? error.response.data.message
                                 : error.message
                         })
                 }
        }

        export const unsubscribeContacts = (blockContact) => async (dispatch) => {
                console.log(blockContact)
                try {
                        dispatch({type: CONTACT_UPDATE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/unsubscribe`,
                                method:"POST",
                                data: blockContact,
                                withCredentials: true
                                })
                        dispatch({ type: CONTACT_UPDATE_SUCCESS })

                } catch (error) {
                         dispatch({
                               type: CONTACT_UPDATE_FAIL,
                                 payload:
                                 error.response && error.response.data.message
                                 ? error.response.data.message
                                 : error.message
                         })
                 }
        }

        export const contactById = (ContactId) => async (dispatch) => {
                try{
                        dispatch({type: CONTACT_DETAILS_REQUEST})
                        await axios
                        .get(`https://my.bulktext.com/contacts/${ContactId}`, {withCredentials: true})
                        .then(res => dispatch({type: CONTACT_DETAILS_SUCCESS, payload: res}))

                } catch(error) {
                      dispatch({type: CONTACT_DETAILS_FAIL,
                      payload:
                      error.message && error.response.data.message 
                      ? error.response.data.message :
                      error.message
                      })
                }
        }

        export const contactArrayById = (selectedContactsID) => async (dispatch) => {
                try{
                        dispatch({type: CONTACT_DETAILS_REQUEST})
                        await axios
                        .get("https://my.bulktext.com/contact-details", selectedContactsID, {withCredentials: true})
                        .then(res => dispatch({type: CONTACT_DETAILS_SUCCESS, payload: res}))

                } catch(error) {
                dispatch({type: CONTACT_DETAILS_FAIL,
                        payload:
                        error.message && error.response.data.message 
                        ? error.response.data.message :
                        error.message
                        })
                }
        }

        export const updateContact = (contact, id) => async (dispatch) => {
                console.log(contact)
                try{
                        dispatch({type: CONTACT_UPDATE_REQUEST})

                        const { data } = await axios({
                                url:`https://my.bulktext.com/contacts/${id}`,
                                method: "PUT",
                                data: contact,
                                withCredentials: true})

                        dispatch({
                                type: CONTACT_UPDATE_SUCCESS,
                                payload: data
                        })
                        dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: data})
                } catch (error) {
                        dispatch({
                                type: CONTACT_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }
 
 //-----------------------------------------------------------------------Blocked Contacts---------------------------------------------------------------------




        export const listBlockedContacts = () => {
                return async function(dispatch) {
                        await 
                        axios({
                         url:"https://my.bulktext.com/contacts-block",
                         method:"GET", 
                         withCredentials: true
                         }).then( res => dispatch({type: LIST_BLOCKED, payload: res.data}))
                         }
                }

                export const createBlockedContacts = (contact, conversations) => async (dispatch) => {

                       const contacts = contact.contacts.map((c) => c.item.phone_number.replace(/[^\d]+/g, "")) //formatting incoming numbers
                                try {
                                       dispatch({type: BLOCKED_CONTACT_CREATE_REQUEST})
        
                                       await axios({
                                               url:"https://my.bulktext.com/contacts-block",
                                               method:"POST",
                                               data: {contacts: contacts, conversations: conversations},
                                               withCredentials: true
                                             })
        
                                       dispatch({type: BLOCKED_CONTACT_CREATE_SUCCESS}) // updates our state with response object
                                        } catch (error) {
                                            dispatch({type: BLOCKED_CONTACT_CREATE_FAIL,
                                                 payload:
                                                 error.response && error.response.data.message
                                                 ? error.response.data.message
                                                 : error.message
                                                })
                                        }
                }


                export const unBlockContacts = (isSelected) => async (dispatch) => {
                        try{
                                dispatch({type: BLOCKED_CONTACT_DELETE_REQUEST})
        
                                const { data } = await axios({
                                        url:`https://my.bulktext.com/contacts-unblock`,
                                        method: "POST",
                                        data: isSelected,
                                        withCredentials: true})
        
                                dispatch({
                                        type: BLOCKED_CONTACT_DELETE_SUCCESS,
                                        payload: data
                                })
                        } catch (error) {
                                dispatch({
                                        type: BLOCKED_CONTACT_DELETE_FAIL,
                                        payload: 
                                        error.response && error.response.data.message 
                                        ? error.response.data.message 
                                        : error.message
                                })
                        }
                }






 //-----------------------------------------------------------------------Groups----------------------------------------------------------------------------------

        export const listGroups = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/groups",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_GROUPS, payload: res.data}))
                }
        }

        export const listShowGroups = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/groups",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_SHOW_GROUPS, payload: res.data}))
                }
        }

        export const listShowAndHideGroups = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/groups",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_SHOW_AND_HIDE_GROUPS, payload: res.data}))
                }
        }

        export const listUnsubs = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/groups",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_UNSUBS, payload: res.data}))
                }
        }

        export const listGroupsExceptUnsub = () => { // except unsub && blocked === true
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/groups",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_GROUPS_EXCEPT_UNSUB, payload: res.data}))
                }
        }


        export const createGroup = (group) => async (dispatch) => {
                try {
                         dispatch({type: GROUP_CREATE_REQUEST})
                
                        await axios({
                        url:"https://my.bulktext.com/groups",
                        method:"POST",
                        data: group, 
                        withCredentials: true
                      })
                                // this dispatch was not running because there was not response being sent from backend
                      dispatch({type: GROUP_CREATE_SUCCESS})
              
                } catch (error) {
                        dispatch({type: GROUP_CREATE_FAIL,
                        payload:
                        error.message && error.response.data.message 
                        ? error.response.data.message :
                        error.message
                        })
                }
        }

        export const deleteGroup = (id) => async (dispatch) => {
                try {
                        dispatch({type: GROUP_DELETE_REQUEST})

                        await axios.delete(`https://my.bulktext.com/groups/${id}`, {withCredentials: true})

                        dispatch({type: GROUP_DELETE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: GROUP_DELETE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

        export const updateGroup = (group, id) => async (dispatch) => {
                console.log(group, id)
                try{
                        dispatch({type: GROUP_UPDATE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/groups-title/${id}`,
                                method: "PUT",
                                data: group,
                                withCredentials: true})

                        dispatch({
                                type: GROUP_UPDATE_SUCCESS
                        })
                } catch (error) {
                        dispatch({
                                type: GROUP_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const updateGroupShowHide = (hide, id) => async (dispatch) => {
          
                try{
                        dispatch({type: GROUP_SHOW_HIDE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/groups-show-hide`,
                                method: "POST",
                                data: {hide: hide, id: id},
                                withCredentials: true})

                        dispatch({
                                type: GROUP_SHOW_HIDE_SUCCESS
                        })
                } catch (error) {
                        dispatch({
                                type: GROUP_SHOW_HIDE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        

        export const deleteMultipleGroups = (isSelected) => async (dispatch) => {
                try {
                        dispatch({type: GROUP_DELETE_REQUEST})

                        await axios({
                                url:`https://my.bulktext.com/groups-remove`,
                                method:"POST",
                                data: isSelected, 
                                withCredentials: true
                                })

                       

                        dispatch({type: GROUP_DELETE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: GROUP_DELETE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

        export const addContactsToGroup = (groupId, selectedRows) => async (dispatch) => {
                try{
                        dispatch({type: GROUP_UPDATE_REQUEST})
       
                        await axios.put(`https://my.bulktext.com/groups/${groupId}`, {groupId: groupId, selectedRows: selectedRows}, {withCredentials: true})
                        .then(res => dispatch({type: GROUP_UPDATE_SUCCESS, payload: res}))
       

                } catch (error) {
                        dispatch({
                                type: GROUP_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const addContactsToMultipleGroups = (selectedContactRows, selectedGroups) => async (dispatch) => {
                try{
                        dispatch({type: GROUP_UPDATE_REQUEST})
       
                        await axios.post(`https://my.bulktext.com/contacts/group-add`, {selectedContactRows, selectedGroups}, {withCredentials: true})
                        .then(res => dispatch({type: GROUP_UPDATE_SUCCESS, payload: res}))
       

                } catch (error) {
                        dispatch({
                                type: GROUP_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const moveCopyContactsToGroup = (changeGroups) => async (dispatch) => {
                console.log(changeGroups)
                try{
                        dispatch({type: GROUP_MOVE_COPY_REQUEST})
       
                        await axios({
                                url:`https://my.bulktext.com/groups-move-copy`,
                                method:"POST",
                                data: changeGroups, 
                                withCredentials: true
                                })
                        
                                dispatch({type: GROUP_MOVE_COPY_SUCCESS})

                } catch (error) {
                        dispatch({
                                type: GROUP_MOVE_COPY_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }

        export const groupById = (GroupId) => async (dispatch) => {
                try{
                        dispatch({type: GROUP_DETAILS_REQUEST})
                        await axios
                        .get(`https://my.bulktext.com/groups/${GroupId}`, {withCredentials: true})
                        .then(res => dispatch({type: GROUP_DETAILS_SUCCESS, payload: res.data.contacts}))
                        
                } catch(error) {
                       dispatch({type: GROUP_DETAILS_FAIL,
                       payload:
                       error.message && error.response.data.message 
                       ? error.response.data.message :
                       error.message
                       })       
                }
        }

        export const groupByIdTitle = (GroupId) => async (dispatch) => {
                try{
                       dispatch({type: GROUP_DETAILS_TITLE_REQUEST})
                       await axios
                       .get(`https://my.bulktext.com/groups-title/${GroupId}`, { withCredentials: true})
                       .then(res => dispatch({type: GROUP_DETAILS_TITLE_SUCCESS, payload: res}))
                
                } catch(error) {
                              dispatch({type: GROUP_DETAILS_TITLE_FAIL,
                              payload:
                              error.message && error.response.data.message 
                              ? error.response.data.message :
                              error.message
                        })      
                }
        }

  //----------------------------------------------------------------------- Fields----------------------------------------------------------------------
  
        export const createField = (field) => async (dispatch) => {
                try {
                        dispatch({type: FIELD_CREATE_REQUEST})
                
                        await axios({
                        url:"https://my.bulktext.com/fields/create",
                        method:"POST",
                        data: field, 
                        withCredentials: true
                        })
                               // this dispatch was not running because there was not response being sent from backend
                     dispatch({type: FIELD_CREATE_SUCCESS})
             
                } catch (error) {
                       dispatch({type: FIELD_CREATE_FAIL,
                       payload:
                       error.message && error.response.data.message 
                       ? error.response.data.message :
                       error.message
                       })

                }
        }

        export const listFields = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/fields/create",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_FIELDS, payload: res.data}))
                }
        }

export const deleteField = (id) => async (dispatch) => {
        try {
                dispatch({
                         type: FIELD_DELETE_REQUEST
                })

                await axios.delete(`https://my.bulktext.com/fields/${id}`, {withCredentials: true})

                dispatch({
                        type: FIELD_DELETE_SUCCESS,
               })
        } catch (error) {
                 dispatch({
                       type: FIELD_DELETE_FAIL,
                         payload:
                         error.response && error.response.data.message
                         ? error.response.data.message
                         : error.message
                 })
         }
 }

 export const deleteMultipleFields = (isSelected) => async (dispatch) => {
        try {
                dispatch({type: FIELD_DELETE_REQUEST})

                await axios({
                        url:`https://my.bulktext.com/fields-remove`,
                        method:"POST",
                        data: isSelected, 
                        withCredentials: true
                        })

                dispatch({type: FIELD_DELETE_SUCCESS})
        } catch (error) {
                dispatch({
                type: FIELD_DELETE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
}

 export const updateField = (field, id) => async (dispatch) => {
         try{
                 dispatch({
                         type: FIELD_UPDATE_REQUEST
                 })

                 const { data } = await axios.put(`https://my.bulktext.com/fields/${id}`, {field: field, id: id}, {withCredentials: true})

                 dispatch({
                         type: FIELD_UPDATE_SUCCESS,
                         payload: data
                 })
         } catch (error) {
                 dispatch({
                         type: FIELD_UPDATE_FAIL,
                         payload: 
                         error.response && error.response.data.message 
                         ? error.response.data.message 
                         : error.message
                 })
         }
 }

 export const fieldById = (FieldId) => async (dispatch) => {
        try{
               dispatch({type: FIELD_DETAILS_REQUEST})
               await axios
               .get(`https://my.bulktext.com/fields/${FieldId}`, {withCredentials: true})
               .then(res => dispatch({type: FIELD_DETAILS_SUCCESS, payload: res}))
      
        } catch(error) {
                      dispatch({type: FIELD_DETAILS_FAIL,
                      payload:
                      error.message && error.response.data.message 
                      ? error.response.data.message :
                      error.message
                      })
                      
               }
       }




  //-----------------------------------------------------------------------Keywords----------------------------------------------------------------------
  
          export const createKeyword = (keyword) => async (dispatch) => {
                try {
                        dispatch({type: KEYWORD_CREATE_REQUEST})
                
                        await axios({
                        url:"https://my.bulktext.com/keywords/create",
                        method:"POST",
                        data: keyword, 
                        withCredentials: true
                        }).then((res) => {
                                dispatch({type: KEYWORD_CREATE_SUCCESS, payload: res.data})
                        })
                               // this dispatch was not running because there was not response being sent from backend
                  
                
                } catch (error) {
                       dispatch({type: KEYWORD_CREATE_FAIL,
                       payload:
                       error.message && error.response.data.message 
                       ? error.response.data.message :
                       error.message
                       })
                
                }
        }
        
        export const listKeywords = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/keywords",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_KEYWORDS, payload: res.data}))
                }
        }
        
        
        export const deleteMultipleKeywords = (isSelected) => async (dispatch) => {
        try {
                dispatch({type: KEYWORD_DELETE_REQUEST})
        
                await axios({
                        url:`https://my.bulktext.com/keywords-remove`,
                        method:"POST",
                        data: isSelected, 
                        withCredentials: true
                        })
                
                dispatch({type: KEYWORD_DELETE_SUCCESS})
        } catch (error) {
                dispatch({
                type: KEYWORD_DELETE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
        }
        
        export const updateKeyword = (keyword, id) => async (dispatch, getState) => {
         try{
                 dispatch({
                         type: KEYWORD_UPDATE_REQUEST
                 })
                
                 const { data } = await axios.put(`https://my.bulktext.com/keywords/${id}`, keyword, {withCredentials: true})
                
                 dispatch({
                         type: KEYWORD_UPDATE_SUCCESS,
                         payload: data
                 })
         } catch (error) {
                 dispatch({
                         type: KEYWORD_UPDATE_FAIL,
                         payload: 
                         error.response && error.response.data.message 
                         ? error.response.data.message 
                         : error.message
                 })
         }
        }
        
        export const keywordById = (FieldId) => async (dispatch) => {
        try{
               dispatch({type: FIELD_DETAILS_REQUEST})
               await axios
               .get(`https://my.bulktext.com/fields/${FieldId}`)
               .then(res => dispatch({type: FIELD_DETAILS_SUCCESS, payload: res}))
        
        } catch(error) {
                      dispatch({type: FIELD_DETAILS_FAIL,
                      payload:
                      error.message && error.response.data.message 
                      ? error.response.data.message :
                      error.message
                      })
                      
               }
        }



//-----------------------------------------------------------------------Csv Upload----------------------------------------------------------------------

export const listResponders = () => {
        return async function(dispatch) {
       await 
       axios({
        url:"https://my.bulktext.com/responders",
        method:"GET", 
        withCredentials: true
        }).then( res => dispatch({type: LIST_RESPONDERS, payload: res.data}))
        }
}

export const createResponder = (upload) => async (dispatch) => {
        try {
                dispatch({type: RESPONDER_CREATE_REQUEST})

                await axios({
                url:"https://my.bulktext.com/responders/create",
                method:"POST",
                data: upload,
                withCredentials: true
              })

                dispatch({type: RESPONDER_CREATE_SUCCESS})
        } catch (error) {
                dispatch({type: RESPONDER_CREATE_FAIL,
                  payload:
                  error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message
                })
        }
}

        export const updateResponder = (responder, id) => async (dispatch) => {
               
                try{
                        dispatch({type: RESPONDER_UPDATE_REQUEST})

                        const { data } = await axios({
                                url:`https://my.bulktext.com/responders/${id}`,
                                method: "PUT",
                                data: responder,
                                withCredentials: true})

                        dispatch({
                                type: RESPONDER_UPDATE_SUCCESS,
                                payload: data
                        })
                        //dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: data})
                } catch (error) {
                        dispatch({
                                type: RESPONDER_UPDATE_FAIL,
                                payload: 
                                error.response && error.response.data.message 
                                ? error.response.data.message 
                                : error.message
                        })
                }
        }


export const deleteMultipleResponders = (isSelected) => async (dispatch) => {

try {
        dispatch({type: RESPONDER_DELETE_REQUEST})

        await axios({
                url:"https://my.bulktext.com/responders-remove",
                method:"POST",
                data: isSelected, 
                withCredentials: true
                })

        dispatch({
                type: RESPONDER_DELETE_SUCCESS
        })
} catch (error) {
        dispatch({
        type: RESPONDER_DELETE_FAIL,
        payload:
        error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
}
}



//-----------------------------------------------------------------------Csv Upload----------------------------------------------------------------------

        export const listFiles = () => {
                return async function(dispatch) {
               await 
               axios({
                url:"https://my.bulktext.com/files/create",
                method:"GET", 
                withCredentials: true
                }).then( res => dispatch({type: LIST_CSVUPLOAD, payload: res.data}))
                }
        }

export const createCsv = (upload) => async (dispatch) => {
        try {
                dispatch({type: CSVUPLOAD_CREATE_REQUEST})

                        await axios({
                        url:"https://my.bulktext.com/files/create",
                        method:"POST",
                        data: upload,
                        withCredentials: true
                      })

                dispatch({type: CSVUPLOAD_CREATE_SUCCESS})
        } catch (error) {
                dispatch({type: CSVUPLOAD_CREATE_FAIL,
                          payload:
                          error.response && error.response.data.message
                          ? error.response.data.message
                          : error.message
                        })
        }
}

export const deleteFiles = (id) => async (dispatch) => {
        try {
                dispatch({
                         type: CSVUPLOAD_DELETE_REQUEST
                })

                await axios.delete(`https://my.bulktext.com/files/${id}`, {withCredentials: true})

                dispatch({
                        type: CSVUPLOAD_DELETE_SUCCESS,
               })
        } catch (error) {
                 dispatch({
                       type: CSVUPLOAD_DELETE_FAIL,
                         payload:
                         error.response && error.response.data.message
                         ? error.response.data.message
                         : error.message
                 })
         }
 }

 export const deleteMultipleFiles = (isSelected) => async (dispatch) => {

        try {
                dispatch({type: CSVUPLOAD_DELETE_REQUEST})

                await axios({
                        url:`https://my.bulktext.com/files-remove`,
                        method:"POST",
                        data: isSelected, 
                        withCredentials: true
                        })

                dispatch({
                        type: CSVUPLOAD_DELETE_SUCCESS
                })
        } catch (error) {
                dispatch({
                type: CSVUPLOAD_DELETE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
}
     
    

        
        export const attachFile = (uploadFile) => async (dispatch) => {
                try {
                        dispatch({type: ATTACH_FILE_CREATE_REQUEST })
                        await axios({
                                url:"https://my.bulktext.com/attach-file",
                                method:"POST",
                                data: uploadFile,
                                withCredentials: true
                              }).then(res =>  dispatch({type: ATTACH_FILE_CREATE_SUCCESS, payload: res}))   


                } catch(error) {
                        console.log(error)
                        dispatch({type: ATTACH_FILE_CREATE_FAIL,
                                payload:
                                error.message && error.response.data.message 
                                ? error.response.data.message :
                                error.message
                                })
                }
        }
    


        export const attachMMS = (uploadMMS) => async (dispatch) => {
           
                try {

                        // need to send with different headers? its always sending JSON // check bookmark to complete
                        dispatch({type: ATTACH_MMS_CREATE_REQUEST })

                        await axios({
                                url:"https://my.bulktext.com/mms",
                                method:"POST",
                                data: uploadMMS,
                                withCredentials: true
                              }).then(res =>  dispatch({type: ATTACH_MMS_CREATE_SUCCESS, payload: res}))   


                } catch(error) {
                        console.log(error)
                        dispatch({type: ATTACH_MMS_CREATE_FAIL,
                                payload:
                                error.message && error.response.data.message 
                                ? error.response.data.message :
                                error.message
                                })
                }
        }




        export const previewMMS = (data) => async (dispatch) => {
           
                try {

                        // need to send with different headers? its always sending JSON // check bookmark to complete
                        dispatch({type: PREVIEW_MMS_CREATE_REQUEST })

                        await axios({
                                url:"https://my.bulktext.com/mms-preview",
                                method:"POST",
                                data: {uri: data},
                                withCredentials: true
                              }).then(res =>  dispatch({type: PREVIEW_MMS_CREATE_SUCCESS, payload: res}))   


                } catch(error) {
                        dispatch({type: PREVIEW_MMS_CREATE_FAIL,
                                payload:
                                error.message && error.response.data.message 
                                ? error.response.data.message :
                                error.message
                                })
                }
        }
//--------------------------------------------------------------------Calendar--------------------------------------------------------------------


export const listCalendar = () => async (dispatch) => {
        try {

               await axios({
               url:"https://my.bulktext.com/calendar",
               method:"GET",
               withCredentials: true
             }).then(res =>  dispatch({type: LIST_CALENDAR, payload: res.data}))
        } catch (error) {
                     
        }   }

export const listPausedCalendar = () => async (dispatch) => {
        try {

               await axios({
               url:"https://my.bulktext.com/calendar",
               method:"GET",
               withCredentials: true
             }).then(res =>  dispatch({type: LIST_PAUSED_CALENDAR, payload: res.data}))
        } catch (error) {
                     
        }   }

        export const listCompletedCalendar = () => async (dispatch) => {
                try {
        
                       await axios({
                       url:"https://my.bulktext.com/calendar",
                       method:"GET",
                       withCredentials: true
                     }).then(res =>  dispatch({type: LIST_COMPLETED_CALENDAR, payload: res.data}))
                } catch (error) {
                             
                }   }



export const calendarCreate = (event) => async (dispatch) => {
     
       try {
               dispatch({type: CALENDAR_CREATE_REQUEST})
       
        await axios({
              url:"https://my.bulktext.com/calendar",
              method:"POST",
              data: {event},
              withCredentials: true
            })
            dispatch({type: CALENDAR_CREATE_SUCCESS})
           
       } catch (error) {
              dispatch({type: CALENDAR_CREATE_FAIL,
              payload:
              error.message && error.response.data.message 
              ? error.response.data.message :
              error.message
              })
              
       }
}

export const calendarDelete = (id) => async (dispatch) => {
        try {
                dispatch({
                         type: CALENDAR_DELETE_REQUEST
                })

                await axios.delete(`https://my.bulktext.com/calendar/${id}`, {withCredentials: true})

                dispatch({
                        type: CALENDAR_DELETE_SUCCESS,
               })
               
        } catch (error) {
                dispatch({
                       type: CALENDAR_DELETE_FAIL,
                         payload:
                         error.response && error.response.data.message
                         ? error.response.data.message
                         : error.message
                })
        }
}

export const deleteMultipleCalendars = (isSelected) => async (dispatch) => {
        try {
                dispatch({type: CALENDAR_DELETE_REQUEST})

                await axios({
                        url:`https://my.bulktext.com/calendar-remove`,
                        method:"POST",
                        data: isSelected, 
                        withCredentials: true
                        })

               

                dispatch({type: CALENDAR_DELETE_SUCCESS})
        } catch (error) {
                dispatch({
                type: CALENDAR_DELETE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
}



export const calendarUpdate = (event) => async (dispatch) => {
    
       try {
               dispatch({type: CALENDAR_UPDATE_REQUEST})
       
        await axios({
              url:"https://my.bulktext.com/calendar",
              method:"PUT",
              data: {event},
              withCredentials: true
            })
            dispatch({type: CALENDAR_UPDATE_SUCCESS})
           
       } catch (error) {
              dispatch({type: CALENDAR_UPDATE_FAIL,
              payload:
              error.message && error.response.data.message 
              ? error.response.data.message :
              error.message
              })
              
       }
}


export const calendarResume = (event) => async (dispatch) => {
  
        try {
                dispatch({type: CALENDAR_UPDATE_REQUEST})
        
         await axios({
               url:"https://my.bulktext.com/calendar-resume",
               method:"POST",
               data: {event},
               withCredentials: true
             }).then((res) => { dispatch({type: CALENDAR_UPDATE_SUCCESS}) })
            
        } catch (error) {
               dispatch({type: CALENDAR_UPDATE_FAIL,
               payload:
               error.message && error.response.data.message 
               ? error.response.data.message :
               error.message
               })
               
        }
 }

 
 export const calendarPause = (event) => async (dispatch) => {

            try {
                    dispatch({type: CALENDAR_UPDATE_REQUEST})
            
             await axios({
                   url:"https://my.bulktext.com/calendar-pause",
                   method:"POST",
                   data: {event},
                   withCredentials: true
                 })
                 dispatch({type: CALENDAR_UPDATE_SUCCESS})
                
            } catch (error) {
                   dispatch({type: CALENDAR_UPDATE_FAIL,
                   payload:
                   error.message && error.response.data.message 
                   ? error.response.data.message :
                   error.message
                   })
                   
            }
     }



export const resumeMultipleCalendars = (isSelected) => async (dispatch) => {
        try {
                dispatch({type: CALENDAR_UPDATE_REQUEST})

                await axios({
                        url:`https://my.bulktext.com/calendar-update`,
                        method:"POST",
                        data: isSelected, 
                        withCredentials: true
                        }).then((res) => { dispatch({type: CALENDAR_UPDATE_SUCCESS})})

               

                dispatch({type: CALENDAR_UPDATE_SUCCESS})
        } catch (error) {
                dispatch({
                type: CALENDAR_UPDATE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
}

//--------------------------------------------------------------------History--------------------------------------------------------------------




export const listSentMessages = () => {
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-sent",
        method:"GET", 
        withCredentials: true
        }).then( res => dispatch({type: LIST_SENT_MESSAGES, payload: res}))
        }
}

export const listSentMessagesDateRange = (dateRange) => {
     
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-sent-dr",
        method:"POST", 
        data: {dateRange},
        withCredentials: true
        }).then( res => { return console.log("res", res), dispatch({type: LIST_SENT_MESSAGES, payload: res})})
        }
}

export const listSentMessagesCustomDateRange = (dateRange) => {
     
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-sent-custom-dr",
        method:"POST", 
        data: {dateRange},
        withCredentials: true
        }).then( res => { dispatch({type: LIST_SENT_MESSAGES, payload: res})})
        }
}


export const listRecievedMessages = () => {
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-recieved",
        method:"GET", 
        withCredentials: true
        }).then( res => dispatch({type: LIST_RECIEVED_MESSAGES, payload: res}))
        }
}

export const listRecievedMessagesDateRange = (dateRange) => {
     
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-recieved-dr",
        method:"POST", 
        data: {dateRange},
        withCredentials: true
        }).then( res => { return console.log("res", res), dispatch({type: LIST_RECIEVED_MESSAGES, payload: res})})
        }
}

export const listRecievedMessagesCustomDateRange = (dateRange) => {
     
        return async function(dispatch) {
        
        await 
        axios({
        url:"https://my.bulktext.com/history-recieve-custom-dr",
        method:"POST", 
        data: {dateRange},
        withCredentials: true
        }).then( res => { dispatch({type: LIST_RECIEVED_MESSAGES, payload: res})})
        }
}



export const listInvoices = () => async (dispatch) => {
        try {

               await axios({
               url:"https://my.bulktext.com/invoices",
               method:"GET",
               withCredentials: true
             }).then(res =>  dispatch({type: LIST_INVOICES, payload: res.data}))
        } catch (error) {
                     
        }   }
//--------------------------------------------------------------------Extra Functions--------------------------------------------------------------------


export const chargeUserForCall = () => async (dispatch) => {
        try {
                dispatch({type: USER_UPDATE_REQUEST})

                await axios({
                        url:`https://my.bulktext.com/update-user`,
                        method:"POST",
                        data: {}, 
                        withCredentials: true
                        })

               

                dispatch({type: USER_UPDATE_SUCCESS})
        } catch (error) {
                dispatch({
                type: USER_UPDATE_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                })
        }
}


















        export const deleteAccount = (id, history) => async (dispatch) => {  
                try {

                        dispatch({type: ACCOUNT_DELETE_REQUEST})
                        await axios({
                                url: `https://my.bulktext.com/account-delete/${id}`,
                                method: "DELETE",
                                withCredentials: true
                        }).then((res) => { return dispatch({type: ACCOUNT_DELETE_SUCCESS, payload: res.data})})


                        } catch(error) {
                                dispatch({
                                        type: ACCOUNT_DELETE_FAIL,
                                        payload:
                                        error.response && error.response.data.message
                                        ? error.response.data.message
                                        : error.message
                                        })    
                        }
      
        }



        export const fetchUser = () => {
                return async function(dispatch) {
                  await axios
                  .get('/api/current_user')
                    .then(res => dispatch({ type: FETCH_USER, payload: res.data }));
                };
              };

        export const LogoutUser = () => {
                return async function(dispatch) {
                        await axios
                        .get('https://my.bulktext.com/api/logout')
                        .then( res => dispatch({ type: LOGOUT_USER, payload: res}))
                }
        }



        export const fetchRegUser = () => {
                return async function(dispatch) {
                        await axios
                        .get('/api/users')
                        .then(res => dispatch({type: FETCH_REG_USER, payload: res.data}))
                }
        }

        export const deleteUser = (id) => {  
                return async function (dispatch) {
                      
                        const res = await axios
                        .delete(`https://my.bulktext.com/api/users/${id}`)
                        dispatch({type: DELETE_USER, payload: res.data })
                }
        }

                         // sending stripe token to backend
      
        export const updateUser = (id, data) => async (dispatch) => {

                try {
                        dispatch({type: USER_UPDATE_REQUEST})
        
                        await axios({
                                url:`https://my.bulktext.com/account-update/${id}`,
                                method:"POST",
                                data: data, 
                                withCredentials: true
                                })
        
                        dispatch({type: USER_UPDATE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: USER_UPDATE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }

        export const updateUserFirstLogin = (id, data) => async (dispatch) => {

                try {
                        dispatch({type: USER_UPDATE_REQUEST})
        
                        await axios({
                                url:`https://my.bulktext.com/account-update-first/${id}`,
                                method:"POST",
                                data: data, 
                                withCredentials: true
                                })
        
                        dispatch({type: USER_UPDATE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: USER_UPDATE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
        }



        export const addSelectedContactsToGroup = (selectedIds, groupId) => async (dispatch) => {
                try {
                        dispatch({type: GROUP_UPDATE_REQUEST})
        
                        await axios({
                                url:`https://my.bulktext.com/groups/${groupId}/addContacts`,
                                method:"POST",
                                data: { selectedIds }, 
                                withCredentials: true
                                })
        
                        dispatch({type: GROUP_UPDATE_SUCCESS})
                } catch (error) {
                        dispatch({
                        type: GROUP_UPDATE_FAIL,
                        payload:
                        error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                        })
                }
              };
      