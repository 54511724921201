import { LIST_CALENDAR } from '../actions/types';
import { RRule } from 'rrule'


export default function (state = [], action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case LIST_CALENDAR:


            
            const newState = []
            action.payload.forEach((event) => {
                
                if(event.repeatCampaign === "Hourly") {
                    
                    if(event.repeatCampaignEnd === "After") {
                        const totalSessions = event.repeatCampaignEndSessions
                  
                        let n = 0
                    
                       
                       event.repeatCampaignHourCycles.sort(function(a, b) {
                        return (a.trigger_time < b.trigger_time) ? -1 : ((a.trigger_time > b.trigger_time) ? 1 : 0);
                    });
                
                        while (n <= totalSessions) {
                           
            
                            event.repeatCampaignHourCycles.forEach((el) => {
                             
                                    n++
                        
                                if(n <= totalSessions - 1) {
                                   
                                    // if newState has this object, icrement rrule count, else add new rrule
                                    if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                       newState.forEach((els) => {
                                            if(els.uid === el.uid) {
                                                els.rrule.count = els.rrule.count + 1
                                            }
                                        })
                                    } else {
                                        // remove trigger times offset, make slice it to same day as start, then compare
                                     
                                           newState.push({...event, 
                                               rrule: {
                                                   freq: "hourly", 
                                                   interval: 24, 
                                                   dtstart: el.trigger_time,
                                                   count: 1
                                               }, 
                                                   duration: "00:05", 
                                                   uid: el.uid})


                                        
                                    
                                    }
                                   
                                  
                        
                                }
                            })
                         
                        }

                       

                    }



                    if(event.repeatCampaignEnd === "OnDate") {
                        //still looping over events array
                        const sD = new Date(event.startDate)

                        event.repeatCampaignHourCycles.sort(function(a, b) {
                            return (a.trigger_time < b.trigger_time) ? -1 : ((a.trigger_time > b.trigger_time) ? 1 : 0);
                        });
                        
                        event.repeatCampaignHourCycles.forEach((el) => {
                            // we want to create a whole new event with rrule count 
                            
                           
                                // if newState doesnt have this object then add it, else update the rrule count
                                if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                   
                                } else {
                                    newState.push({...event, 
                                        rrule: {
                                            freq: "hourly", 
                                            interval: 24, 
                                            dtstart: el.trigger_time,
                                            until: new Date(event.endDate) 
                                        }, duration: "00:05", uid: el.uid})
                                }
                               
                              
                    
                            
                        })

                    }



                    if(event.repeatCampaignEnd === "Never") {

                        const sD = new Date(event.startDate)

                        
                        
                        event.repeatCampaignHourCycles.forEach((el) => {
                            // we want to create a whole new event with rrule count 
                        
                           
                                // if newState doesnt have this object then add it, else update the rrule count
                                if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                   
                                } else {
                                    newState.push({...event, 
                                        rrule: {
                                            freq: "hourly", 
                                            interval: 24, 
                                            dtstart: el.trigger_time 
                                        }, 
                                            duration: "00:05", uid: el.uid})
                                }
                               
                              
                    
                            
                        })

                    }




                }












//-----------------------________________________________DAILY REPEAT-------------------------____________________________------___--_--__-__-_--_-_--_-_-_


// still looping over action.payload

                if(event.repeatCampaign === "Daily") {


                    if(event.repeatCampaignEnd === "After") {

                        const totalSessions = event.repeatCampaignEndSessions
                        let n = 0

                        event.repeatCampaignDayTimes.sort(function(a, b) {
                            return (a.trigger_time < b.trigger_time) ? -1 : ((a.trigger_time > b.trigger_time) ? 1 : 0);
                        });
                        
                        while (n <= totalSessions) {
                           

                            event.repeatCampaignDayTimes.forEach((el) => {
                               
                               
                                    n++
                                
                        
                                // 1 less than or equal to 5
                                if(n <= totalSessions - 1) {
                               
                                    if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                       newState.forEach((els) => {
                                            if(els.uid === el.uid) {
                                                els.rrule.count = els.rrule.count + 1
                                            }
                                        })
                                    } else {
                                       
                                        
                                        newState.push({...event, 
                                            rrule: {
                                                freq: "daily", 
                                                interval: event.repeatCampaignDayCount, 
                                                dtstart: el.trigger_time,
                                                count: 1}, 
                                                uid: el.uid})
                                    }
                                }
                            })                         
                        }
                    }

                    if(event.repeatCampaignEnd === "OnDate") {
                        //still looping over events array
                        const sD = new Date(event.startDate)

                        event.repeatCampaignDayTimes.sort(function(a, b) {
                            return (a.trigger_time < b.trigger_time) ? -1 : ((a.trigger_time > b.trigger_time) ? 1 : 0);
                        });
                        
                        event.repeatCampaignDayTimes.forEach((el) => {
                            // we want to create a whole new event with rrule count 
                            
                                // if newState doesnt have this object then add it, else update the rrule count
                                if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                   
                                } else {
                                    

                                    newState.push({...event, 
                                        rrule: {
                                            freq: "daily", 
                                            interval: event.repeatCampaignDayCount, 
                                            dtstart: el.trigger_time, 
                                            until: new Date(event.endDate) 
                                        }, 
                                        uid: el.uid})
                                }  
                        })
                    }



                    if(event.repeatCampaignEnd === "Never") {

                        const sD = new Date(event.startDate)
                        

                        event.repeatCampaignDayTimes.forEach((el) => {
                            
                      
                            console.log("el.trigger_time", el.trigger_time)
                                if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                                   
                                } else {

                                    

                                    newState.push({...event, 
                                        rrule: {
                                            freq: "daily", 
                                            interval: event.repeatCampaignDayCount, 
                                            dtstart: el.trigger_time
                                        }, 
                                            uid: el.uid})
                                }    
                        })
                    }
                }


















//-----------------------------------------------------------WEEKLY REPEAT--------------------------------________-------_______----____---___---___--__--__-_-_-_

        if(event.repeatCampaign === "Weekly") {


            if(event.repeatCampaignEnd === "After") {
            
                const totalSessions = event.repeatCampaignEndSessions

                const sD = new Date(event.startDate)


                // GET TIMES FROM CUSTOMERS CHOSEN WEEKDAYS
                            const dayTimes = []
                            for (let [key, value] of Object.entries(event.repeatCampaignWeekdayTimes)) {
                                if(event.repeatCampaignWeekdays[key] === true) {
                                    dayTimes.push({[key]: value})
                                }
                            }

                let n = 0
                const newArr = []
                
                // CONVERT DATA STRUCTURE ----------
                            dayTimes.forEach((days) => {
                                for( let [key, value] of Object.entries(days)) {
                                    value.forEach((el) => {
                                        newArr.push({[key]: el})
                                    })
                                
                                }
                            })

                // SORT DATES FROM OLDEST TO NEWEST---------
                newArr.sort(function(a, b) {
                       
                    return (Object.values(a)[0].trigger_time < Object.values(b)[0].trigger_time) ? -1 : ((Object.values(a)[0].trigger_time > Object.values(b)[0].trigger_time) ? 1 : 0);
                });
              
                while (n <= totalSessions) {
                    
                     newArr.forEach((el) => {
                        
                        for (let [key, value] of Object.entries(el)) {
                        
                                n++
                               
                                if(n <= totalSessions - 1) {
                                 
                                
                                    if(newState.filter((els) => { return els.uid === value.uid})[0]) {
                                       newState.forEach((els) => {
                                            if(els.uid === value.uid) {
                                               els.rrule.count = els.rrule.count + 1
                                            }
                                        })
                                    } else {


                                      let tz_date_string = new Date(value.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                      let date_tz = new Date(tz_date_string);
                                      const tzDate = date_tz.getDate()
                                        
                                     
                                 
                                        newState.push({...event,
                                            rrule: {
                                                freq: "weekly",
                                                interval: event.repeatCampaignWeekCount,
                                                dtstart: value.trigger_time,
                                                byweekday: 0,
                                                byweekday: key === "monday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 0 + 1 : 0 :
                                                key === "tuesday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 1 + 1 : 1 :
                                                key === "wednesday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 2 + 1 : 2 :
                                                key === "thursday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 3 + 1 : 3 : 
                                                key === "friday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 4 + 1 : 4 : 
                                                key === "saturday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 5 + 1 : 5 :
                                                key === "sunday" ? tzDate !== new Date(value.trigger_time).getUTCDate() ? 0 : 6 : 6,
                                                count: 1
                                            }, 
                                            uid: value.uid})
                                    }
                                  
                                }
                            
                            
                        }
                    })
                }
               
            }





            if(event.repeatCampaignEnd === "OnDate") {

                const sD = new Date(event.startDate)
                
                //still looping over events array
                const dayTimes = []
            for (let [key, value] of Object.entries(event.repeatCampaignWeekdayTimes)) {
               
                if(event.repeatCampaignWeekdays[key] === true) {
                    dayTimes.push({[key]: value})
                }
            }




          return  dayTimes.forEach((el) => {
                
                for (let [key, value] of Object.entries(el)) {
                    
                    value.forEach((time) => {
                      
                            if(newState.filter((els) => { return els.uid === time.uid})[0]) {
                           
                            
                            } else {

                                const startDateDay = sD.toLocaleString('en-US', { weekday: 'long' })


                                const dates = []
                                dates.push(sD)
                                const newTTDate = new Date(time.trigger_time)
                                dates.push(newTTDate)

                              let tz_date_string = new Date(time.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                      let date_tz = new Date(tz_date_string);
                                      const tzDate = date_tz.getDate()
                             
                               newState.push({...event,
                                     rrule: {
                                         freq: "weekly",
                                         interval: event.repeatCampaignWeekCount,
                                         dtstart: time.trigger_time,
                                         byweekday: key === "monday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 0 + 1 : 0 :
                                                key === "tuesday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 1 + 1 : 1 :
                                                key === "wednesday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 2 + 1 : 2 :
                                                key === "thursday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 3 + 1 : 3 : 
                                                key === "friday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 4 + 1 : 4 : 
                                                key === "saturday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 5 + 1 : 5 :
                                                key === "sunday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 0 : 6 : 6,
                                         until: new Date(event.endDate)
                                     }, 
                                     uid: time.uid})
                           }
                    }) 
                }
            })
        }



            if(event.repeatCampaignEnd === "Never") {

                const sD = new Date(event.startDate)
            
                    //still looping over events array
                    const dayTimes = []
                    for (let [key, value] of Object.entries(event.repeatCampaignWeekdayTimes)) {
                       
                        if(event.repeatCampaignWeekdays[key] === true) {
                            dayTimes.push({[key]: value})
                        }
                    }
        
                    
        
        
                  return  dayTimes.forEach((el) => {
                            console.log("el for repeat week ", el)
                        for (let [key, value] of Object.entries(el)) {
                            
                            value.forEach((time) => {
                           
                                //            // if newState has this object update the rrule count, else add a new event
                                    if(newState.filter((els) => { return els.uid === time.uid})[0]) {
                                    
                                    } else {
                                     
                                        const startDateDay = sD.toLocaleString('en-US', { weekday: 'long' })


                                        const dates = []
                                        dates.push(sD)
                                        const newTTDate = new Date(time.trigger_time)
                                        dates.push(newTTDate)
                                        const earliestInDay = dates.sort((a,b) => 
                                        a.toISOString().split("T").pop().localeCompare(
                                          b.toISOString().split("T").pop()
                                        )
                                      )[0]; 

                                      let tz_date_string = new Date(time.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                      let date_tz = new Date(tz_date_string);
                                      const tzDate = date_tz.getDate()
                                        
                                     

                                       newState.push({...event,
                                             rrule: {
                                                 freq: "weekly",
                                                 interval: event.repeatCampaignWeekCount,
                                                 dtstart: time.trigger_time,//earliestInDay.toISOString() !== sD.toISOString() && startDateDay.toLowerCase() === key ? new Date(newTTDate.setUTCDate(newTTDate.getUTCDate() + 7)).toISOString() : time.trigger_time,
                                                 byweekday: key === "monday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 0 + 1 : 0 :
                                                 key === "tuesday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 1 + 1 : 1 :
                                                 key === "wednesday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 2 + 1 : 2 :
                                                 key === "thursday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 3 + 1 : 3 : 
                                                 key === "friday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 4 + 1 : 4 : 
                                                 key === "saturday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 5 + 1 : 5 :
                                                 key === "sunday" ? tzDate !== new Date(time.trigger_time).getUTCDate() ? 0 : 6 : 6,                                                 
                                             }, 
                                             uid: time.uid})
                                   }    
                            }) 
                        }
                    })
            }
        }























//-----------------------------------------------------------MONTHLY REPEAT--------------------------------________-------_______----____---___---___--__--__-_-_-_

    if(event.repeatCampaign === "Monthly") {

        

    if(event.repeatCampaignEnd === "After") {

        const totalSessions = event.repeatCampaignEndSessions

        const sD = new Date(event.startDate)


        let n = 0

        event.repeatCampaignMonthTimes.sort(function(a, b) {
            return (a.trigger_time < b.trigger_time) ? -1 : ((a.trigger_time > b.trigger_time) ? 1 : 0);
        });
        
        while (n <= totalSessions - 1) {

            event.repeatCampaignMonthTimes.forEach((el) => {
               
                    n++
        
                if(n <= totalSessions - 1) {
                   
                    // if newState has the object, update the rrule, else create a new event
                    if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                       newState.forEach((els) => {
                            if(els.uid === el.uid) {
                                els.rrule.count = els.rrule.count + 1
                            }
                        })
                    } else {
                        if(event.repeatCampaignType === "onDay") {
                         
                            let tz_date_string = new Date(el.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                            let date_tz = new Date(tz_date_string);
                            const tzDate = date_tz.getDate()

                            newState.push({...event, rrule: {
                                freq: "monthly", 
                                bymonthday:  tzDate !== new Date(el.trigger_time).getUTCDate() ? parseInt(event.repeatCampaignMonthOnDay) + 1 : parseInt(event.repeatCampaignMonthOnDay), // add one day if trigger time falls on diff day
                                interval: 1, 
                                dtstart: el.trigger_time, 
                                count: 1
                            }, 
                                uid: el.uid})
                        }

                        if(event.repeatCampaignType === "onThe")
                        {
                            const daysArr = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                            let rruleDay

                            let tz_date_string = new Date(el.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                      let date_tz = new Date(tz_date_string);
                                      const tzDate = date_tz.getDate()



                            if (tzDate !== new Date(el.trigger_time).getUTCDate() && daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) === 6) {
                                rruleDay = daysArr[0]
                            } else if (tzDate !== new Date(el.trigger_time).getUTCDate()) {
                                rruleDay = daysArr[daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) + 1]
                            } else {
                                rruleDay = event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()
                            }
                           
                            const rruleNth = event.repeatCampaignMonthOnThe.first_part === "First" ? 1 : event.repeatCampaignMonthOnThe.first_part === "Second" ? 2 : event.repeatCampaignMonthOnThe.first_part === "Third" ? 3 : event.repeatCampaignMonthOnThe.first_part === "Fourth" ? 4 : event.repeatCampaignMonthOnThe.first_part === "Last" ? -1 : 1
                         
                            newState.push({...event,
                                rrule: {
                                    freq: "monthly", 
                                    byweekday: [RRule[rruleDay].nth(+rruleNth)], // [RRule[MON].nth(+2)] second monday
                                    interval: 1, 
                                    dtstart: el.trigger_time, 
                                    count: 1}, 
                                    uid: el.uid
                            })
                        }
                    }
                }
            })
        }
    }






    if(event.repeatCampaignEnd === "OnDate") {
        //still looping over events array
        event.repeatCampaignMonthTimes.forEach((el) => {
            // we want to create a whole new event with rrule count 
           
                // if newState doesnt have this object then add it, else update the rrule count
                if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                   
                } else {
                    if(event.repeatCampaignType === "onDay") {

                        newState.push({...event, 
                            rrule: {
                                freq: "monthly", 
                                bymonthday: new Date(el.trigger_time).getDate() !== new Date(el.trigger_time).getUTCDate() ? parseInt(event.repeatCampaignMonthOnDay) + 1 : parseInt(event.repeatCampaignMonthOnDay),
                                interval: 1, 
                                dtstart: el.trigger_time, 
                                until: new Date(event.endDate)
                            }, uid: el.uid})
                    }

                    if(event.repeatCampaignType === "onThe")
                    {
                        const daysArr = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                        let rruleDay
                        if (new Date(el.trigger_time).getDate() !== new Date(el.trigger_time).getUTCDate() && daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) === 6) {
                            rruleDay = daysArr[0]
                        } else if (new Date(el.trigger_time).getDate() !== new Date(el.trigger_time).getUTCDate()) {
                            rruleDay = daysArr[daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) + 1]
                        } else {
                            rruleDay = event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()
                        }
                       
                        const rruleNth = event.repeatCampaignMonthOnThe.first_part === "First" ? 1 : event.repeatCampaignMonthOnThe.first_part === "Second" ? 2 : event.repeatCampaignMonthOnThe.first_part === "Third" ? 3 : event.repeatCampaignMonthOnThe.first_part === "Fourth" ? 4 : event.repeatCampaignMonthOnThe.first_part === "Last" ? -1 : 1
                     
                        newState.push({...event,
                            rrule: {
                                freq: "monthly", 
                                byweekday: [RRule[rruleDay].nth(+rruleNth)], 
                                interval: 1, 
                                dtstart: el.trigger_time, 
                                until: new Date(event.endDate)}, 
                                uid: el.uid
                        })
                    }  
                }
        })
    }





        if(event.repeatCampaignEnd === "Never") {
            //still looping over events array
            event.repeatCampaignMonthTimes.forEach((el) => {
                // we want to create a whole new event with rrule count 
            
                    // if newState doesnt have this object then add it, else update the rrule count
                    if(newState.filter((els) => { return els.uid === el.uid})[0]) {
                    
                    } else {

                        if(event.repeatCampaignType === "onDay") {



                            let tz_date_string = new Date(el.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                let date_tz = new Date(tz_date_string);
                                const tzDate = date_tz.getDate()

                            newState.push({...event, rrule: {
                                freq: "monthly", 
                                bymonthday: tzDate !== new Date(el.trigger_time).getUTCDate() ? parseInt(event.repeatCampaignMonthOnDay) + 1 : parseInt(event.repeatCampaignMonthOnDay), 
                                interval: 1, 
                                dtstart: el.trigger_time
                            }, uid: el.uid})
                        }








                        if(event.repeatCampaignType === "onThe")
                        {

                            let tz_date_string = new Date(el.trigger_time).toLocaleString("en-US", { timeZone: event.timezone });
                                      let date_tz = new Date(tz_date_string);
                                      const tzDate = date_tz.getDate()
                            const daysArr = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                            let rruleDay
                            if (tzDate !== new Date(el.trigger_time).getUTCDate() && daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) === 6) {
                                rruleDay = daysArr[0]
                            } else if (tzDate !== new Date(el.trigger_time).getUTCDate()) {
                                rruleDay = daysArr[daysArr.indexOf(event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()) + 1]
                            } else {
                                rruleDay = event.repeatCampaignMonthOnThe.day.slice(0,2).toUpperCase()
                            }
                        



                            const rruleNth = event.repeatCampaignMonthOnThe.first_part === "First" ? 1 : event.repeatCampaignMonthOnThe.first_part === "Second" ? 2 : event.repeatCampaignMonthOnThe.first_part === "Third" ? 3 : event.repeatCampaignMonthOnThe.first_part === "Fourth" ? 4 : event.repeatCampaignMonthOnThe.first_part === "Last" ? -1 : 1
                        
                            newState.push({...event,
                                rrule: {
                                    freq: "monthly", 
                                    byweekday: [RRule[rruleDay].nth(+rruleNth)], // ADD ONE DAY IF DAYS DONT MATCH
                                    interval: 1,
                                    dtstart: el.trigger_time}, 
                                    duration: '00:15',
                                    uid: el.uid
                            })
                        }

                    }
            })

        }
    }





    })
    
  
   //const changeStartDateColor = action.payload.map((cycle) => { if(!cycle.rrule) { return {...cycle, className: cycle.className.slice(0,2).concat(cycle.className.slice(7, cycle.className.length))}} else { return cycle}})
   

            return action.payload.concat(newState).filter((el) => { return el.isActive === "active"}).map((ev) => { return {...ev}})
            
            default:
            return state;
    }
}