const GET_PROFILE_INFO = 'get_profile_info';

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    heading: '',
    intro: ''
}
export default function (state = initialState, action) {
    
    switch (action.type) { // when we logout, this action.payload is an empty string so lets do || false
        case GET_PROFILE_INFO:
            return action.payload
            default:
            return state;
    }
}