import React from 'react';
import { Col, Row } from 'reactstrap';
import { version } from '../../config';
import useWindowDeminsions from '../../hooks/useWindowDimensions';
import { getPageName } from '../../helpers/utils';

const Footer = () => {
  const windowWidth = useWindowDeminsions()
  const isChat = getPageName("chat")

  return (
    <div>
      { windowWidth.width < 400 && isChat ? (

        <footer className="pt-3"></footer>
      ) : (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto" l>
        <p className="mb-0 text-600">
          Thank you for using BulkText <span className="d-none d-sm-inline-block">| </span>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy; <a href="https://www.bulktext.com">BulkText</a>
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>
  </footer>



      )}
    </div>
)};

export default Footer;
