import { CSVUPLOAD_CREATE_REQUEST, CSVUPLOAD_CREATE_SUCCESS, CSVUPLOAD_CREATE_FAIL } from '../actions/types';

export default function csvUploadCreateReducer(state = [], action) {
    switch(action.type) {
        case CSVUPLOAD_CREATE_REQUEST:
            return { loading: 99 }
        case CSVUPLOAD_CREATE_SUCCESS:
            return { loading: 100, success: true}
        case CSVUPLOAD_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}