import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters,
} from 'react-table';
import { Input } from 'reactstrap'
import { useSticky } from 'react-table-sticky'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SELECTED_CONTACT_ROWS } from '../../../actions/types';
import { listContactsTotal } from '../../../actions';



const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);


    return (
      
      <Form.Check
        type="checkbox"
        className="form-check fs-1 mb-0 d-flex align-items-center p-3 pb-1 "
        
       // its registering form-check, just not registering the form-check variable for opacity and other styles?
      >
        <Form.Check.Input type="checkbox" className="w-50 h-50" ref={resolvedRef} {...rest} />
      </Form.Check>
     
    );
  }
);


const AdvanceContactsTableWrapper = ({
  children,
  columns,
  fetchData,
  sortable,
  selection,
  selectionColumnWidth,
  selectionCallback,
  pageCount,
  data
}) => {

    const [loading, setLoading] = useState(false);
  
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    
    const count = preFilteredRows?.length
  
    return (
      <Input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      
    }),
    []
  )




  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    selectedFlatRows,
    flatRows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { selectedRowIds, globalFilter, filters },
    setGlobalFilter
  } = useTable (
    {
      columns,
      data,
      defaultColumn,
      disableSortBy: !sortable,
     // initialState: { pageIndex: 0, pageSize: 10 },
     manualPagination: true, // Tell the usePagination
     manualSortBy: true,
     autoResetPage: false,
     autoResetSelectedRows: false,
     getRowId: (row) => row.key
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
    hooks => {
      if (selection) {
        
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
               return (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> //getToggleAllPageRowsSelectedProps was getToggleAllRowsSelectedProps
            )},
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth,
                
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                {
                  row?.original?.title === "unsubscribers" ? "" : <div>

                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                }
              </div>
            )
          },
          
          ...columns
        ]);
      }
    }
  );

  const dispatch = useDispatch()
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  const pageIndex = useSelector((state => state.paginationRedux.pageIndex))
  const pageSize = useSelector((state) => state.paginationRedux.pageSize)
  const contactCreate = useSelector((state) => state.createContact)
  const { success:successCreate } = contactCreate

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success:successDelete } = contactDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success:successBlockedCreate } = blockedContactCreate

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success: successUpdate } = contactUpdate

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successUpdateGroup } = groupUpdate
 

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize })
    dispatch(listContactsTotal())
  }, [pageIndex, pageSize, successCreate, successDelete, successBlockedCreate, successUpdate, successUpdateGroup])





  useEffect(() => {
    dispatch({type: UPDATE_SELECTED_CONTACT_ROWS, payload: {selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows}})
  }, [selectedRowIds])



    useEffect(() => {
      selectionCallback(selectedFlatRows)
    }, [reduxSelectedRows])




  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            data,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            selectedFlatRows,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  return (
    <>{recursiveMap(children)}</>
  );
};

export default AdvanceContactsTableWrapper;