import React, { useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Card, CardBody, Modal, ModalBody, ModalHeader, Label } from 'reactstrap';
import ContactForm from './ContactForm';


const SingleOrListForm = ({fromCompose, showSingleOrListModal, setShowSingleOrListModal}) => {
  
  // State
  const [showContactModal, setShowContactModal] = useState(false);
  
  // Handler
  
  const toggle = () => { setShowContactModal(!showContactModal)}

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return ( 
  <>
   
      
    <ButtonGroup className="btn-group-toggle btn-block">
          <Button color="theme-default" onClick={() => {setShowContactModal(!showContactModal)}} className={classNames('custom-radio-success')}>
            <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="user-plus" transform="grow-7" className="mt-3 fs-4" />
            <h5 className="mt-2" >Create a new contact</h5>
            <h6 className="mb-3">Add contacts individually</h6>
            </Label>
          </Button>
          <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
          <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>Add New Contact</ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                                <ContactForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} showSingleOrListModal={showSingleOrListModal} setShowSingleOrListModal={setShowSingleOrListModal} fromCompose={fromCompose}/>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
          <Button color="theme-dark" a href="/pages/contacts-import" className={classNames('custom-radio-success')}>
            <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="file-upload" transform="grow-7" className="mt-3 fs-4" />
            <h5 className="mt-2" >Import from a CSV file</h5>
            <h6 className="mb-3">Import your Contacts</h6>
            </Label>
          </Button>
        </ButtonGroup>
    
   
  
  </>
 
    
  );
};

SingleOrListForm.propTypes = {
  
  hasLabel: PropTypes.bool
};

SingleOrListForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default SingleOrListForm;