import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import { SocketContext, socket } from '../context/Socket';
import { listGroups } from '../actions/index';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const dispatch = useDispatch()
  const isKanban = getPageName('kanban');

  useEffect(() => {
    // think about dispatching all contacts, groups calendar etc... from this point
    dispatch(listGroups()) // list groups is neede or else campaignPreview message shows undefined if campaign includes a group instead of contact
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);




  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <DashboardRoutes />
          </Switch>
          {!isKanban && <Footer />}
        </div>
        <SidePanelModal path={location.pathname} />
    </div>
  );
};



//  return (
//    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
//      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
//      <SocketContext.Provider value={socket}>
//        <div className="content">
//          <NavbarTop />
//     
//          <Switch>
//            <Route path="/" exact component={Dashboard} />
//            <DashboardRoutes />
//          </Switch>
//          {!isKanban && <Footer />}
//        </div>
//        <SidePanelModal path={location.pathname} />
//      </SocketContext.Provider>
//    </div>
//  );
//};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
