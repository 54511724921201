import { LIST_RESPONDER_MESSAGES_REQUEST, LIST_RESPONDER_MESSAGES_SUCCESS, LIST_RESPONDER_MESSAGES_FAIL} from '../actions/types';

export default function listResponderMessagesCreateReducer(state = {}, action) {
    switch(action.type) {
        case LIST_RESPONDER_MESSAGES_REQUEST:
            return { loading: true }
        case LIST_RESPONDER_MESSAGES_SUCCESS:
            return { loading: false, success: true}
        case LIST_RESPONDER_MESSAGES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}