import React, { Fragment, useEffect } from 'react';
import { 
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import Lottie from "lottie-react";
import animationData from '../auth/wizard/lottie/calendarAdd.json';
import { CALENDAR_CREATE_RESET } from '../../actions/types';



const SuccessAdd = ({ setBulkMessage, formObj, setFormObj, setShowTimeModal, setShowSuccessModal}) => {

 const dispatch= useDispatch()

  const emptyData = () => {
    setBulkMessage({groups: "", contacts: "", groupNames: [], messageContents: "", bulkNumber: "" })
    setFormObj({})
    //dispatch({type: RESET_BULK_TEXT_MESSAGE_CONTENT})
  };

  useEffect(() => {
    emptyData()
    dispatch({type: CALENDAR_CREATE_RESET})
  }, [])


  return (
    <div>
      <Col>
       <Row>
         <Card className="w-100">
           <CardBody>
                <Fragment>
                    <div className="wizard-lottie-wrapper">
                        <div className="wizard-lottie mx-auto">
                          <Lottie animationData={animationData} loop={1} onLoopComplete={() => { return setShowTimeModal(false), setShowSuccessModal(false) }} />
                        </div>
                    </div>
                    <h4 className="mb-1 mt-3 text-center">Success</h4>
                    <p className="fs-0 text-center">Your messages are scheduled</p>
                    
                </Fragment>   
      </CardBody>
        </Card>
       </Row>
      </Col>
      

      </div>
  ); 
};

export default SuccessAdd;