import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Card,
  CardBody,
  Col,
  Media,
  Row
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import Loader from '../common/Loader';
import Moment from 'react-moment';
import AdvanceContactsTable from '../common/contactsTable/AdvanceContactsTable';
import AdvanceContactsTableFooter from '../common/contactsTable/AdvanceContactsTableFooter';
import AdvanceContactsTableSearchBox from '../common/contactsTable/AdvanceContactsTableSearchBox';
import AdvanceContactsTableWrapper from '../common/contactsTable/AdvanceContactsTableWrapper';
import {listContacts, listContactsTotal, addContactsToGroup} from '../../actions/index';
import { RESET_SELECTED_CONTACT_ROWS } from '../../actions/types';

const NewAddContactsToGroup = ({GroupId, showUnsubUserModal, setShowUnsubUserModal, showExistingUserModal, setShowExistingUserModal, chooseImportModal, setChooseImportModal}) => {

  
//--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch(); 
  
  const contacts = useSelector((state) => state.contacts)
  const contactsTotal = useSelector((state) => state.contactsTotal)
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  const [showSearch, setShowSearch] = useState(false)
  const pageSize = useSelector(state => state.paginationRedux.pageSize);
  
  const contactDelete = useSelector((state) => state.deleteContact)
  const { success:successDelete } = contactDelete

  const [isSelected, setIsSelected] = useState(false);
 
  const selectionCallback = useCallback((selectedFlatRows) => { return  setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id}))}, 
  [setIsSelected]);

  useEffect(() => {
    dispatch({type: RESET_SELECTED_CONTACT_ROWS })
  }, [])

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, {avatar}) => {
    const {firstName} = rowData.row.original
    return (
        <Media tag={Flex} align="center">
          <Avatar name={firstName} {...avatar} />
          <Media body className="ml-2">
            <h5 className="mb-0 fs--1">{firstName}</h5>
          </Media>
        </Media>
    );
  };
  
  const lastNameFormatter = (rowData) => {
    const { lastName, _id } = rowData.row.original
    return (
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{lastName}</h5>
          </Media>
    );
  };
  
  const phoneFormatter = (rowData) => {
      const {_id, phone_number} = rowData.row.original
    return (
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{phone_number}</h5>
          </Media>
    );
  };
  
  const groupFormatter = (rowData) => {
      const {group} = rowData.row.original
    return (
        <div className="groups-cell">
        {group.map((groups, index) => 
        <div key={index}>
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{groups?.title}</h5>          
          </Media>
          </div>
          )}
      </div>
    );
  };

  const createdFormatter = (rowData) => {
    const {created} = rowData.row.original
    
  return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={created}/></h5>
        </Media>
      </Media>
  )};

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    
    {
        accessor: 'firstName',
        Header: 'First Name',
        Cell: firstNameFormatter
    },
    {
        accessor: 'lastName',
        Header: 'Last Name',
        Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
    }
  },
  {
      accessor: 'group',
      Header: 'Group',
     
      Cell: groupFormatter
  },
    {
        accessor: 'phone_number',
        Header: 'Phone',
        Cell: phoneFormatter
    },
    {
        accessor: 'phone_type',
        Header: 'Type',
    },
    {
        accessor: 'created',
        Header: 'Created',
        Cell: createdFormatter
    }
  ];
  
  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------


  useEffect(() => {
   // dispatch(listContacts())
  },[successDelete])

  useEffect(() => {
    dispatch(listContactsTotal())
  },[])


  const fetchData = useCallback(({ pageSize, pageIndex }) => {

    //   setLoading(true);
       dispatch(listContacts("", pageIndex + 1, pageSize));

//    setLoading(false)
//  }

}, [])

  return (
    
    <Card className="mb-3">
       <Fragment>
        <Row className="d-flex justify-content-between my-1">
          <Col xs={12} sm={6}>
            <ButtonIcon 
            icon="plus" 
            onClick={() => { 
              console.log("redux Selected Rows", reduxSelectedRows)
              const contactsToMoveIds = reduxSelectedRows.map((el) => { return el.original._id})
              return dispatch(addContactsToGroup(GroupId, contactsToMoveIds)),
               showExistingUserModal ? (setShowExistingUserModal(!showExistingUserModal), setChooseImportModal(!chooseImportModal)) : 
               setShowUnsubUserModal(!showUnsubUserModal) 
               }}
            transform="shrink-3 down-2"
            color="falcon-success" 
            className="mx-2"
            size="sm">
              {`Add ${reduxSelectedRows?.length} Contacts`}
            </ButtonIcon>
          </Col>
          </Row>
          <Row className="d-flex justify-content-between my-1">
          <Col xs={12} sm={6}>
            <ButtonIcon tag={Link} to="/pages/contacts-import" icon="users" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2">
              Import Contacts
            </ButtonIcon>
          </Col>
        </Row>
          </Fragment>
        
  
      <CardBody className="p-0">
      
      {!contacts ? (
          <div>
            <Loader />
            </div>
        ) : (
         
            <Fragment>
                <Card>
                <AdvanceContactsTableWrapper
                  columns={columns}
                  data={contacts}
                  fetchData={fetchData}
                  pageCount={Math.ceil(contactsTotal / pageSize)}
                  sortable
                  pagination
                  perPage={10}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                  
                >
                   <Row className="flex-start-center my-2 ml-1">
                <Col className={showSearch ? "" : "d-none"} xs="auto" sm={12}>
                  <AdvanceContactsTableSearchBox table/>
                </Col>
              </Row>
                 
                  <AdvanceContactsTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                    isSelected={reduxSelectedRows}
                    contactsTotal={contactsTotal}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceContactsTableFooter
                        rowCount={contactsTotal}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        pageCount={Math.ceil(contactsTotal / pageSize)}
                        pageSize={pageSize}
                   
                       
                    />
                  </div>
                </AdvanceContactsTableWrapper>
                
                </Card>
                </Fragment>
        
              )
            }
      </CardBody>
 
    </Card>
  
  );
};


export default NewAddContactsToGroup;