import { KEYWORD_CREATE_REQUEST, KEYWORD_CREATE_SUCCESS, KEYWORD_CREATE_FAIL, KEYWORD_CREATE_RESET } from '../actions/types';

export default function keywordCreateReducer(state = {}, action) {
    switch(action.type) {
        case KEYWORD_CREATE_REQUEST:
            return { loading: true }
        case KEYWORD_CREATE_SUCCESS:
            return { loading: false, success: true, newKeyword: action.payload}
        case KEYWORD_CREATE_FAIL:
            return { loading: false, error: action.payload }
            case KEYWORD_CREATE_RESET:
            return { }
        default:
            return state
    }
}