import { TEMPLATE_CREATE_REQUEST, TEMPLATE_CREATE_SUCCESS, TEMPLATE_CREATE_FAIL, TEMPLATE_CREATE_RESET } from '../actions/types';

export default function templateCreateReducer(state = {}, action) {
    switch(action.type) {
        case TEMPLATE_CREATE_REQUEST:
            return { loading: true }
        case TEMPLATE_CREATE_SUCCESS:
            return { loading: false, success: true}
        case TEMPLATE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case TEMPLATE_CREATE_RESET: 
            return {}
        default:
            return state
    }
}