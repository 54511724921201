import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Button,
  Card,
  CardBody,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Modal, 
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
        } from 'reactstrap';
import PropTypes from 'prop-types';
import Divider from '../common/Divider';
import Avatar from '../common/Avatar';
import { updateContact, contactById, createGroup, createBlockedContacts, unBlockContacts } from '../../actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-input-2/lib/style.css';

const ContactEditModal = ({ contact, setContact, openGroups, contactFields, contactGroups, ContactId, setChangeText }) => {

//--------------------------------------------------------State-----------------------------------------------------------------------------------------------
  const dispatch = useDispatch()
  
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [editGroups, setEditGroups] = useState(openGroups)
  const [blockContactEdit, setBlockContactEdit] = useState()
  const [group, setGroup] = useState({
    title: ''
  })
 
  const [currentGroups, setCurrentGroups] = useState({
    groups: contactGroups
  })

  const contactDetails = useSelector((state) => state.contactDetails)

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success:successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success:successBlockedDelete } = blockedContactDelete
  

//-------------------------------------------------------Handlers--------------------------------------------------------------------------------------------
   const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateContact(contact, ContactId)) // dispatching our state, then our Id
   };

   const handleGroupSubmit = (e) => {
    e.preventDefault()
      dispatch(createGroup(group))
      setShowGroupModal(false)
      setEditGroups([...editGroups, group])
   };

   const handleChange = e => {
    setGroup({...group, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    dispatch(contactById(ContactId))
    setContact({...contact, groups: [currentGroups.groups], fields: contactDetails?.contact?.data?.fields})
  }, [successBlockedCreate, successBlockedDelete]);

  useEffect(() => {

    setContact({...contact, groups: currentGroups.groups, fields: contactDetails?.contact?.data?.fields })
  }, [currentGroups, contactDetails])


 
  return ( 
  <>
   <Modal isOpen={showGroupModal} centered toggle={() => setShowGroupModal(!showGroupModal)}>
            <ModalHeader className="text-center bg-light d-flex flex-between-center border-bottom-0">
                Create a new group
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                          <CardBody className="fs--1 font-weight-normal p-4"> 
                            <Form onSubmit={handleGroupSubmit}>
                              <Label for="title">Group Name:</Label>
                                <Input value={group.title.value} onChange={handleChange} className="mb-3" name="title" id="title"/>
                                  <Button block onClick={() => {setShowGroupModal(false)}} color="primary" className="mb-3" type="submit">Save</Button>
                            </Form>
                                <Button block onClick={() => {setShowGroupModal(false)}}>Close</Button>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
                {
                  // block contact modal ----------------------------------
                }

                <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Label className="center">{contactDetails?.contact?.data?.blocked ? "You will be able to recieve phone calls and messages from this contact" : "You will not recieve phone calls or messages from people on the block list."}</Label>
                          <Divider></Divider>   
                            <Button onClick={() => { if(contactDetails?.contact?.data?.blocked) {
                              return dispatch(unBlockContacts([ContactId])), // added brackets to match type
                                   setChangeText(false),
                                   setShowBlockModal(!showBlockModal)
                                   } else { 
                                     return dispatch(createBlockedContacts({contacts: [{item: {_id: blockContactEdit._id, phone_number: blockContactEdit.phoneNumber}}]})),
                                      setChangeText(false),
                                       setShowBlockModal(!showBlockModal)
                                       }}} 
                                  block 
                                  className="text-danger" color="light">{contactDetails?.contact?.data?.blocked ? "Unblock Contact" : "Block Contact"}</Button>
                            <Button onClick={() => { setShowBlockModal(!showBlockModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
  <Card>
    <CardBody className="bg-light">

     <Row className="justify-content-center mt-2">
      <Avatar name={contactDetails?.contact?.data?.firstName}/>
     </Row>
     <Row className="justify-content-center mt-2" >
          <h2 className="mt-1 ml-3 fs-1">{contactDetails?.contact?.data?.firstName}</h2>
          <h2 className="mt-1 ml-1 fs-1">{contactDetails?.contact?.data?.lastName}</h2>
      </Row>
    <Form onSubmit={handleSubmit}>
    <div>
        <FormGroup>
          <Card className="mb-2">
            <Label for="firstName" className="ml-3 mt-2 mb-2">First name</Label>
                <Input 
                  type="text" 
                  name="firstName"
                  id="firstName"
                  className="form-control border-0" 
                  defaultValue={contactDetails?.contact?.data?.firstName}
                  onChange={(e) => {setContact({ ...contact, [e.target.name]: e.target.value})}}
                  onFocus={() => {setChangeText(true)}}
                />
          </Card>
        </FormGroup>
      </div>

      <div>
        <FormGroup>
          <Card className="mb-2">
            <Label for="lastName" className="ml-3 mt-2 mb-2">Last name</Label>
                <Input 
                  type="text"
                  name="lastName"
                  id="lastName" 
                  className="form-control border-0" 
                  defaultValue={contactDetails?.contact?.data?.lastName}
                  onChange={(e) => {setContact({ ...contact, [e.target.name]: e.target.value})}}
                  onFocus={() => {setChangeText(true)}}
                />
          </Card>
        </FormGroup>
      </div>

      <div>
        <FormGroup>
          <Card className="mb-2">
            <Label for="notes" className="ml-3 mt-2 mb-2">Notes</Label>
                <Input 
                  type="textarea"
                  id="notes"
                  name="notes" 
                  className="form-control border-0" 
                  defaultValue={contactDetails?.contact?.data?.notes}
                  onChange={(e) => {setContact({ ...contact, [e.target.name]: e.target.value})}}
                  onFocus={() => {setChangeText(true)}}
                />
          </Card>
        </FormGroup>
      </div>

    
      <div>
        <FormGroup>
          <Label>Current Groups</Label>
          <Row>
          {currentGroups.groups.map((groups, index) =>
          <Button key={index} onClick={() => {
            console.log("keep here for render")
            }} 
            color="light" 
            className="rounded-capsule fs--1 ml-1 mb-0" >{groups.title + " "} 
          <Button 
            onClick={(e) => { 
              return e.stopPropagation(), 
              setChangeText(true),
              setCurrentGroups({groups: currentGroups.groups.filter((cg) => { return cg.title != groups.title}) }), 
              setContact({...contact, groups: currentGroups.groups }), 
              setEditGroups([...editGroups, groups])
              }} 
            className="rounded-capsule btn btn-sm">
              <FontAwesomeIcon icon="times"/></Button>
          </Button>
          )}


          <div className="dropdown">
              <UncontrolledDropdown>
                <DropdownToggle color="link" size="sm" className="pl-0">
                  <Button onClick={(e) => {
                    return e.preventDefault(),
                     setChangeText(true)
                     }} 
                     color="success" 
                     className="rounded-capsule ml-2 btn-sm">
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </DropdownToggle>
                    <DropdownMenu className="py-0" style={{ minWidth: '15rem' }}>
                      <div className="bg-white py-3 rounded-soft">
                      <DropdownItem onClick={() => {setShowGroupModal(!showGroupModal)}} tag="div" className="text-base px-3 py-2">
                          <span className="d-flex fs--1 text-black">
                            <span className="font-weight-semi-bold">+ create new</span>
                          </span>
                        </DropdownItem>
                      {editGroups.map((group, index) => (
                        <div key={index}>
                        <DropdownItem 
                        onClick={(e) => { 
                          return setContact({...contact, groups: [ ...currentGroups.groups, group ]}),
                           setEditGroups(editGroups.filter(( eg) => { return eg.title != group.title})),
                            setCurrentGroups({...currentGroups, groups: [ ...currentGroups.groups, group ]})
                            }} 
                            tag="div" 
                            className="text-base px-3 py-2">
                          <span className="d-flex fs--1 text-black">
                            <span className="font-weight-semi-bold">{group?.title}</span>
                          </span>
                        </DropdownItem>
                        <DropdownItem divider className="my-0" />
                        </div>
                        ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
          </div>
          </Row>
        </FormGroup>
      </div>
      
      
      <div>
        <FormGroup>
          <Card className="mb-2">
            <Label for="phone_number" className="ml-3 mt-2 mb-2">Phone (number must have country code)</Label>
                <Input 
                  type="text" 
                  className="form-control border-0"
                  id="phone_number"
                  name="phone_number" 
                  defaultValue={contactDetails?.contact?.data?.phone_number}
                  onChange={(e) => {setContact({...contact, [e.target.name]: e.target.value})}}
                  onFocus={() => {setChangeText(true)}}
                />
          </Card>
        </FormGroup>
      </div>

      {contactFields.map((field, index) => { return (
        <div key={index}>
          <FormGroup>
            <Card className="mb-2">
              <Label for={field?.title} className="ml-3 mt-2 mb-2">{field?.title}</Label>
                  <Input 
                    type="text"
                    id={field?.title}
                    name={field?.title} 
                    className="form-control border-0" 
                    defaultValue={field?.body}
                    onChange={(e) => {setContact({...contact, fields: {...contact.fields, [e.target.name]: e.target.value}})}}
                    onFocus={() => {setChangeText(true)}}
                    />
            </Card>
          </FormGroup>
        </div>
      )})}

      <div>
        <FormGroup>
          <Card className="bg-transparent mb-2">
            <Button onClick={() => { return setShowBlockModal(!showBlockModal), setBlockContactEdit({_id: contactDetails?.contact?.data?._id, phoneNumber: contactDetails?.contact?.data?.phone_number})}} className="btn btn-outline-danger text-white bg-danger border-2x rounded-pill btn-lg mt-4 fs-0 py-2" >
              {contactDetails?.contact?.data?.blocked ? "Unblock this caller" : "Block this caller"}
              <FontAwesomeIcon icon="ban" transform="shrink-6 down-1 right-5" />
            </Button>
          </Card>
        </FormGroup>
      </div>
      </Form>
    </CardBody>
  </Card>
  
  </>
 
    
  );
};

ContactEditModal.propTypes = {
  
  hasLabel: PropTypes.bool
};

ContactEditModal.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default ContactEditModal;