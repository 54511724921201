import { GROUP_MOVE_COPY_REQUEST, GROUP_MOVE_COPY_SUCCESS, GROUP_MOVE_COPY_FAIL, GROUP_MOVE_COPY_RESET } from '../actions/types';

export default function groupMoveCopyReducer(state = {}, action) {
    switch(action.type) {
        case GROUP_MOVE_COPY_REQUEST:
            return { loading: true }
        case GROUP_MOVE_COPY_SUCCESS:
            return { loading: false, success: true}
        case GROUP_MOVE_COPY_FAIL:
            return { loading: false, error: action.payload }
        case GROUP_MOVE_COPY_RESET:
            return {}
        default:
            return state
    }
}