import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import Loader from '../common/Loader';
import Divider from '../common/Divider';
import Moment from 'react-moment';
import AdvanceContactsTable from '../common/contactsTable/AdvanceContactsTable';
import SingleOrListForm from '../contacts/SingleOrListForm';
import AdvanceContactsTableFooter from '../common/contactsTable/AdvanceContactsTableFooter';
import AdvanceContactsTableSearchBox from '../common/contactsTable/AdvanceContactsTableSearchBox';
import AdvanceContactsTableWrapper from '../common/contactsTable/AdvanceContactsTableWrapper';
import ContactEditModal from '../contacts/ContactEditModal';
import { listContacts,listContactsTotal, deleteMultipleContacts, updateContact, createBlockedContacts, unsubscribeContacts } from '../../actions/index';
import { ADD_TO_FIELD_HTML, CONTACT_CREATE_RESET, REMOVE_SELECTED_CONTACT_ROWS, RESET_SELECTED_CONTACT_ROWS} from '../../actions/types';

const AddContactToTable = ({ contactState, setContactState, contactModal, setContactModal, toField, setToField, fromCompose, fromCalendar }) => {

  
//--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch(); 

  const contacts = useSelector((state) => state.contacts)
  const contactsTotal = useSelector((state) => state.contactsTotal)
  const groups = useSelector((state) => state.groups)
  const fields = useSelector((state) => state.fields)
  const conversations = useSelector((state) => state.conversations)
  const pageSize = useSelector(state => state.paginationRedux.pageSize);
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  
  const contactDelete = useSelector((state) => state.deleteContact)
  const { success:successDelete } = contactDelete

  const contactCreate = useSelector((state) => state.createContact)
  const { success:successCreate } = contactCreate

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success:successUpdate } = contactUpdate

  const groupDelete = useSelector((state) => state.groupDelete)
  const { success:successGroupDelete } = groupDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success:successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success:successBlockedDelete } = blockedContactDelete
  
  const [showSingleOrListModal, setShowSingleOrListModal ] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState('0')
  const [tempContact, setTempContact] = useState()
  const [changeText, setChangeText] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
  const [contact, setContact] = useState({ });
  const [showContactUpdateModal, setShowContactUpdateModal] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [blockContact, setBlockContact] = useState()

  
 //compare rowIds with FlatRows to solve multi search choose contact issue
  const selectionCallback = useCallback((selectedFlatRows) => { 
    return setIsSelected(selectedFlatRows.map((fr) => {return fr.original._id})), setSelectedRows(selectedFlatRows), setRowDelete([])},
  [setIsSelected]);

  const openGroups = []
  groups.map((g) => { if(!tempContact?.group.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) }})

  const contactFields = fields.map((field) => {
    return {title: field.title, body: tempContact?.fields[field.title] }
  })

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, {avatar}) => {
    const {firstName, _id} = rowData.row.original
    return (
      <Link onClick={() => { console.log("rowData", rowData.row)}} to="#!" >
        <Media tag={Flex} align="center">
          <Avatar name={firstName} {...avatar} />
          <Media body className="ml-2">
            <h5 className="mb-0 fs--1">{firstName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };
  
  const lastNameFormatter = (rowData) => {
    const { lastName } = rowData.row.original
    return (
     
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{lastName}</h5>
          </Media>
     
    );
  };
  
  const phoneFormatter = (rowData) => {
      const {phone_number} = rowData.row.original
    return (
     
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{phone_number}</h5>
          </Media>
    
    );
  };
  
  const groupFormatter = (rowData) => {
      const {group} = rowData.row.original
    return (
        <div>
        {group.map((groups, index) => 
        <div key={index}>
       
          <Media body className="ml-2" tag={Flex} align="center">
            <h5 className="mt-2 fs--1">{groups?.title}</h5>          
          </Media>
        
          </div>
          )}
      </div>
    );
  };

  const createdFormatter = (rowData) => {
    const {created} = rowData.row.original
    
  return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={created}/></h5>
        </Media>
      </Media>
  )};
  
  const actionFormatter = (rowData) => {
    const { firstName, lastName, phone_number, _id } = rowData.row.original
    const composeContact = {firstName: firstName, lastName: lastName, phoneNumber: phone_number, _id: _id}

    return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal bg-soft-primary ">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
        <DropdownItem onClick={() => { return setTempContact(rowData.row.original), setShowContactUpdateModal(true)}}><FontAwesomeIcon icon="edit" className="fs--1" />{` Edit`}</DropdownItem>
        <DropdownItem onClick={() => { return setShowBlockModal(!showBlockModal), setBlockContact({_id: _id, phoneNumber: phone_number})}}><FontAwesomeIcon icon="ban" className="fs--1" />{` Block`}</DropdownItem>
        <DropdownItem onClick={() => { return setShowUnsubscribeModal(!showUnsubscribeModal), setBlockContact(composeContact)}}><FontAwesomeIcon icon="minus" className="fs--1" />{` Unsubscribe`}</DropdownItem>
        <DropdownItem onClick={() => { return setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)}} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete contact`}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    )
};

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    
    {
        accessor: 'firstName',
        Header: 'First Name',
        Cell: firstNameFormatter
    },
    {
        accessor: 'lastName',
        Header: 'Last Name',
        Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
    }
  },
    {
        accessor: 'phone_number',
        Header: 'Phone',
        Cell: phoneFormatter
    },
    {
        accessor: 'Group',
        Header: 'Group',
        Cell: groupFormatter
    },
    {
        accessor: 'phone_type',
        Header: 'Type',
    },
    {
        accessor: 'created',
        Header: 'Created',
        Cell: createdFormatter
    },
    {
      accessor: 'actions',
      headerProps: {
          className: 'd-flex justify-content-end',
          
      },
      Cell: actionFormatter,
      sticky: 'right',
      
  }
  ];
  
 
  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------
 

  const toggle = () => { setShowSingleOrListModal(!showSingleOrListModal)}

  const toggleSave = () => { 
    if(changeText === true) {
    return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
    setShowContactUpdateModal(!showContactUpdateModal)
    
    }

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  const addContactsToDiv = async(composeContact) => {
    let composeContactHtml
    if(composeContact) {
      composeContactHtml = (
        `<label contentEditable="false" class="p-1 font-weight-bold bg-primary ml-2 text-white rounded-capsule shadow-none fs--3">${composeContact.firstName + " " + composeContact.lastName + " "}
      <span class="badge fs--1 badge-soft-primary badge-pill ml-2">${composeContact.phoneNumber}</span>
      <span name="indy-contacts" id="contactToAdd" class="d-none">${composeContact._id}</span>
      <span class="cursor-pointer text-dark bg-soft-primary text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${composeContact._id} >x</span>
      </label>`)
    }
     const matchedContactsArray = reduxSelectedRows.map((contact) => { 
      let match 
      const findMatches = contactState?.forEach((el) =>  { return el?.id === contact?.original?._id ? match = el?.id : ""})
      if(match) {
      } else { return (
        `<label contentEditable="false" class="p-1 font-weight-bold bg-primary ml-2 text-white rounded-capsule shadow-none fs--3">${contact.original.firstName + " " + contact.original.lastName + " "}
      <span class="badge fs--1 badge-soft-primary badge-pill ml-2">${contact.original.phone_number}</span>
      <span name="indy-contacts" id="contactToAdd" class="d-none">${contact.original._id}</span>
      <span class="cursor-pointer text-dark bg-soft-primary text-bold border-0 rounded-capsule ml-2 btn-sm" name="remove-contact-btn" id=${contact.original._id} >x</span>
      </label>`)
      }
    })
    if(composeContact) {
      dispatch({type: ADD_TO_FIELD_HTML, payload: {html: toField.html + composeContactHtml.toString()}})
      setToField({...toField, html: toField.html + composeContactHtml.toString()})
    } else {
    dispatch({type: ADD_TO_FIELD_HTML, payload: {html: toField.html + matchedContactsArray.toString()}})
     setToField({...toField, html: toField.html + matchedContactsArray.toString()}) // update state with array. DO NOT update INside loop. always update outside of loop with returned array
  }
    const removeBtns = document.getElementsByName('remove-contact-btn')
    await setContactState(removeBtns) // <--- contact State holds button values, NOt entire html // nodelist

    
  }

 
  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------
  useEffect(() => {
    if(!successCreate) return;
   toast(
     <Fragment>
       <strong>Contact Created</strong>
     </Fragment>
   );
   dispatch({type: CONTACT_CREATE_RESET})
 }, [successCreate]);


  useEffect(() => {
    //dispatch(listContacts())
  },[successDelete, successCreate, successUpdate, successGroupDelete, successBlockedCreate, successBlockedDelete])

  useEffect(() => {
    dispatch(listContactsTotal())
  },[])



  const fetchData = useCallback(({ pageSize, pageIndex }) => {

    //   setLoading(true);
       dispatch(listContacts("", pageIndex + 1, pageSize));

//    setLoading(false)
//  }

}, [])

  return (
    
    <Card className="mb-5">
       <FalconCardHeader title="" light={false}>
          <Fragment>
          <ButtonGroup className="mt-2" >
          <ButtonIcon onClick={() => { return addContactsToDiv(), setContactModal(!contactModal)}} icon="plus" transform="shrink-3 down-2" color="falcon-success" size="sm">
              {`Add ${reduxSelectedRows.length} Contacts`}
            </ButtonIcon>
            <ButtonIcon onClick={(() => setShowSingleOrListModal(true))} icon="plus" transform="shrink-3" color="falcon-success" size="sm">
              New Contact
            </ButtonIcon>
                <Modal isOpen={showSingleOrListModal} centered toggle={() => setShowSingleOrListModal(!showSingleOrListModal)}>
                <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
                How would you like to Add?
                  </ModalHeader>
                    <ModalBody className="p-0">
                        <Card>
                            <CardBody className="fs--1 font-weight-normal p-4"> 
                                <SingleOrListForm showSingleOrListModal={showSingleOrListModal} setShowSingleOrListModal={setShowSingleOrListModal} fromCompose={fromCompose}/>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
                <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) } } disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
                  Delete
                </ButtonIcon>
                <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
                    <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                      <ModalBody className="p-0">
                        <Card>
                          <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Button onClick={() => { if(rowDelete?.length) { 
                              return dispatch(deleteMultipleContacts(rowDelete, conversations)), 
                              dispatch({type: REMOVE_SELECTED_CONTACT_ROWS, payload: rowDelete}),
                              setShowDeleteModal(!showDeleteModal)
                              } else { 
                              const deleteIds = reduxSelectedRows.map((el) => { return el.original._id})
                              return dispatch(deleteMultipleContacts(deleteIds, conversations)), 
                              dispatch({type: RESET_SELECTED_CONTACT_ROWS, payload: deleteIds}),
                              setShowDeleteModal(!showDeleteModal)
                              }}} 
                              block 
                              className="text-danger" 
                              color="light">Delete contacts</Button>
                            <Button onClick={() => { setShowDeleteModal(!showDeleteModal)}} block className="text-primary" color="light" >Cancel</Button>
                          </CardBody>
                        </Card>
                      </ModalBody>
                  </Modal>
            </ButtonGroup>
            <Modal size="lg" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
                <ModalHeader toggle={toggleSave} className="bg-light border-bottom-0">
                  <ButtonIcon 
                  onClick={() => { return dispatch(updateContact(contact, tempContact._id)), setShowContactUpdateModal(!showContactUpdateModal)}} 
                  disabled={!changeText} 
                  transform="shrink-3 down-2" 
                  color="falcon-primary" 
                  icon=""
                  size="md">done</ButtonIcon>
                </ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <ContactEditModal 
                            contact={contact}
                            ContactId={tempContact?._id} 
                            changeText={changeText}
                            setContact={setContact} 
                            openGroups={openGroups}
                            successCreate={successCreate} 
                            contactGroups={tempContact?.group} 
                            contactFields={contactFields}  
                            setChangeText={setChangeText} />
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Label className="center">Are you sure you want to discard changes?</Label>
                          <Divider></Divider>   
                            <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal)}} block className="text-danger" color="light">Discard Changes</Button>
                            <Button onClick={() => { setShowSaveModal(!showSaveModal)}} block className="text-primary" color="light" >Keep editing</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // Blocked Modal-----------------------------------------
              }
              <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                          <Label className="center">You will not recieve phone calls or messages from people on the block list.</Label>
                          <Divider></Divider>   
                            <Button onClick={() => { 
                                     return dispatch(createBlockedContacts({contacts: [{item: {_id: blockContact._id, phone_number: blockContact.phoneNumber}}]})), // data is in this format just to match for action, could just create a "singleBlockContact but didnt feel like it"
                                       setShowBlockModal(!showBlockModal)
                                       }} 
                                  block 
                                  className="text-danger" color="light">Block Contact</Button>
                            <Button onClick={() => { setShowBlockModal(!showBlockModal)}} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
              {
                // Unsubscribe Modal---------------------------------------
              }
              <Modal isOpen={showUnsubscribeModal} centered toggle={() => {setShowUnsubscribeModal(!showUnsubscribeModal)}}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4"> 
                        <Button onClick={() => {
                          return dispatch(unsubscribeContacts(blockContact)), 
                          setShowUnsubscribeModal(false)
                          }} 
                          block 
                          className="text-info" 
                          color="light">Unsubscribe contact</Button>
                        <Button onClick={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} block className="text-primary" color="light" >Cancel</Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
              </Modal>
          </Fragment>
        
      </FalconCardHeader>

      <CardBody className="p-0">
      
      {!contacts ? (
          <div>
            <Loader />
            </div>
        ) : (
         
            <Fragment>
                <Card>
                <AdvanceContactsTableWrapper
                  columns={columns}
                  data={contacts}
                  fetchData={fetchData}
                  pageCount={Math.ceil(contactsTotal / pageSize)}
                  sortable
                  pagination
                  perPage={10}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                  
                >
                  <Row className="flex-start-center mb-3 ml-1">
                    <Col >
                      <AdvanceContactsTableSearchBox table/>
                    </Col>
                  </Row>
                 
                  <AdvanceContactsTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                    isSelected={reduxSelectedRows}
                    contactsTotal={contactsTotal}
                  />
                  <div className="mt-3 mb-2 pr-2 pl-3 ">
                    <AdvanceContactsTableFooter
                      rowCount={contactsTotal}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                      pageCount={Math.ceil(contactsTotal / pageSize)}
                      pageSize={pageSize}
                 
                    />
                  </div>
                </AdvanceContactsTableWrapper>
                
                </Card>
                </Fragment>
        
              )
            }
      </CardBody>
    </Card>
  
  );
};


export default AddContactToTable;