import {LIST_RESPONDERS} from '../actions/types';

export default function responderReducer(state = [], action) {
  const { type, payload} = action;
  switch (type) {


    case LIST_RESPONDERS:
      return action.payload  

  
      default:
        return state;
    }
  };
