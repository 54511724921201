import {AVAIL_NUMBERS_REQUEST, AVAIL_NUMBERS_SUCCESS, AVAIL_NUMBERS_FAIL, AVAIL_NUMBERS_RESET} from '../actions/types';

export default function availNumbersReducer(state = {}, action) {
    switch(action.type) {
        case AVAIL_NUMBERS_REQUEST:
            return {loading: true};
        case AVAIL_NUMBERS_SUCCESS:
            return action.payload
        case AVAIL_NUMBERS_FAIL:
            return {loading: false, error: action.payload}
            case AVAIL_NUMBERS_RESET:
            return {}
        default:
            return state
    }
}