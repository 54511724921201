import { 
    EDIT_CHAT,
    LIST_CHATS,
    ADD_CHAT,
    DELETE_CHAT,
    CHAT_SWAP_INFO, 
    REMOVE_BLOCKED_CHAT,
    REMOVE_UNSUBSCRIBED_CHAT,
    ADD_INCOMING_CHAT_MESSAGE

 } from "../actions/types";


export default function (state = [], action) {
    const { payload } = action
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case ADD_INCOMING_CHAT_MESSAGE:
            return state.map(chat => (chat._id === payload.chatId ? {...chat, lastMessage: payload.message, read: "false"} : chat));
        case LIST_CHATS:
            return action.payload.filter((obj) => { if(obj.contact.UnsubscribeDate) { return Object.keys(obj.contact.UnsubscribeDate).length === 0} else { return obj}})

        case REMOVE_BLOCKED_CHAT:
        return state.filter((el) => { return el._id !== payload._id})

        case REMOVE_UNSUBSCRIBED_CHAT:
            return state.filter((el) => { return el._id !== payload._id})
        

        case ADD_CHAT: 
            return [payload, ...state];

        
        case EDIT_CHAT:
            return state.map(chat => (chat._id === payload._id ? payload : chat));

        case CHAT_SWAP_INFO:
            return state.map(chat => 
                (chat.phone === payload[0].phone ? 
                    { 
                    contact: chat.contact,
                    dateCreated: payload[0].date_created,
                    dateUpdated: payload[0].date_updated,
                    mute: chat.mute,
                    phone: payload[0].phone,
                    read: chat.read,
                    state: chat.state,
                    _id: payload[0]._id
                    } : chat) )
       
            
        case DELETE_CHAT: 
        return state.filter((chat) => chat._id !== payload.chat._id);

        default:
        return state;
    }
}