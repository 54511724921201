export const FETCH_USER = 'fetch_user';
export const FETCH_REG_USER = 'fetch_reg_user';
export const DELETE_USER = 'delete_user';
export const UPDATE_USER_CREDIT = 'update_user_credit';

export const GET_PROFILE_INFO = 'get_profile_info';
export const LOGOUT_USER = 'logout_user';

export const GET_TWILIO_NUMBER = 'get_twilio_number';

export const USER_UPDATE_REQUEST = 'user_update_request';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_FAIL = 'user_update_fail';
export const USER_UPDATE_RESET = 'user_update_reset';

 // contacts

export const LIST_CONTACTS = 'list_contacts';
export const LIST_CONTACTS_TOTAL = 'list_contacts_total';


export const ADD_COMPOSE_CONTACT = 'add_compose_contact';
export const LIST_BLOCKED = 'list_blocked';

export const CONTACT_CREATE_REQUEST = 'contact_create_request';
export const CONTACT_CREATE_SUCCESS = 'contact_create_success';
export const CONTACT_CREATE_FAIL = 'contact_create_fail';
export const CONTACT_CREATE_RESET = 'contact_create_reset';

export const CONTACT_DELETE_REQUEST = 'contact_delete_request';
export const CONTACT_DELETE_SUCCESS = 'contact_delete_success';
export const CONTACT_DELETE_FAIL = 'contact_delete_fail';

export const CONTACT_DETAILS_REQUEST = 'contact_details_request';
export const CONTACT_DETAILS_SUCCESS = 'contact_details_success';
export const CONTACT_DETAILS_FAIL = 'contact_details_fail';

export const CONTACT_UPDATE_REQUEST = 'contact_update_request';
export const CONTACT_UPDATE_SUCCESS = 'contact_update_success';
export const CONTACT_UPDATE_FAIL = 'contact_update_fail';

export const BLOCKED_CONTACT_CREATE_REQUEST = 'blocked_contact_create_request';
export const BLOCKED_CONTACT_CREATE_SUCCESS = 'blocked_contact_create_success';
export const BLOCKED_CONTACT_CREATE_FAIL = 'blocked_contact_create_fail';
export const BLOCKED_CONTACT_CREATE_RESET = 'blocked_contact_create_reset';

export const BLOCKED_CONTACT_DELETE_REQUEST = 'blocked_contact_delete_request';
export const BLOCKED_CONTACT_DELETE_SUCCESS = 'blocked_contact_delete_success';
export const BLOCKED_CONTACT_DELETE_FAIL = 'blocked_contact_delete_fail';
export const BLOCKED_CONTACT_DELETE_RESET = 'blocked_contact_delete_reset';



// groups
export const LIST_GROUPS = 'list_groups';
export const LIST_SHOW_GROUPS = 'list_show_groups';
export const LIST_SHOW_AND_HIDE_GROUPS = 'list_show_and_hide_groups';
export const LIST_UNSUBS = 'list_unsubs';
export const LIST_GROUPS_EXCEPT_UNSUB = "list_groups_except_unsub"
export const ADD_COMPOSE_GROUP = 'add_compose_group';
export const RESET_COMPOSE_GROUP = 'reset_compose_group';
export const GROUP_CREATE_REQUEST = 'group_create_request';
export const GROUP_CREATE_SUCCESS = 'group_create_success';
export const GROUP_CREATE_FAIL = 'group_create_fail';
export const GROUP_CREATE_RESET = 'group_create_reset';


export const GROUP_DELETE_REQUEST = 'group_delete_request';
export const GROUP_DELETE_SUCCESS = 'group_delete_success';
export const GROUP_DELETE_FAIL = 'group_delete_fail';

export const GROUP_DETAILS_REQUEST = 'group_details_request';
export const GROUP_DETAILS_SUCCESS = 'group_details_success';
export const GROUP_DETAILS_FAIL = 'group_details_fail';

export const GROUP_DETAILS_TITLE_REQUEST = 'group_details_title_request';
export const GROUP_DETAILS_TITLE_SUCCESS = 'group_details_title_success';
export const GROUP_DETAILS_TITLE_FAIL = 'group_details_title_fail';

export const GROUP_UPDATE_REQUEST = 'group_update_request';
export const GROUP_UPDATE_SUCCESS = 'group_update_success';
export const GROUP_UPDATE_FAIL = 'group_update_fail';
export const GROUP_UPDATE_RESET = 'group_update_reset';

export const GROUP_SHOW_HIDE_REQUEST = 'group_show_hide_request';
export const GROUP_SHOW_HIDE_SUCCESS = 'group_show_hide_success';
export const GROUP_SHOW_HIDE_FAIL = 'group_show_hide_fail';
export const GROUP_SHOW_HIDE_RESET = 'group_show_hide_reset';

export const GROUP_MOVE_COPY_REQUEST = 'group_move_copy_request';
export const GROUP_MOVE_COPY_SUCCESS = 'group_move_copy_success';
export const GROUP_MOVE_COPY_FAIL = 'group_move_copy_fail';
export const GROUP_MOVE_COPY_RESET = 'group_move_copy_reset';


// templates
export const LIST_TEMPLATES = 'list_templates';
export const ADD_COMPOSE_TEMPLATE = 'add_compose_template';
export const TEMPLATE_CREATE_REQUEST = 'template_create_request';
export const TEMPLATE_CREATE_SUCCESS = 'template_create_success';
export const TEMPLATE_CREATE_FAIL = 'template_create_fail';
export const TEMPLATE_CREATE_RESET = 'template_create_reset';

export const TEMPLATE_DELETE_REQUEST = 'template_delete_request';
export const TEMPLATE_DELETE_SUCCESS = 'template_delete_success';
export const TEMPLATE_DELETE_FAIL = 'template_delete_fail';

export const TEMPLATE_UPDATE_REQUEST = 'template_update_request';
export const TEMPLATE_UPDATE_SUCCESS = 'template_update_success';
export const TEMPLATE_UPDATE_FAIL = 'template_update_fail';
export const TEMPLATE_UPDATE_RESET = 'template_update_reset';

// fields
export const LIST_FIELDS = 'list_fields';
export const FIELD_CREATE_REQUEST = 'field_create_request';
export const FIELD_CREATE_SUCCESS = 'field_create_success';
export const FIELD_CREATE_FAIL = 'field_create_fail';
export const FIELD_CREATE_RESET = 'field_create_reset';


export const FIELD_DELETE_REQUEST = 'field_delete_request';
export const FIELD_DELETE_SUCCESS = 'field_delete_success';
export const FIELD_DELETE_FAIL = 'field_delete_fail';
 
export const FIELD_UPDATE_REQUEST = 'field_update_request';
export const FIELD_UPDATE_SUCCESS = 'field_update_success';
export const FIELD_UPDATE_FAIL = 'field_update_fail';
export const FIELD_UPDATE_RESET = 'field_update_reset';

export const FIELD_DETAILS_REQUEST = 'field_details_request';
export const FIELD_DETAILS_SUCCESS = 'field_details_success';
export const FIELD_DETAILS_FAIL = 'field_details_fail';

// keywords
export const LIST_KEYWORDS = 'list_keywords';

export const KEYWORD_CREATE_REQUEST = 'keyword_create_request';
export const KEYWORD_CREATE_SUCCESS = 'keyword_create_success';
export const KEYWORD_CREATE_FAIL = 'keyword_create_fail';
export const KEYWORD_CREATE_RESET = 'keyword_create_reset';

export const KEYWORD_DELETE_REQUEST = 'keyword_delete_request';
export const KEYWORD_DELETE_SUCCESS = 'keyword_delete_success';
export const KEYWORD_DELETE_FAIL = 'keyword_delete_fail';

export const KEYWORD_UPDATE_REQUEST = 'keyword_update_request';
export const KEYWORD_UPDATE_SUCCESS = 'keyword_update_success';
export const KEYWORD_UPDATE_FAIL = 'keyword_update_fail';
export const KEYWORD_UPDATE_RESET = 'keyword_update_reset';

export const KEYWORD_DETAILS_REQUEST = 'keyword_details_request';
export const KEYWORD_DETAILS_SUCCESS = 'keyword_details_success';
export const KEYWORD_DETAILS_FAIL = 'keyword_details_fail';

// flows
export const LIST_RESPONDERS = 'list_responders';
export const ADD_RESPONDER = 'add_responder';
export const RESET_RESPONDER = 'reset_responder';

export const RESPONDER_CREATE_REQUEST = 'responder_create_request';
export const RESPONDER_CREATE_SUCCESS = 'responder_create_success';
export const RESPONDER_CREATE_FAIL = 'responder_create_fail';

export const RESPONDER_DELETE_REQUEST = 'responder_delete_request';
export const RESPONDER_DELETE_SUCCESS = 'responder_delete_success';
export const RESPONDER_DELETE_FAIL = 'responder_delete_fail';

export const RESPONDER_UPDATE_REQUEST = 'responder_update_request';
export const RESPONDER_UPDATE_SUCCESS = 'responder_update_success';
export const RESPONDER_UPDATE_FAIL = 'responder_update_fail';
export const RESPONDER_UPDATE_RESET = 'responder_update_reset';

export const RESPONDER_DETAILS_REQUEST = 'responder_details_request';
export const RESPONDER_DETAILS_SUCCESS = 'responder_details_success';
export const RESPONDER_DETAILS_FAIL = 'responder_details_fail';

//--------------------------------------------------------------------------------------------------CSV Upload---------------------------------------------------------------------------------------------------

export const LIST_CSVUPLOAD = 'list_csvUpload';
export const CSVUPLOAD_CREATE_REQUEST = 'csvUpload_create_request';
export const CSVUPLOAD_CREATE_SUCCESS = 'csvUpload_create_success';
export const CSVUPLOAD_CREATE_FAIL = 'csvUpload_create_fail';

export const CSVUPLOAD_DELETE_REQUEST = 'csvUpload_delete_request';
export const CSVUPLOAD_DELETE_SUCCESS = 'csvUpload_delete_success';
export const CSVUPLOAD_DELETE_FAIL = 'csvUpload_delete_fail';

export const CSVUPLOAD_UPDATE_REQUEST = 'csvUpload_update_request';
export const CSVUPLOAD_UPDATE_SUCCESS = 'csvUpload_update_success';
export const CSVUPLOAD_UPDATE_FAIL = 'csvUpload_update_fail';
export const CSVUPLOAD_UPDATE_RESET = 'csvUpload_update_reset';

//--------------------------------------------------------------------------------------------------History---------------------------------------------------------------------------------------------------

export const LIST_SENT_MESSAGES = "list_sent_messages";
export const LIST_SENT_MESSAGES_CREATE_REQUEST = "list_sent_messages_create_request";
export const LIST_SENT_MESSAGES_CREATE_SUCCESS = "list_sent_messages_create_success";
export const LIST_SENT_MESSAGES_CREATE_FAIL = "list_sent_messages_create_fail";

export const DELETE_SENT_MESSAGES_CREATE_REQUEST = "delete_sent_messages_create_request";
export const DELETE_SENT_MESSAGES_CREATE_SUCCESS = "delete_sent_messages_create_success";
export const DELETE_SENT_MESSAGES_CREATE_FAIL = "delete_sent_messages_create_fail";

export const LIST_RECIEVED_MESSAGES = "list_recieved_messages";
export const LIST_RECIEVED_MESSAGES_CREATE_REQUEST = "list_recieved_messages_create_request";
export const LIST_RECIEVED_MESSAGES_CREATE_SUCCESS = "list_recieved_messages_create_success";
export const LIST_RECIEVED_MESSAGES_CREATE_FAIL = "list_recieved_messages_create_fail";

export const DELETE_RECIEVED_MESSAGES_CREATE_REQUEST = "delete_recieved_messages_create_request";
export const DELETE_RECIEVED_MESSAGES_CREATE_SUCCESS = "delete_recieved_messages_create_success";
export const DELETE_RECIEVED_MESSAGES_CREATE_FAIL = "delete_recieved_messages_create_fail";


// messaging
export const MESSAGE_CREATE_REQUEST = "message_create_request";
export const MESSAGE_CREATE_SUCCESS = "message_create_success";
export const MESSAGE_CREATE_FAIL = "message_create_fail";


//--------------------------------------------------------------------------------------------------Chat---------------------------------------------------------------------------------------------------


export const LIST_ACTIVE_CHAT = "list_active_chat";
export const UPDATE_ACTIVE_CHAT = "update_active_chat";

export const LIST_CHATS = "list_chats";
export const ADD_CHAT = "add_chat";
export const CHAT_SWAP_INFO = 'chat_swap_info';
export const EDIT_CHAT = "edit_chat";
export const DELETE_CHAT = "delete_chat";
export const REMOVE_BLOCKED_CHAT = "remove_blocked_chat";
export const REMOVE_UNSUBSCRIBED_CHAT = "remove_unsubscribed_chat";

export const CHAT_CREATE_REQUEST = 'chat_create_request';
export const CHAT_CREATE_SUCCESS = 'chat_create_success';
export const CHAT_CREATE_FAIL = 'chat_create_fail';

export const CHAT_DELETE_REQUEST = 'chat_delete_request';
export const CHAT_DELETE_SUCCESS = 'chat_delete_success';
export const CHAT_DELETE_FAIL = 'chat_delete_fail';

export const CHAT_UPDATE_REQUEST = 'chat_update_request';
export const CHAT_UPDATE_SUCCESS = 'chat_update_success';
export const CHAT_UPDATE_FAIL = 'chat_update_fail';



//--------------------------------------------------------------------------------------------------Chat messages---------------------------------------------------------------------------------------------------


export const FETCH_CHAT_MESSAGES_REQUEST = "fetch_chat_messages_request";
export const FETCH_CHAT_MESSAGES_SUCCESS = "fetch_chat_messages_success";
export const LIST_CHAT_MESSAGES = "list_chat_messages";
export const CREATE_LIST_EACH_CHAT_MESSAGES = "create_list_each_chat_messages";
export const LIST_CHAT_MESSAGES_CREATE = "list_chat_messages_create";

export const ADD_CHAT_MESSAGE = "add_chat_message";
export const ADD_INCOMING_CHAT_MESSAGE = "add_incoming_chat_message";
export const DELETE_CHAT_MESSAGE = "delete_chat_message";
export const DELETE_CHAT_CALL = "delete_chat_call";

export const UPDATE_MESSAGE_STATUS = "update_message_status";

export const CHAT_MESSAGE_UPDATE_SUCCESS = 'chat_message_update_success';

export const CHAT_MESSAGES_CREATE_REQUEST = "chat_messages_create_request";
export const CHAT_MESSAGES_CREATE_SUCCESS = "chat_messages_create_success";
export const CHAT_MESSAGES_CREATE_FAIL = "chat_messages_create_fail";

export const CHAT_MESSAGES_DELETE_REQUEST = "chat_messages_delete_request";
export const CHAT_MESSAGES_DELETE_SUCCESS = "chat_messages_delete_success";
export const CHAT_MESSAGES_DELETE_FAIL = "chat_messages_delete_fail";






//--------------------------------------------------------------------------------------------------Attach file---------------------------------------------------------------------------------------------------

export const ATTACH_FILE_CREATE_REQUEST = "attach_file_create_request";
export const ATTACH_FILE_CREATE_SUCCESS = "attach_file_create_success";
export const ATTACH_FILE_CREATE_FAIL = "attach_file_create_fail";
export const ATTACH_FILE_CREATE_RESET = "attach_file_create_reset";


//--------------------------------------------------------------------------------------------------Attach MMS---------------------------------------------------------------------------------------------------


export const ATTACH_MMS_CREATE_REQUEST = "attach_mms_create_request";
export const ATTACH_MMS_CREATE_SUCCESS = "attach_mms_create_success";
export const ATTACH_MMS_CREATE_FAIL = "attach_mms_create_fail";
export const ATTACH_MMS_RESET = "attach_mms_reset";

export const PREVIEW_MMS_CREATE_REQUEST = "preview_mms_create_request";
export const PREVIEW_MMS_CREATE_SUCCESS = "preview_mms_create_success";
export const PREVIEW_MMS_CREATE_FAIL = "preview_mms_create_fail";
export const PREVIEW_MMS_RESET = "preview_mms_reset";


//--------------------------------------------------------------------------------------------------Numbers---------------------------------------------------------------------------------------------------

export const LIST_NUMBERS = "list_numbers";
export const UPDATE_PRIMARY_NUMBER = "update_primary_number";

export const LIST_PRIMARY_NUMBER = "list_primary_number";

export const AVAIL_NUMBERS_REQUEST = "avail-numbers-request";
export const AVAIL_NUMBERS_SUCCESS = "avail-numbers-success";
export const AVAIL_NUMBERS_FAIL = "avail-numbers-fail";
export const AVAIL_NUMBERS_RESET = "avail-numbers-reset";

export const NUMBER_CREATE_REQUEST = "number-create-request";
export const NUMBER_CREATE_SUCCESS = "number-create-success";
export const NUMBER_CREATE_FAIL = "number-create-fail";
export const NUMBER_CREATE_RESET = "number-create-reset";

export const NUMBER_DETAILS_REQUEST = "number-details-request";
export const NUMBER_DETAILS_SUCCESS = "number-details-success";
export const NUMBER_DETAILS_FAIL = "number-details-fail";

export const NUMBER_DELETE_REQUEST = 'number_delete_request';
export const NUMBER_DELETE_SUCCESS = 'number_delete_success';
export const NUMBER_DELETE_FAIL = 'number_delete_fail';

export const NUMBER_UPDATE_REQUEST = 'number_update_request';
export const NUMBER_UPDATE_SUCCESS = 'number_update_success';
export const NUMBER_UPDATE_FAIL = 'number_update_fail';
export const NUMBER_UPDATE_RESET = 'number_update_reset';

export const PRIMARY_NUMBER_UPDATE_REQUEST = 'primary_number_update_request';
export const PRIMARY_NUMBER_UPDATE_SUCCESS = 'primary_number_update_success';
export const PRIMARY_NUMBER_UPDATE_FAIL = 'primary_number_update_fail';
export const PRIMARY_NUMBER_UPDATE_RESET = 'primary_number_update_reset';
//-------------------------------------------------------------------------------------------------Calendar------------------------------------------------------

export const UPDATE_MONTH_VIEW = "update_month_view";
export const LIST_CALENDAR = "list_calendar";
export const LIST_PAUSED_CALENDAR = "list_paused_calendar";
export const LIST_COMPLETED_CALENDAR = "list_completed_calendar";

export const CALENDAR_CREATE_REQUEST = "calendar_create_request";
export const CALENDAR_CREATE_SUCCESS = "calendar_create_success";
export const CALENDAR_CREATE_FAIL = "calendar_create_fail";
export const CALENDAR_CREATE_RESET = "calendar_create_reset";

export const CALENDAR_DETAILS_REQUEST = "calendar_details_request";
export const CALENDAR_DETAILS_SUCCESS = "calendar_details_success";
export const CALENDAR_DETAILS_FAIL = "calendar_details_fail";

export const CALENDAR_DELETE_REQUEST = 'calendar_delete_request';
export const CALENDAR_DELETE_SUCCESS = 'calendar_delete_success';
export const CALENDAR_DELETE_FAIL = 'calendar_delete_fail';

export const CALENDAR_UPDATE_REQUEST = 'calendar_update_request';
export const CALENDAR_UPDATE_SUCCESS = 'calendar_update_success';
export const CALENDAR_UPDATE_FAIL = 'calendar_update_fail';
export const CALENDAR_UPDATE_RESET = 'calendar_update_reset';

export const CALENDAR_RESUME_REQUEST = 'calendar_resume_request';
export const CALENDAR_RESUME_SUCCESS = 'calendar_resume_success';
export const CALENDAR_RESUME_FAIL = 'calendar_resume_fail';
export const CALENDAR_RESUME_RESET = 'calendar_resume_reset';

//-------------------------------------------------------------------------------------------------Credit create--------------------------------------

export const MERCHANT_DETAILS_REQUEST = 'merchant-details-request';
export const MERCHANT_DETAILS_SUCCESS = 'merchant-details-success';
export const MERCHANT_DETAILS_FAIL = 'merchant-details-fail';
export const MERCHANT_DETAILS_RESET = 'merchant-details-reset';

export const CREDIT_CREATE_REQUEST = 'credit_create_request';
export const CREDIT_CREATE_SUCCESS = 'credit_create_success';
export const CREDIT_CREATE_FAIL = 'credit_create_fail';
export const CREDIT_CREATE_RESET = 'credit_create_reset';

//-------------------------------------------------------------------------------------------------Call create--------------------------------------

export const LIST_CALLS = "list_calls";
export const LIST_CALLS_CREATE_REQUEST = "list_calls_create_request";
export const LIST_CALLS_CREATE_SUCCESS = "list_calls_create_success";
export const LIST_CALLS_CREATE_FAIL = "list_calls_create_fail";

export const CALL_CREATE_REQUEST = 'call_create_request';
export const CALL_CREATE_SUCCESS = 'call_create_success';
export const CALL_CREATE_FAIL = 'call_create_fail';
export const CALL_CREATE_RESET = 'call_create_reset';

export const CALLS_DELETE_REQUEST = 'calls_delete_request';
export const CALLS_DELETE_SUCCESS = 'calls_delete_success';
export const CALLS_DELETE_FAIL = 'calls_delete_fail';
export const CALLS_DELETE_RESET = 'calls_delete_reset';

export const CREATE_TOKEN_REQUEST = 'create_token _request';
export const CREATE_TOKEN_SUCCESS = 'create_token_success';
export const CREATE_TOKEN_FAIL = 'create_token_fail';
export const CREATE_TOKEN_RESET = 'create_token_reset';




//--------------------------------------------------------------------------------------------------TABS---------------------------------------------------------------------------------------------------
export const ADD_TAB = 'add_tab';

export const ADD_EVENT = 'add_event';
export const ADD_EVENTS_PLUGIN = 'add_events_plugin';
export const RESET_EVENT = 'reset_event';

export const PLAN_UPDATE_STATE = 'plan_update_state';
export const PLAN_UPDATE_STATE_RESET = 'plan_update_state_reset';


export const ADD_CONTENT = 'add_content';
export const RESET_BULK_TEXT_MESSAGE_CONTENT = "reset_bulk_text_message_content"

export const SUBSCRIPTION_CANCEL_REQUEST = 'subscription_cancel_request';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'subscription_cancel_success';
export const SUBSCRIPTION_CANCEL_FAIL = 'subscription_cancel_fail';

export const CARD_SECRET_REQUEST = 'card_secret_request';
export const CARD_SECRET_SUCCESS = 'card_secret_success';
export const CARD_SECRET_FAIL = 'card_secret_fail';

export const CONFIRM_SETUP_INTENT_REQUEST = 'confirm_setup_intent_request';
export const CONFIRM_SETUP_INTENT_SUCCESS = 'confirm_setup_intent_success';
export const CONFIRM_SETUP_INTENT_FAIL = 'confirm_setup_intent_fail';

export const PAYMENT_METHOD_CREATE_REQUEST = 'payment_method_create_request';
export const PAYMENT_METHOD_CREATE_SUCCESS = 'payment_method_create_success';
export const PAYMENT_METHOD_CREATE_FAIL = 'payment_method_create_fail';
export const PAYMENT_METHOD_CREATE_RESET = 'payment_method_create_reset';

export const PAYMENT_METHOD_DELETE_REQUEST = 'payment_method_delete_request';
export const PAYMENT_METHOD_DELETE_SUCCESS = 'payment_method_delete_success';
export const PAYMENT_METHOD_DELETE_FAIL = 'payment_method_delete_fail';

export const DEFAULT_PAY_METHOD_CREATE_REQUEST = 'payment_method_create_request';
export const DEFAULT_PAY_METHOD_CREATE_SUCCESS = 'payment_method_create_success';
export const DEFAULT_PAY_METHOD_CREATE_FAIL = 'payment_method_create_fail';

export const LIST_INVOICES = 'list_invoices';

//---------------------------------------------------------------------------------REDUX ROWS--------------------------------------------------------------------------------------------

export const UPDATE_SELECTED_ROWS = 'update_selected_rows';

export const UPDATE_SELECTED_GROUP_ROWS = 'update_selected_group_rows';

export const UPDATE_SELECTED_SENT_ROWS = "update_selected_sent_rows";

//-----------------------------------------------------------------------BULKSEND------------------------------------------------------------------------------------------------------
export const LIST_BULK_SEND = 'list_bulk_send';
export const BULK_SEND_CREATE_REQUEST = "bulk_send_create_request";
export const BULK_SEND_CREATE_SUCCESS = "bulk_send_create_success";
export const BULK_SEND_CREATE_FAIL = "bulk_send_create_fail";
export const BULK_SEND_CREATE_RESET = "bulk_send_create_reset";

export const DELETE_BULK_SEND_REQUEST = "delete_bulk_send_request";
export const DELETE_BULK_SEND_SUCCESS = "delete_bulk_send_success";
export const DELETE_BULK_SEND_FAIL = "delete_bulk_send_fail";
export const DELETE_BULK_SEND_RESET = "delete_bulk_send_reset";


export const LIST_BULK_SEND_MESSAGES = "list_bulk_send_messages";
export const LIST_BULK_SEND_DELIVERED_MESSAGES = "list_bulk_send_delivered_messages";
export const LIST_BULK_SEND_SENT_MESSAGES = "list_bulk_send_sent_messages";
export const LIST_BULK_SEND_FAILED_MESSAGES = "list_bulk_send_failed_messages";
export const BULK_SEND_DETAILS_RESET = "bulk_send_details_reset";

//---------------------------------------------------------------------------------BULKMESSAGE CONTENT--------------------------------------------------------------------------------------------
export const ADD_BULK_MESSAGE_CONTENT = "add_bulk_message_content";
export const ADD_TO_FIELD_HTML = "add_to_field_html";
//---------------------------------------------------------------------------------ACCOUNT --------------------------------------------------------------------------------------------

export const ACCOUNT_DELETE_REQUEST = 'account_delete_request';
export const ACCOUNT_DELETE_SUCCESS = 'account_delete_success';
export const ACCOUNT_DELETE_FAIL = 'account_delete_fail';
export const ACCOUNT_DELETE_RESET = 'account_delete_reset';

export const FORGOT_PASSWORD_CREATE_REQUEST = 'forgot_password_create_request';
export const FORGOT_PASSWORD_CREATE_SUCCESS = 'forgot_password_create_success';
export const FORGOT_PASSWORD_CREATE_FAIL = 'forgot_password_create_fail';

export const UPDATE_PASSWORD_REQUEST = 'update_password_request';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const UPDATE_PASSWORD_FAIL = 'update_password_fail';

export const LIST_RESPONDER_MESSAGES = 'list_responder_messages';
export const LIST_RESPONDER_MESSAGES_REQUEST = 'list_responder_messages_request';
export const LIST_RESPONDER_MESSAGES_SUCCESS = 'list_responder_messages_success';
export const LIST_RESPONDER_MESSAGES_FAIL = 'list_responder_messages_fail';

export const SET_EMAIL = 'set_email';
export const SET_PASS = 'set_pass';

export const CUSTOMER_PROFILE_REQUEST = 'customer_profile_request';
export const CUSTOMER_PROFILE_SUCCESS = 'customer_profile_success';
export const CUSTOMER_PROFILE_FAIL = 'customer_profile_fail';

export const SUBSCRIPTION_DETAILS_REQUEST = 'subscription_details_request';
export const SUBSCRIPTION_DETAILS_SUCCESS = 'subscription_details_success';
export const SUBSCRIPTION_DETAILS_FAIL = 'subscription_details_fail';

export const UPDATE_PAGE_SIZE = "update_page_size";
export const UPDATE_PAGE_INDEX = "update_page_index";

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const UPDATE_SELECTED_CONTACT_ROWS = "update_selected_contact_rows";
export const REMOVE_SELECTED_CONTACT_ROWS = "remove_selected_contact_rows";
export const RESET_SELECTED_CONTACT_ROWS = "reset_selected_contact_rows";

export const RESET_SELECTED_ROWS = "reset_selected_rows";