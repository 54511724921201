import React from 'react';
import socketio from "socket.io-client";

let socket;

export const connectSocket = () => {
  if (!socket) {
    socket = socketio.connect('https://my.bulktext.com', { transports: ['websocket'] });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => {
  if (!socket) {
    throw new Error('Must call connectSocket first');
  }
  return socket;
};

export const SocketContext = React.createContext();