import { LIST_SENT_MESSAGES } from "../actions/types";


export default function (state = [], action) {
    const { payload } = action
    switch (action.type) {
        case LIST_SENT_MESSAGES:
         
            return payload.data
            default:
            return state;

    }

}