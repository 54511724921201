import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Button, CustomInput, Modal, ModalHeader, ButtonGroup, Label, Media } from 'reactstrap'; // Badge was included
import AppContext from '../../context/Context';
import defaultModeImg from '../../assets/img/generic/bulkText-light.jpg';
import darkModeImg from '../../assets/img/generic/bulkText-dark.jpg';
import Flex from '../common/Flex';
import ScrollBarCustom from '../common/ScrollBarCustom';
import { listPrimaryNumbers, listNumbers, makePrimaryNumber } from '../../actions/index';
//import VerticalNavRadioBtn from './VerticalNavStyleRadioBtn';

const SidePanelModal = () => {
  const {
    isOpenSidePanel,
    toggleModal,
    isDark,
    setIsDark,
    setIsOpenSidePanel,
  } = useContext(AppContext);

  
  
  const dispatch = useDispatch()
  const primaryNumber = useSelector((state) => state.primaryNumber)
  const successPrimary = useSelector((state) => state.primaryNumberUpdate)
  const {success: successUpdate } = successPrimary
  const numbers = useSelector((state) => state.numbers)
  const [active, setActive] = useState(false)

  
  useEffect(() => {
    dispatch(listPrimaryNumbers())
    dispatch(listNumbers())
  
  }, [successUpdate])


  

  
    const handleChange = (el) => {
        return setActive(el._id),  dispatch(makePrimaryNumber(el._id))
      
    }

    function formatPhoneNumber(phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }

  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader tag="div" className="" toggle={toggleModal}>
        <div className="py-1 flex-grow-1">
          <h5 className={isDark ? 'text-white' : ''}>
            <FontAwesomeIcon icon="palette" className="mr-2 fs-0" />
            Chat Number
          </h5>
          <p className={isDark ? "mb-0 fs--1 text-white opacity-75" : "mb-0 fs--1 opacity-75"}>Your "Sending" Chat Number: </p>
        
        </div>
      </ModalHeader>
      <div>
      {numbers?.map((el, i) => {
            const stringLength = (formatPhoneNumber(el.phoneNumber) + " " + el.nickName).length
  
          
            return (
        
            <div key={i} onClick={() => {handleChange(el)}} className=" ml-2 custom-control custom-switch custom-switch-adaptive">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={active === el?._id || el?.primary?.US == true}
              onChange={() => {}} // if checked without onChange you get error, needed empty fuction
            
            />
            <Label 
              className={el._id === active && i === 0 || el?.primary?.US === true ?
                "badge badge-soft-success rounded-capsule custom-control-label fs-1 mb-3 mt-2" 
                  : el._id === active ? "badge badge-soft-success rounded-capsule custom-control-label fs-1 mb-3" 
                    : i === 0 && el._id !== active ? "badge badge-soft-primary rounded-capsule custom-control-label fs-1 mb-3 mt-2" 
                      : "badge badge-soft-primary rounded-capsule custom-control-label fs-1 mb-2"
              }>{el.nickName && stringLength > 25 ? 
                  (el.nickName + " " + formatPhoneNumber(el.phoneNumber)).slice(0, 24) + ".." 
                    : el.nickName && stringLength < 25 ? 
                      el.nickName + " " + formatPhoneNumber(el.phoneNumber) 
                        : formatPhoneNumber(el.phoneNumber)}
              </Label>
            </div>
   
            )
          })}
      </div>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restProps }) => (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames('p-card position-absolute', { 'border-left': isDark })}
            />
          )
        }}
      >
        <Flex justify="between">
          <Media className="flex-grow-1">
          <FontAwesomeIcon icon="sms" className="mr-2" transform="grow-1" />
            <Media body>
              <h5 className="fs-0">Chat</h5>
              <p className="fs--1 mb-0">Go to Chat</p>
            </Media>
          </Media>
          <Link to="/chat" >
          <Button color="primary" size="sm" onClick={toggleModal}>
                <FontAwesomeIcon icon="sms" className="mr-1" /> Go to Chat
              </Button>
              </Link>
        </Flex>


        <hr />
        <Flex justify="between">
          <Media className="flex-grow-1">
          <FontAwesomeIcon icon="users" className="mr-2" transform="grow-1" />
            <Media body>
              <h5 className="fs-0">Add Contacts</h5>
              <p className="fs--1 mb-0">Increase your reach </p>
            </Media>
          </Media>
        <Link to="/pages/contacts">
          <Button size="sm" color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="plus" className="mr-1" />  Add
          </Button>
        </Link>
        </Flex>
      
       
          
            <hr />
            <Flex justify="between">
              <Media className="flex-grow-1">
                <FontAwesomeIcon icon="cog" className="mr-2" color="falcon-primary" transform="grow-1" />
                <Media body>
                  <h5 className="fs-0">Account Settings</h5>
                  <p className="fs--1 mb-0">Billing, Details, etc...</p>
                </Media>
              </Media>
              <Link to="/account/account-settings">
          <Button size="sm" color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="cog" className="mr-1" /> Account
          </Button>
        </Link>
            </Flex>
          
            <hr />
        <h5 className="fs-0">Color Scheme</h5>
        <ButtonGroup className="btn-group-toggle btn-block">
          <Button color="theme-default" className={classNames('custom-radio-success', { active: !isDark })}>
            <Label for="theme-mode-default" className="cursor-pointer hover-overlay">
              <img className="w-100" src={defaultModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-default"
              label="Light"
              checked={!isDark}
              onChange={({ target }) => setIsDark(!target.checked)}
            />
          </Button>
          <Button color="theme-dark" className={classNames('custom-radio-success', { active: isDark })}>
            <Label for="theme-mode-dark" className="cursor-pointer hover-overlay">
              <img className="w-100" src={darkModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-dark"
              label="Dark"
              checked={isDark}
              onChange={({ target }) => setIsDark(target.checked)}
            />
          </Button>
        </ButtonGroup>
   
     
        <hr />
        <div className="text-center mt-5">
          {
         // <img src={settings} alt="settings" width={120} className="mb-4" />
          }
          <h5>Change plan</h5>
          <p className="fs--1">Get credits and start building your community, the masses are waiting</p>
          <Link to="/get-credits">
          <Button className="mb-5" color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="chevron-left" className="mr-1" /> View Credits/Plans
          </Button>
        </Link>
        </div>
      
      </ScrollBarCustom>
    </Modal>
  );
};


export default SidePanelModal;
