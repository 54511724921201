import { ATTACH_FILE_CREATE_REQUEST, ATTACH_FILE_CREATE_SUCCESS, ATTACH_FILE_CREATE_FAIL, ATTACH_FILE_CREATE_RESET } from '../actions/types';

export default function attachFileCreateReducer(state = {}, action) {
    switch(action.type) {
        case ATTACH_FILE_CREATE_REQUEST:
            return { loading: true }
        case ATTACH_FILE_CREATE_SUCCESS:
            return { loading: false, success: true, payload: action.payload.data}
        case ATTACH_FILE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case ATTACH_FILE_CREATE_RESET: 
            return {}
        default:
            return state
    }
}