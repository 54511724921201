import { CALLS_DELETE_REQUEST, CALLS_DELETE_SUCCESS, CALLS_DELETE_FAIL, CALLS_DELETE_RESET } from '../actions/types';

export default function blockedContactDeleteReducer(state = {}, action) {
    switch(action.type) {
        case CALLS_DELETE_REQUEST:
            return { loading: true }
        case CALLS_DELETE_SUCCESS:
            return { loading: false, success: true}
        case CALLS_DELETE_FAIL:
            return { loading: false, error: action.payload }
            case CALLS_DELETE_RESET:
                return { }
        default:
            return state
    }
}