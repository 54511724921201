import { FETCH_CHAT_MESSAGES_REQUEST, FETCH_CHAT_MESSAGES_SUCCESS} from '../actions/types';

export default function fetchChatMessagesReducer(state = {}, action) {
    switch(action.type) {
        case FETCH_CHAT_MESSAGES_REQUEST:
            return { loading: true }
        case FETCH_CHAT_MESSAGES_SUCCESS:
            return { loading: false, success: true}
        default:
            return state
    }
}