import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { Row, Col} from 'reactstrap'
import './react-table.scss'

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  isSelected
  
}) => {


  return (

      <Table responsive {...getTableProps(tableProps)} className="table-sticky">
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => { 

              return (
              
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort && column?.id != "actions" ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
             
              </th>
            )})}
          </tr>
        </thead>

        <tbody>
        
          <tr className={isSelected?.length ? " " : "d-none"} > 
           <td colSpan="6" >{`${isSelected?.length} of `}
           </td>
          </tr>

          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i}  className={rowClassName} {...row.getRowProps({style: { maxHeight: '20px'}})}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    
   
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable;