import { LIST_SHOW_AND_HIDE_GROUPS } from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {

            case LIST_SHOW_AND_HIDE_GROUPS:
                const listGroupsExceptUnsub = action.payload.filter((group) => group.title !== "unsubscribers")
                return listGroupsExceptUnsub
            default:
                return state;
    }
}